import React, {useContext} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import {Route, Switch} from 'react-router-dom';
import DashboardPage from '../dashboard/DashboardPage';
import Question from '../question/Question';
import TopBar from "./TopBar";
import UserContext from "../../context/user-context";
import SearchPage from "../search/SearchPage";
import styles from "../../assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import {makeStyles} from "@material-ui/core/styles";
import {MySidebar} from "./MySidebar";
import Footer from "../../theme-components/components/Footer/Footer";
import Questionnaire from "../questionnaire/Questionnaire";
import ROQuestionPage from "../readOnly/ROQuestionPage";
import {useIntl} from "react-intl";
import UserTable from "../admin/user/UserTable";
import QuestionTable from "../admin/chapter/QuestionTable";
import CountryTable from "../admin/country/CountryTable";
import QuestionnaireContext from "../../context/questionnaire-context";
import WeightsTable from "../admin/weights/WeightsTable";
import DownloadsTable from "../admin/downloads/DownloadsTable";
import QuestionNotFound from "../misc/QuestionNotFound";
import InfoPage from "../misc/InfoPage";
import ChartsPage from "../charts/ChartsPage";
import getRoutes from "../misc/routes";
import AppinfoContext from "../../context/appinfo-context";
import vdp_logo from "../../assets/img/pfandbrief_logo.svg";
import hypzert_logo from "../../assets/img/hypzert_logo.svg";
import DisclaimerModal from "./modals/DisclaimerModal";
import GlossaryPage from "../misc/GlossaryPage";
import DisclaimerModalRTCBL from "./modals/DisclaimerModalRTCBL";
import ResetPasswordPage from "../login/ResetPasswordPage";
import EditPasswordPage from "../login/EditPasswordPage";
import CookieConsent from "../misc/CookieConsent";

const useStyles = makeStyles(styles);

export default function BaseLayout() {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    const intl = useIntl();
    const ctx = useContext(UserContext);
    const appCtx = useContext(AppinfoContext);
    const questionnaireCtx = useContext(QuestionnaireContext);
    const classes = useStyles();
    const mainPanel = React.createRef();
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    // check if Questio exists
    if (location.pathname.includes("/question/")) {
        let id = parseInt(location.pathname.split("/").slice(0, -1).pop());
        const [chapter, question] = questionnaireCtx.getChapterAndQuestionById(id);
        if (!chapter) {
            return (
                <QuestionNotFound/>
            );
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <MySidebar
                    routes={getRoutes(appCtx.labelShort)}
                    // logoText="Round Table"
                    logo={appCtx.labelShort === 'Runder Tisch Valuation' ? hypzert_logo : vdp_logo}
                    // logo={logo_path}
                    handleDrawerToggle={handleDrawerToggle}
                    open={mobileOpen}
                    color="white"
                    bgColor="white"
                    smallerLogo={appCtx.labelShort === 'Runder Tisch Valuation'}    // Hypzert logo should have width=75%
                    username={ctx.isLoggedIn ?
                        ctx.firstname + " " + ctx.lastname :
                        intl.formatMessage({defaultMessage: "Guest account"})}
                />
                <div id="main-wrapper" className={classes.mainPanel} ref={mainPanel}>
                    <CssBaseline/>
                    <TopBar handleDrawerToggle={handleDrawerToggle} open={open}/>
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer}/>
                        <Container maxWidth="lg" className={classes.container}>
                            <Switch>
                                <Route path="/questionnaire">
                                    <Questionnaire/>
                                </Route>
                                <Route path="/question/:questionId">
                                    {ctx.getIsAnonOrRo() ?
                                        <ROQuestionPage/> : <Question/>}
                                </Route>
                                <Route path="/search">
                                    <SearchPage/>
                                </Route>
                                <Route path="/users/">
                                    <UserTable/>
                                </Route>
                                <Route path="/questions/">
                                    <QuestionTable/>
                                </Route>
                                <Route path="/weights/">
                                    <WeightsTable/>
                                </Route>
                                <Route path="/countries/">
                                    <CountryTable/>
                                </Route>
                                <Route path="/downloads/">
                                    <DownloadsTable/>
                                </Route>
                                <Route path="/dashboard/">
                                    <DashboardPage/>
                                </Route>
                                <Route path="/info/">
                                    <InfoPage/>
                                </Route>
                                <Route path="/disclaimer/">
                                    {appCtx.labelShort === 'Round Table Covered Bond Legislation' ?
                                        <DisclaimerModalRTCBL/> : <DisclaimerModal/>}
                                </Route>
                                <Route path="/glossary/">
                                    <GlossaryPage/>
                                </Route>
                                <Route path="/charts/">
                                    <ChartsPage/>
                                </Route>
                                <Route path="/">
                                    <DashboardPage/>
                                </Route>
                            </Switch>

                        </Container>
                    </main>
                    <Footer white fluid/>
                </div>
            </div>
        </div>
    );
}