import React, {useState} from 'react';


const CountryContext = React.createContext({
    countries : null,
    countriesLoading: false,
    reloadCountries: (countries) => {},
    toggleCountriesLoading: (loading) => {},
    getCountriesCount: () => {},
    getCountryByCode: (code) => {},
});

export const CountryContextProvider = (props) => {
    const [countries, setCountries] = useState(null);
    const [countriesLoading, setCountriesLoading] = useState(false);
    const reloadCountries = (countries) => {
        setCountries(countries);
    };
    const toggleCountriesLoading = (loading) => {
        setCountriesLoading(loading);
    };
    const getCountriesCount = () => {
        return countries.length;
    };
    const getCountryByCode = (code) => {
        return countries.find(country => country.code === code);
    };
    return (
        <CountryContext.Provider value={
            {
                countries: countries,
                countriesLoading: countriesLoading,
                reloadCountries: reloadCountries,
                toggleCountriesLoading: toggleCountriesLoading,
                getCountriesCount: getCountriesCount,
                getCountryByCode: getCountryByCode
            }
        }>
            {props.children}
        </CountryContext.Provider>
    );

};

export default CountryContext;