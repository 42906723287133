import axios from "axios";
import ErrorPage from "../components/misc/ErrorPage";
import Login from "../components/login/Login";
import React from "react";

export const getCommentsURL = "/api/comments/";
export const getAttachmentsURL = "/api/attachments/";
export const authRefreshURL = "/api/token/refresh/";
export const getUserinfoURL = "/userinfo/";
export const getAppinfoURL = "/appinfo/";
export const getChapterURL = "/api/chapters/";
export const getCountriesURL = "/api/countries/";
export const getGivenAnswersURL = "/api/givenAnswers/";
export const reportByCountryURL = "/report-by-country/";
export const reportByQuestionURL = "/report-by-questions/";
export const updateConfirmedStatusURL = "/update-confirmation/";
export const getDownloadableCategoriesURL = "/api/downloadablecategories/";
export const getQuestionsURL = "/api/questions/";
export const exportMapsURL = "/img/zip/";
// Admin routes
export const getUsersURL = "/api/users/";
export const getChapterAdminURL = "/api/chaptersAdmin/";
export const getQuestionAdminURL = "/api/questionsAdmin/";
export const getCountryAdminURL = "/api/countriesAdmin/";
export const getPointOfViewsAdminURL = "/api/pointOfViews/";
export const getPointOfViewsURL = "/api/pointOfViews/";
export const getQuestionWeights = "/api/questionweights/";
export const getScores = "/api/scores/";
export const getDownloadableCategoriesAdminURL = "/api/downloadableCategoriesAdmin/";
export const getDownloadsURL = "/api/downloadablesAdmin/";

const axios_init_config = (context) => {
    if ( context.isLoggedIn && context.accessToken ){
        axios.defaults.headers['Authorization'] = "JWT " + context.accessToken;
    }
    else {
        axios.defaults.headers['Authorization'] = '';
    }
        axios.interceptors.response.use(
        response => response,
        error => {
            const originalRequest = error.config;
            if(!error.response){
                return <ErrorPage message="Server did not respond" />;
            }
            if(error.response.status === 401) {
                if(originalRequest.url === authRefreshURL){
                    return <Login />;
                }
                const refresh_token = localStorage.getItem("refreshToken");
                return axios.post(authRefreshURL, {refresh: refresh_token})
                    .then((response) => {
                        context.onNewTokens(response.data.access, response.data.refresh);
                        axios.defaults.headers['Authorization'] = "JWT " + response.data.access;
                        originalRequest.headers['Authorization'] = "JWT " + response.data.access;
                        return axios(originalRequest);
                    }).catch(err => {
                        context.onLogout();
                    });
            }
            return Promise.reject(error);
        }
    );
};
export default axios_init_config;