import React, {useEffect, useState} from 'react';

const initialState = {
    rtDeadline : null,
    rtPreviousDeadline : null,
    rtDaysLeft: null,
    latestCountryName: "",
    progressAllCountries: "",
    commentsLeader: "",
    commentsLeaderCount: "",
    commentsCount: "",
    colors: [],
    rtAllowAnonUsers: false,
    label: "",
    labelShort: "",
    description: "",
    descriptionTitle: "",
    infoUrl1: "",
    infoUrl2: "",
    contactUrl: "",
    impressumUrl: "",
    privacyUrl: "",
    enableDisclaimer: false,
    footerCopyRight: "",
    disableTranslation: false,
    enableAnalytics: false,
};
const AppInfoContext = React.createContext({
    ...initialState,
    onAppInfo: (appInfo) => {},
});

export const AppInfoContextProvider = (props) => {
    const [appInfoState, setAppInfoState] = useState(
        initialState
    );
    useEffect(() => {
        const storedLatestCountryName = localStorage.getItem('latestCountryName');
        const storedProgressAllCountries = localStorage.getItem('progressAllCountries');
        const storedCommentsLeader = localStorage.getItem('commentsLeader');
        const storedCommentsLeaderCount = localStorage.getItem('commentsLeaderCount');
        const storedCommentsCount = localStorage.getItem('commentsCount');
        const storedDescriptionTitle = localStorage.getItem('descriptionTitle');
        const storedDescription = localStorage.getItem('description');
        const storedInfoUrl1 = localStorage.getItem('infoUrl1');
        const storedInfoUrl2 = localStorage.getItem('infoUrl2');
        const storedContactUrl = localStorage.getItem('contactUrl');
        const storedImpressumUrl = localStorage.getItem('impressumUrl');
        const storedprivacyUrl = localStorage.getItem('privacyUrl');
        const storedFooterCopyRight = localStorage.getItem('footerCopyRight');
        const storedDisableTranslation = localStorage.getItem('disableTranslation');
        const storedEnableAnalytics = localStorage.getItem('enableAnalytics');
        let storedColors;
        let storedEnableDisclaimer;
        let storedRtAllowAnonUsers;
        try {
            storedColors = JSON.parse(localStorage.getItem('colors'));
        } catch (e){
            storedColors = [];
        }
        try {
            storedEnableDisclaimer = JSON.parse(localStorage.getItem('enableDisclaimer'));
        } catch (e){
            storedEnableDisclaimer = '';
        }
        try {
            storedRtAllowAnonUsers = JSON.parse(localStorage.getItem('rtAllowAnonUsers'));
        } catch (e){
            storedRtAllowAnonUsers = '';
        }
        setAppInfoState({
            latestCountryName: storedLatestCountryName,
            progressAllCountries: storedProgressAllCountries,
            commentsLeader: storedCommentsLeader,
            commentsLeaderCount: storedCommentsLeaderCount,
            commentsCount: storedCommentsCount,
            colors: storedColors,
            descriptionTitle: storedDescriptionTitle,
            description: storedDescription,
            infoUrl1: storedInfoUrl1,
            infoUrl2: storedInfoUrl2,
            storedContactUrl: storedContactUrl,
            impressumUrl: storedImpressumUrl,
            privacyUrl: storedprivacyUrl,
            rtAllowAnonUsers: storedRtAllowAnonUsers,
            enableDisclaimer: storedEnableDisclaimer,
            footerCopyRight: storedFooterCopyRight,
            disableTranslation: storedDisableTranslation,
            enableAnalytics: storedEnableAnalytics,
        });
    }, []);
    const setApplicationInfo = (appInfo) => {
        let newState = {
            ...appInfoState,
            latestCountryName: appInfo.latestCountryName,
            progressAllCountries: appInfo.progressAllCountries,
            commentsLeader: appInfo.commentsLeader,
            commentsLeaderCount: appInfo.commentsLeaderCount,
            commentsCount: appInfo.commentsCount,
            colors: appInfo.colors,
            labelShort: appInfo.labelShort,
            label: appInfo.label,
            descriptionTitle: appInfo.descriptionTitle,
            description: appInfo.description,
            infoUrl1: appInfo.infoUrl1,
            infoUrl2: appInfo.infoUrl2,
            contactUrl: appInfo.contactUrl,
            impressumUrl: appInfo.impressumUrl,
            privacyUrl: appInfo.privacyUrl,
            rtAllowAnonUsers: appInfo.allowAnonUsers,
            enableDisclaimer: appInfo.enableDisclaimer,
            footerCopyRight: appInfo.footerCopyRight,
            disableTranslation: appInfo.disableTranslation,
            enableAnalytics: appInfo.enableAnalytics,
        };
        if (appInfo.hasOwnProperty('deadline')) {
            localStorage.setItem('rtDeadline', JSON.stringify(appInfo.deadline));
            newState['rtDeadline'] = appInfo.deadline;
        }
        if(appInfo.hasOwnProperty('daysLeft')) {
            localStorage.setItem('rtDaysLeft', JSON.stringify(appInfo.daysLeft));
            newState['rtDaysLeft'] = appInfo.daysLeft;
        }
        if(appInfo.hasOwnProperty('previousDeadline')) {
            localStorage.setItem('rtPreviousDeadline', JSON.stringify(appInfo.previousDeadline));
            newState['rtPreviousDeadline'] = appInfo.previousDeadline;
        }
        localStorage.setItem('latestCountryName', JSON.stringify(appInfo.latestCountryName));
        localStorage.setItem('progressAllCountries', JSON.stringify(appInfo.progressAllCountries));
        localStorage.setItem('commentsLeader', JSON.stringify(appInfo.commentsLeader));
        localStorage.setItem('commentsLeaderCount', JSON.stringify(appInfo.commentsLeaderCount));
        localStorage.setItem('commentsCount', JSON.stringify(appInfo.commentsCount));
        localStorage.setItem('colors', JSON.stringify(appInfo.colors));
        localStorage.setItem('rtAllowAnonUsers', JSON.stringify(appInfo.allowAnonUsers));
        localStorage.setItem('labelShort', JSON.stringify(appInfo.labelShort));
        localStorage.setItem('label', JSON.stringify(appInfo.label));
        localStorage.setItem('descriptionTitle', JSON.stringify(appInfo.descriptionTitle));
        localStorage.setItem('description', JSON.stringify(appInfo.description));
        localStorage.setItem('infoUrl1', JSON.stringify(appInfo.infoUrl1));
        localStorage.setItem('infoUrl2', JSON.stringify(appInfo.infoUrl2));
        localStorage.setItem('contactUrl', JSON.stringify(appInfo.contactUrl));
        localStorage.setItem('impressumUrl', JSON.stringify(appInfo.impressumUrl));
        localStorage.setItem('privacyUrl', JSON.stringify(appInfo.privacyUrl));
        localStorage.setItem('enableDisclaimer', JSON.stringify(appInfo.enableDisclaimer));
        localStorage.setItem('footerCopyRight', JSON.stringify(appInfo.footerCopyRight));
        localStorage.setItem('disableTranslation', JSON.stringify(appInfo.disableTranslation));
        localStorage.setItem('enableAnalytics', JSON.stringify(appInfo.enableAnalytics));
        setAppInfoState(newState);
    };

    return (
        <AppInfoContext.Provider value={
            {
                rtDeadline: appInfoState.rtDeadline,
                rtPreviousDeadline: appInfoState.rtPreviousDeadline,
                rtDaysLeft: appInfoState.rtDaysLeft,
                latestCountryName: appInfoState.latestCountryName,
                progressAllCountries: appInfoState.progressAllCountries,
                commentsLeader: appInfoState.commentsLeader,
                commentsLeaderCount: appInfoState.commentsLeaderCount,
                commentsCount: appInfoState.commentsCount,
                colors: appInfoState.colors,
                rtAllowAnonUsers: appInfoState.rtAllowAnonUsers,
                labelShort: appInfoState.labelShort,
                label: appInfoState.label,
                description: appInfoState.description,
                descriptionTitle: appInfoState.descriptionTitle,
                infoUrl1: appInfoState.infoUrl1,
                infoUrl2: appInfoState.infoUrl2,
                contactUrl: appInfoState.contactUrl,
                impressumUrl: appInfoState.impressumUrl,
                privacyUrl: appInfoState.privacyUrl,
                enableDisclaimer: appInfoState.enableDisclaimer,
                footerCopyRight: appInfoState.footerCopyRight,
                disableTranslation: appInfoState.disableTranslation,
                enableAnalytics: appInfoState.enableAnalytics,
                onAppInfo: setApplicationInfo,
            }
        }>
            {props.children}
        </AppInfoContext.Provider>
    );
};

export default AppInfoContext;
