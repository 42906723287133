/*eslint-disable*/
import React, {useContext, useRef, useState} from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {Link, NavLink, useHistory, useLocation} from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";

// core components

import sidebarStyle from "~/assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";

import avatar from "~/assets/img/user.png";
import {InputBase, ListSubheader, Tooltip} from "@material-ui/core";
import UserContext from "../../../context/user-context";
import {FormattedMessage, useIntl} from "react-intl";
import SearchIcon from "@material-ui/icons/Search";
import Highlighter from "react-highlight-words";
import {intToRoman} from "../../../utils/general-helpers";
import {Star} from "@material-ui/icons";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import {AnsweredIcon, NotAnsweredIcon, StarIcon} from "../../../components/misc/icons";

const useStyles = makeStyles(sidebarStyle);

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
function SidebarWrapper({className, user, headerLinks, links}) {
    const sidebarWrapper = React.useRef();
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(sidebarWrapper.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
        };
    });
    return (
        <div className={className} ref={sidebarWrapper}>
            {user}
            {headerLinks}
            {links}
        </div>
    );
}

function Sidebar(props) {
    const classes = useStyles();
    const [searchInput, setSearchInput] = useState('');
    const [filteredRoutes, setFilteredRoutes] = useState(props.routes);
    const ctx = useContext(UserContext)
    const intl = useIntl();
    const history = useHistory();
    // to check for active links and opened collapses
    let location = useLocation();
    // this is for the user collapse
    const [openAvatar, setOpenAvatar] = React.useState(true);
    // this is for the rest of the collapses
    const [state, setState] = React.useState({});
    React.useEffect(() => {
        setState(getCollapseStates(props.routes));
    }, [props.routes]);
    React.useEffect(() => {
        setFilteredRoutes(props.routes);
    }, [props.routes]);
    React.useEffect(() => {
        filterRoutes(searchInput);
    }, [searchInput]);
    const mainPanel = React.useRef();
    const goToLogin = () => {
        history.push("/login");
    }
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };
    const filterRoutes = (searchTerm) => {
        let newRoutes = [];
        searchTerm = searchTerm.toLowerCase();
        props.routes.forEach(route => {
            var newRoute = {...route};
            if (route.isChapter){
                newRoute.views = route.views.filter(view => {
                    return view.name.toLowerCase().includes(searchTerm);
                })
                if(newRoute.views.length > 0 || newRoute.name.toLowerCase().includes(searchTerm)){
                    return newRoutes.push(newRoute);
                }
            } else {
                return newRoutes.push(newRoute);
            }
        });
        setFilteredRoutes(newRoutes);
    }
    // this creates the intial state of this component based on the collapse routes
    // that it gets through routes
    const getCollapseStates = (routes) => {
        let initialState = {};
        routes.map((prop) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: getCollapseInitialState(prop.views),
                    // ...getCollapseStates(prop.views),
                    ...initialState,
                };
            }
            // return null;
        });
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    const getCollapseInitialState = (routes) => {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (location.pathname === routes[i].layout + routes[i].path) {
                return true;
            }
        }
        return false;
    };
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        //   let locationPathname = location.pathname.length > 1 && location.pathname[location.pathname.length - 1] === "/" ?
        //       location.pathname.slice(0, -1) : location.pathname;
        // return locationPathname === routeName ? "active" : "";
        return location.pathname === routeName || location.pathname.slice(0, -1) === routeName ? "active" : "";
    };
    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routes) => {
        const {color} = props;
        return routes.map((prop, key) => {
            if (prop.redirect) {
                return null;
            }
            if (prop.collapse) {
                var st = {};
                st[prop.state] = !state[prop.state];
                const navLinkClasses =
                    classes.itemLink +
                    " " +
                    cx({
                        [" " + classes.collapseActive]: getCollapseInitialState(prop.views),
                    });
                const itemText =
                    classes.itemText;
                const collapseItemText =
                    classes.collapseItemText;
                const itemIcon =
                    classes.itemIcon;
                const caret =
                    classes.caret
                const collapseItemMini =
                    classes.collapseItemMini
                return (
                    <ListItem
                        key={key}
                        className={cx(
                            {[classes.item]: prop.icon !== undefined},
                            {[classes.collapseItem]: prop.icon === undefined}
                        )}
                    >
                        <NavLink
                            to={"#"}
                            className={navLinkClasses}
                            onClick={(e) => {
                                e.preventDefault();
                                setState(st);
                            }}
                        >
                            {prop.icon !== undefined ? (
                                typeof prop.icon === "string" ? (
                                    <Icon className={itemIcon}>{prop.icon}</Icon>
                                ) : (
                                    <prop.icon className={itemIcon}/>
                                )
                            ) : (
                                <span className={collapseItemMini}
                                      style={{marginRight: "0"}}
                                >
                  {prop.mini}
                </span>
                            )}
                            <ListItemText
                                primary={ (prop.isChapter) ? (
                                    <Highlighter
                                        highlightClassName="highlight-search-term"
                                        searchWords={[searchInput]}
                                        autoEscape={true}
                                        textToHighlight={prop.name}
                                    />) : prop.name}
                                secondary={
                                    <b
                                        className={
                                            caret +
                                            " " +
                                            (state[prop.state] ? classes.caretActive : "")
                                        }
                                        style={{
                                            top: "0",
                                            right: "0"
                                        }}
                                    />
                                }
                                disableTypography={true}
                                className={cx(
                                    {[itemText]: prop.icon !== undefined},
                                    {[collapseItemText]: prop.icon === undefined}
                                )}
                                style={{whiteSpace: "break-spaces", padding: "0 10px 0 40px"}}
                            />
                        </NavLink>
                        {/*uncollapse all questions if there is a searchterm*/}
                        <Collapse in={(prop.isChapter && searchInput.length > 0) || state[prop.state]} unmountOnExit>
                            <List className={classes.list + " " + classes.collapseList}>
                                {createLinks(prop.views)}
                            </List>
                        </Collapse>
                    </ListItem>
                );
            }
            const innerNavLinkClasses =
                classes.collapseItemLink +
                " " +
                cx({
                    [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
            const collapseItemMini =
                classes.collapseItemMini
            const navLinkClasses =
                classes.itemLink +
                " " +
                cx({
                    [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });
            const itemText =
                classes.itemText;
            const collapseItemText =
                classes.collapseItemText;
            const itemIcon =
                classes.itemIcon
            if (prop.title) {
                return (
                    <div key={key}>
                        <ListSubheader key={key} style={{position: "static"}}>{prop.title}</ListSubheader>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                id="search-input"
                                placeholder={intl.formatMessage({defaultMessage: "Search"}) + "..."}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                value={searchInput}
                                onChange={handleSearchInputChange}
                            />
                        </div>
                    </div>
                )
            }
            if (prop.modalId) {
                return (
                    <ListItem
                        key={key}
                        className={cx(
                            {[classes.item]: prop.icon !== undefined},
                            {[classes.collapseItem]: prop.icon === undefined},
                            {[navLinkClasses]: prop.icon !== undefined},
                            {[innerNavLinkClasses]: prop.icon === undefined}
                        )}
                        button
                        onClick={() => props.onButtonClick(prop.modalId)}
                    >
                        {prop.icon !== undefined ? (
                            typeof prop.icon === "string" ? (
                                <Icon className={itemIcon}>{prop.icon}</Icon>
                            ) : (
                                <prop.icon className={itemIcon}/>
                            )
                        ) : (
                            <span className={collapseItemMini}>
                {prop.mini}
              </span>
                        )}
                        <ListItemText
                            primary={prop.name}
                            disableTypography={true}
                            className={cx(
                                {[itemText]: prop.icon !== undefined},
                                {[collapseItemText]: prop.icon === undefined}
                            )}
                        />
                    </ListItem>
                )
            }
            const listItem = (
                <ListItem
                    key={key}
                    className={cx(
                        {[classes.item]: prop.icon !== undefined},
                        {[classes.collapseItem]: prop.icon === undefined}
                    )}
                    disabled={prop.disabled}
                >
                    <NavLink
                        to={prop.disabled ? false : (prop.external ? {pathname: prop.path} : prop.layout + prop.path)}
                        target={prop.external && "_blank"}
                        className={cx(
                            {[navLinkClasses]: prop.icon !== undefined},
                            {[innerNavLinkClasses]: prop.icon === undefined},
                            // {[classes.specialQuestion]: prop.isSpecial}
                        )}
                    >
                        {prop.icon !== undefined ? (
                            typeof prop.icon === "string" ? (
                                <Icon className={itemIcon}>{prop.icon}</Icon>
                            ) : (
                                <prop.icon className={itemIcon}/>
                            )
                        ) : (
                            <span className={collapseItemMini}
                                  style={{marginRight: "0"}}
                            >
                {prop.mini}
              </span>
                        )}
                        <ListItemText
                            primary={ (prop.isQuestion ) ? (<>
                                {ctx.getIsAnonOrRo() ? "" : prop.isAnswered ? <AnsweredIcon /> : <NotAnsweredIcon /> }
                                {prop.isSpecial && <StarIcon />}
                                <Highlighter
                                highlightClassName="highlight-search-term"
                                searchWords={[searchInput]}
                                autoEscape={true}
                                textToHighlight={prop.name}
                                /></>) : prop.name}
                            disableTypography={true}
                            className={cx(
                                {[itemText]: prop.icon !== undefined},
                                {[collapseItemText]: prop.icon === undefined}
                            )}
                            style={{whiteSpace: "break-spaces"}}
                        />
                    </NavLink>
                </ListItem>
            );
            return prop.disabled ? (
                <Tooltip title={intl.formatMessage({defaultMessage: "This question is currently under review"})}>
                    {listItem}
                </Tooltip>
                )
                : listItem
        });
    };
    const {logo, image, logoText, bgColor} = props;
    const itemText =
        classes.itemText;
    const collapseItemText =
        classes.collapseItemText;
    const userWrapperClass =
        classes.user +
        " " +
        cx({
            [classes.whiteAfter]: bgColor === "white",
        });
    const caret =
        classes.caret;
    const collapseItemMini =
        classes.collapseItemMini;
    const photo =
        classes.photo;
    var user = (
        <div className={userWrapperClass}>
            <div className={photo}>
                <img src={avatar} className={classes.avatarImg} alt="..."/>
            </div>
            <List className={classes.list}>
                <ListItem className={classes.item + " " + classes.userItem}>
                    <NavLink
                        to={"#"}
                        className={classes.itemLink + " " + classes.userCollapseButton}
                        onClick={() => setOpenAvatar(!openAvatar)}
                    >
                        <ListItemText
                            primary={props.username}
                            secondary={
                                <b
                                    className={
                                        caret +
                                        " " +
                                        classes.userCaret +
                                        " " +
                                        (openAvatar ? classes.caretActive : "")
                                    }
                                />
                            }
                            disableTypography={true}
                            className={itemText + " " + classes.userItemText}
                        />
                    </NavLink>
                    <Collapse in={openAvatar} unmountOnExit>
                        <List className={classes.list + " " + classes.collapseList}>
                            {
                                ctx.isLoggedIn ? (
                                    <ListItem className={cx(classes.collapseItem, classes.collapseItemLink)} button
                                              onClick={ctx.onLogout}>
                  <span className={collapseItemMini}>
                    L
                  </span>
                                        <ListItemText
                                            primary={<FormattedMessage defaultMessage="Logout"/>}
                                            disableTypography={true}
                                            className={collapseItemText}
                                        />
                                    </ListItem>
                                    // </>
                                ) : (
                                    <ListItem className={cx(classes.collapseItem, classes.collapseItemLink)} button
                                              onClick={goToLogin}>
                          <span className={collapseItemMini}>
                            L
                          </span>
                                        <ListItemText
                                            primary={"Login"}
                                            disableTypography={true}
                                            className={collapseItemText}
                                        />
                                    </ListItem>
                                )
                            }

                        </List>
                    </Collapse>
                </ListItem>
            </List>
        </div>
    );
    const links = <List className={classes.list}>{createLinks(filteredRoutes)}</List>;

    const logoNormal =
        classes.logoNormal;
    const logoMini =
        classes.logoMini;
    const logoClasses =
        classes.logo +
        " " +
        cx({
            [classes.whiteAfter]: bgColor === "white",
        });
    var brand = (logoText ?
            <div className={logoClasses}>
                <a
                    href="/"
                    // target="_blank"
                    className={logoMini}
                >
                </a>
                <a
                    href="/"
                    // target="_blank"
                    className={logoNormal}
                >
                    {logoText}
                </a>
            </div> :
            <div className={logoClasses}>
                <a href="/"
                   className={logoNormal}
                >
                    <img src={logo} alt="logo" className={classes.onlyImgLogo} style={props.smallerLogo ? {width: '70%'} : {}}/>
                </a>
            </div>
    );
    const drawerPaper =
        classes.drawerPaper;
    const sidebarWrapper =
        classes.sidebarWrapper +
        " " +
        cx({
            [classes.sidebarWrapperWithPerfectScrollbar]:
            navigator.platform.indexOf("Win") > -1,
        });
    return (
        <div ref={mainPanel}>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={props.open}
                    classes={{
                        paper: drawerPaper + " " + classes[bgColor + "Background"],
                    }}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <SidebarWrapper
                        className={sidebarWrapper}
                        user={user}
                        links={links}
                    />
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{backgroundImage: "url(" + image + ")"}}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    anchor="left"
                    variant="permanent"
                    open
                    classes={{
                        paper: drawerPaper + " " + classes[bgColor + "Background"],
                    }}
                >
                    {brand}
                    <SidebarWrapper
                        className={sidebarWrapper}
                        user={user}
                        links={links}
                    />
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{backgroundImage: "url(" + image + ")"}}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
        </div>
    );
}

Sidebar.defaultProps = {
    bgColor: "blue",
};

Sidebar.propTypes = {
    bgColor: PropTypes.oneOf(["white", "black", "blue"]),
    color: PropTypes.oneOf([
        "white",
        "red",
        "orange",
        "green",
        "blue",
        "purple",
        "rose",
    ]),
    logo: PropTypes.string,
    logoText: PropTypes.string,
    image: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    miniActive: PropTypes.bool,
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
};

SidebarWrapper.propTypes = {
    className: PropTypes.string,
    user: PropTypes.object,
    headerLinks: PropTypes.object,
    links: PropTypes.object,
};

export default Sidebar;
