import Sidebar from "../../theme-components/components/Sidebar/Sidebar";
import ReportByCountryModal from "./modals/ReportByCountryModal";
import React, {useContext} from "react";
import ReportByQuestionModal from "./modals/ReportByQuestionModal";
import QuestionnaireContext from "../../context/questionnaire-context";
import {intToRoman} from "../../utils/general-helpers";
import {useLocation} from "react-router-dom";
import ExportMapsModal from "./modals/ExportMapsModal";
import {FormattedMessage} from "react-intl";
import UserContext from "../../context/user-context";

export const MySidebar = (props) => {
    const [countryModalOpen, setCountryModalOpen] = React.useState(false);
    const [questionModalopen, setQuestionModalOpen] = React.useState(false);
    const [exportMapsModalopen, setExportMapsModalOpen] = React.useState(false);
    const qContext = useContext(QuestionnaireContext);
    const location = useLocation();
    const ctx = useContext(UserContext);
    const isAnon = ctx.getIsAnon();


    const handleCountryModalClose = () => {
        setCountryModalOpen(false);
    };
    const handleQuestionModalClose = () => {
        setQuestionModalOpen(false);
    };
    const handleExportMapsModalClose = () => {
        setExportMapsModalOpen(false);
    };
    const openModalById = (id) => {
        switch (id){
            case "exportByCountry":
                // This setTimout prevents an error that cannot be resolved easyly
                setTimeout(() => {
                    setCountryModalOpen(true);
                }, 0);
                break;
            case "exportByQuestion":
                setTimeout(() => {
                    setQuestionModalOpen(true);
                }, 0);
                break;
            case "exportMaps":
                setTimeout(() => {
                    setExportMapsModalOpen(true);
                }, 0);
                break;
            default:
                break;
        }
    };
    let questionnaireRoutes = [];
    questionnaireRoutes = [{
        title: <FormattedMessage defaultMessage="Questions Overview" />
        // title: "Questions Overview"
    }];
    qContext.chapters.forEach((chapter) => {
        let route = {
            collapse: true,
            name: chapter.name,
            mini: intToRoman(chapter.order),
            state: "chapter" + chapter.id + "Collapse",
            isChapter: true
        };
        let children = [];
        chapter.questions.forEach((q) => {
            const path = q.id.toString() + "/";
            children.push({
                path: path,
                name: q.text,
                mini: q.order,
                layout: "/question/",
                isQuestion: true,
                isSpecial: q.is_marked_as_special,
                isAnswered: q.is_answered,
                disabled: (isAnon && !q.public)
            });
        });
        route["views"] = children;
        questionnaireRoutes.push(route);
    });
    // console.log(questionnaireRoutes)
    // props.routes = [...props.routes, ...questionnaireRoutes]
    return (
        <React.Fragment>
        <Sidebar onButtonClick={openModalById} {...props} routes={[...props.routes, ...questionnaireRoutes]}/>
        <ReportByCountryModal open={countryModalOpen} onClose={handleCountryModalClose}/>
        <ReportByQuestionModal open={questionModalopen} onClose={handleQuestionModalClose}/>
        <ExportMapsModal open={exportMapsModalopen} onClose={handleExportMapsModalClose}/>
        </React.Fragment>
);
};