import {AppBar, Tab, Tabs, Typography} from "@material-ui/core";
import React, {useContext, useState} from "react";
import Box from "@material-ui/core/Box";
import QuestionAccordion from "./QuestionsAccordion";
import {makeStyles} from "@material-ui/core/styles";
import {intToRoman} from "../../utils/general-helpers";
import {withStyles} from "@material-ui/styles";
import {grayColor} from "../../assets/jss/material-dashboard-pro-react";
import LoadingOverlay from 'react-loading-overlay';
import {FormattedMessage} from "react-intl";
import {Alert, AlertTitle} from "@material-ui/lab";
import Explanation from "../question/Explanation";
import {useHistory, useLocation} from "react-router-dom";


function useQueryParam() {
    return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    chapterTabsHeader: {
        backgroundColor: grayColor[10],
        fontSize: theme.typography.pxToRem(18),
        color: theme.palette.textColor.grey1,
        padding: "6px"
    },
}));

function TabPanel(props) {

    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}


const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: theme.typography.color,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(16),
        marginRight: theme.spacing(1),
        '&:focus': {
            opacity: 1,
        },
    },
}))((props) => <Tab disableRipple {...props} />);


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const ChapterTabs = (props) => {
    const classes = useStyles();
    const queryParams = useQueryParam();
    const activeChapterId = queryParams.get("chapter");
    const activeIndex = props.chapters.findIndex(chapter => chapter.id === parseInt(activeChapterId));
    const [value, setValue] = useState(activeIndex !== -1 ? activeIndex : 0);
    const history = useHistory();
    const handleChange = (event, newValue) => {
        setValue(newValue);
        const chapterId = props.chapters[newValue].id;
        history.replace({
            pathname: history.location.pathname,
            search: `?chapter=${chapterId}`
        });
    };
    return (
        <AppBar position="static" color="default">
            <Typography className={classes.chapterTabsHeader} variant="h5" align='center' noWrap>
                <FormattedMessage defaultMessage="Chapters {chapterOrder} to {questionOrder}"
                values={{
                    chapterOrder: props.chapters && props.chapters.length > 0 ? intToRoman(props.chapters[0].order) : "",
                    questionOrder: props.chapters && props.chapters.length > 0 ? intToRoman(props.chapters[props.chapters.length - 1].order) : ""
                }}/>

            </Typography>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
            >
                {props.chapters.map((chapter, index) => (
                    <StyledTab key={index}
                               label={`${intToRoman(chapter.order)}. ${chapter.name}`} {...a11yProps(index)} />
                ))}
            </Tabs>
                {props.chapters.map((chapter, index) => (
                    <TabPanel key={index} value={value} index={index}>
                        {chapter.explanation && chapter.explanation.length > 0 && (
                            <Explanation explanation={chapter.explanation} />
                        )}
                        <QuestionAccordion questions={chapter.questions}/>
                    </TabPanel>
                ))}

        </AppBar>

    );
};

export default ChapterTabs;