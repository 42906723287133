import React from 'react';
import {Box} from "@material-ui/core";
import Flag from "react-flagkit";
import {useContext} from "react";

import {intToRoman, pythonDateTimeToDate} from "../../utils/general-helpers";
import {grayColor} from "../../assets/jss/material-dashboard-pro-react";
import {makeStyles} from "@material-ui/core/styles";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import QuestionnaireContext from "../../context/questionnaire-context";
import {rtBoxShadow} from "../../assets/jss/rt-theme";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {ContentState, convertFromHTML, EditorState} from "draft-js";
import AppInfoContext from "../../context/appinfo-context";
import {Person} from "@material-ui/icons";
import UserContext from "../../context/user-context";

const useStyles = makeStyles((theme) => ({
    commentBox: {
        // textAlign: "center",
        // border: "1px solid " + primaryColor[0],
        padding: "0 15px 25px",
        boxShadow: rtBoxShadow.primary,
        marginBottom: "25px"
    },
    flagHeader: {
        display: "inline-block"
    },
    timeInfoHeader: {
        float: "right",
        display: "inline-block"

    },
    stats: {
        color: grayColor[0],
        fontSize: "12px",
        lineHeight: "22px",
        display: "inline-flex",
        "& svg": {
            position: "relative",
            top: "4px",
            width: "16px",
            height: "16px",
            marginRight: "3px",
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            position: "relative",
            top: "4px",
            fontSize: "16px",
            marginRight: "3px",
        },
    },
    commentTextBox: {
        paddingLeft: "25px"
    },
    statsWrapper: {
        marginRight: "15px"
    }
}));


const Comment = (props) => {
    const ctx = useContext(UserContext);
    const commentBlocks = convertFromHTML(props.comment.text);
    const contentState = ContentState.createFromBlockArray(commentBlocks.contentBlocks, commentBlocks.entityMap);
    const editorState = EditorState.createWithContent(contentState);
    const questionnairCtx = useContext(QuestionnaireContext);
    const classes = useStyles();
    const [chapter, question] = questionnairCtx.getChapterAndQuestionById(props.comment.question);
    const appCtx = useContext(AppInfoContext);
    return (
        <Box className={classes.commentBox}>
            <div className={classes.stats}>
                <div className={classes.statsWrapper}>
                    <Flag country={props.comment.country.code}  style={{paddingRight: "5px"}}/> {props.comment.country.name}
                </div>
                <div className={classes.statsWrapper}>
                    <MenuBookIcon /> {intToRoman(chapter.order)} {chapter.name}
                </div>
                <div className={classes.statsWrapper}>
                    <AccessTimeIcon /> {pythonDateTimeToDate(props.comment.modified)}
                </div>
                {
                    props.comment.modified_by && ctx.isLoggedIn &&
                    <div className={classes.statsWrapper}>
                        <Person /> {`${props.comment.modified_by.first_name} ${props.comment.modified_by.last_name}`}
                    </div>
                }
            </div>
            {props.linkToQuestion ? (
                <a href={"/question/" + question.id}>
                    <h4>{question.order}.) {question.text}</h4>
                </a>
            ) : (
                <h4>{question.order}.) {question.text}</h4>
                )
            }
            {props.comment.answer_text && props.comment.answer_number ? (
                <h4 style={{backgroundColor: appCtx.colors[props.comment.answer_number - 1] + "55"}}>
                    {props.comment.answer_number} - {props.comment.answer_text}
                </h4>
            ) : (
                <h4>not answered yet</h4>

            )}
            <Box className={classes.commentTextBox}>
                <Editor
                    // value={props.comment.text}
                    editorState={editorState}
                    readOnly={true}
                    toolbarHidden={true}
                />
            </Box>
        </Box>
    );
};
export default Comment;