import React, {useState} from 'react';
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio, RadioGroup
} from "@material-ui/core";
import {useContext} from "react";
import UserContext from "../../context/user-context";
import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {makeStyles} from "@material-ui/core/styles";
import {rtColors} from "../../assets/jss/rt-theme";
import LoadingOverlay from 'react-loading-overlay';
import {getGivenAnswersURL} from "../../utils/api";
import AppInfoContext from "../../context/appinfo-context";
import {useSnackbar} from "notistack";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
    questionRadiosLabel: {
        color: rtColors.grey1,
    },
    formControl: {
        width: "100%"
    }
}));

const QuestionRadios = (props) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();
    const not_answered_id = {answer: "not-answered-" + props.question.id};
    const initial_data = props.question.hasOwnProperty('givenAnswer') ? props.question.givenAnswer : not_answered_id;
    const [givenAnswer, setGivenAnswer] = useState(initial_data);
    const queryClient = useQueryClient();
    const ctx = useContext(UserContext);
    const appCtx = useContext(AppInfoContext);
    const anonOrRo = ctx.getIsAnonOrRo();
    const unansweredString = intl.formatMessage({defaultMessage: "unanswered"});
    const givenAnswerQuery = useQuery(['givenAnswer', props.question.id, ctx.currentCountry.id],
        () => axios.get(getGivenAnswersURL, {
            params:
                {
                    question: props.question.id,
                    country: ctx.currentCountry.id
                }
        }), {
            enabled: !props.question.hasOwnProperty('givenAnswer'),
            onSuccess: data => {
                if ( data && Array.isArray(data.data) && data.data.length > 0 ) {
                    setGivenAnswer(data.data[0]);
                } else if (data && typeof data.data === 'object' && data.data.hasOwnProperty('answer')){
                    setGivenAnswer(data.data);
                } else {
                    setGivenAnswer(not_answered_id);
                }
            },

        }
    );
    const updateGivenAnswer = useMutation(
        answer_id => axios.post(getGivenAnswersURL, {
            answer: answer_id,
            question: props.question.id,
            country: ctx.currentCountry.id
        }), {
            onSuccess: (data) => {
                const newAnswer = props.question.answers.find((answer) => {
                    return answer.id === data.data.answer;
                });
                const oldAnswer = props.question.answers.find((answer) => {
                    return answer.id === givenAnswer.answer;
                });
                queryClient.setQueryData(['givenAnswer', props.question.id, ctx.currentCountry.id], data);
                queryClient.invalidateQueries("chapters");
                enqueueSnackbar(intl.formatMessage({defaultMessage: "Answer changed from {oldAnswer} to {newAnswer}"}, {
                    oldAnswer: oldAnswer ? oldAnswer.order : unansweredString, newAnswer: newAnswer ? newAnswer.order : unansweredString
                }), {variant: "success"});
            },

        }
    );
    const deleteGivenAnswer = useMutation(
        () => axios.delete(getGivenAnswersURL + givenAnswer.id + "/"), {
            onSuccess: (data) => {
                const oldAnswer = props.question.answers.find((answer) => {
                    return answer.id === givenAnswer.answer;
                });
                queryClient.setQueryData(['givenAnswer', props.question.id, ctx.currentCountry.id], null);
                queryClient.invalidateQueries("chapters");
                enqueueSnackbar(intl.formatMessage({defaultMessage: "Answer changed from {oldAnswer} to {newAnswer}"}, {
                    oldAnswer: oldAnswer.order, newAnswer: unansweredString
                }), {variant: "success"});
            }
        }
    );
    const handleChange = (event) => {


        if (event.target.value === not_answered_id.answer) {
            if (givenAnswer.hasOwnProperty('id')){
                deleteGivenAnswer.mutate();
            } else {
                console.log("Answer wants to be deleted, but is allready null.");
            }
        } else {
            updateGivenAnswer.mutate(event.target.value);
        }

    };
    return (
        <LoadingOverlay
            active={updateGivenAnswer.isLoading || givenAnswerQuery.isLoading || deleteGivenAnswer.isLoading}
            spinner
        >
            <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">
                </FormLabel>
                <RadioGroup aria-label="Antwortmöglichkeiten"
                            name={"question-id-" + props.question.id}
                            value={"" + givenAnswer.answer}
                            onChange={handleChange}
                >
                    {props.question.answers.map((answer, index) => (
                        <FormControlLabel
                            className={classes.questionRadiosLabel}
                            key={answer.id}
                            value={answer.id.toString()}
                            control={<Radio color="default"/>}
                            label={answer.order.toString() + " - " + answer.text}
                            style={{
                                backgroundColor: appCtx.colors[index] + "55",
                                marginTop: "5px"
                            }}
                        />
                    ))}
                    <FormControlLabel
                        value={"not-answered-" + props.question.id}
                        className={classes.questionRadiosLabel}
                        control={<Radio color="default" />}
                        label="not answered yet"
                    />
                </RadioGroup>
            </FormControl>
        </LoadingOverlay>
    );
};
export default QuestionRadios;