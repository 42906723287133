import {useField} from "formik";
import {Checkbox, FormControlLabel, FormGroup} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {dangerColor} from "../../assets/jss/material-dashboard-pro-react";
const useStyles = makeStyles((theme) => ({
    error: {
        color: dangerColor[0]
    },
}));
const MyCheckbox = ({children, label, ...props}) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const classes = useStyles();

    const [field, meta] = useField({...props, type: 'checkbox'});
    return (
        <FormGroup>
            <FormControlLabel
                control={<Checkbox {...field} {...props}/>}
                label={label}
            />
            {meta.touched && meta.error ? (
                <div className={classes.error}>{meta.error}</div>
            ) : null}
        </FormGroup>
    );
};
export default MyCheckbox;