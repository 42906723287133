import React from 'react';
import Container from "@material-ui/core/Container";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import {FormattedMessage} from "react-intl";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    accordion: {
        width: '100%',
        marginBottom: "15px"
    },
    infoIcon: {
        color: theme.palette.primary.main,
        marginRight: "5px"
    }
}));
const Explanation = (props) => {
    const classes = useStyles();
    const replaceURLsWithLinks = (text) => {
        const urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        return text.replace(urlRegex, (url) => {
            return `<a href="${url}" target="_blank">${url}</a>`;
        });
    };
    const linkifiedExplanation = replaceURLsWithLinks(props.explanation);
    return (
            <Box mt={2} mb={2}>
                <Container>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <InfoIcon className={classes.infoIcon}/> <FormattedMessage defaultMessage="Show explanation"/>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{ __html: linkifiedExplanation }} />
                        </AccordionDetails>
                    </Accordion>
                </Container>
            </Box>
    );
};

export default Explanation;