import React, {useEffect} from "react";
import spinningTable from '~/assets/img/loading_animation.gif';

const LoadingPage = () => {
    const margin_top = ((window.innerHeight / 2 ) - 50).toString() + "px";
    // Workaround: For some reason app being stuck and ctx.initialized kept being false
    // useEffect(() => {
    //     setTimeout(() => {
    //         window.location.reload(false);
    //     }, 5000);
    // }, []);
    return (
        <img src={spinningTable} width="100px" style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: margin_top
        }}/>
    );
};

export default LoadingPage;