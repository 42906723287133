import React, {useContext, useState} from "react";
import MyDialog from "./MyDialog";
import {FormattedMessage} from "react-intl";
import Button from "../../CustomButtons/Button";
import QuestionnaireContext from "../../../context/questionnaire-context";
import {useQuery} from "react-query";
import axios from "axios";
import FileDownload from "js-file-download";
import {reportByQuestionURL} from "../../../utils/api";
import UserContext from "../../../context/user-context";
import ChaptersAccordion from "./ChapterAccordion";

const ReportByQuestionModal = (props) => {
    const [questions, setQuestions] = useState([]);
    const qContext = useContext(QuestionnaireContext);
    const ctx = useContext(UserContext);
    const isAnon = ctx.getIsAnon();

    const handleChange = (event) => {
        if (event.target.checked) {
            setQuestions([...questions, event.target.value]);
        } else {
            setQuestions(questions.filter((question) => {
                return question !== event.target.value;
            }));
        }
    };
    const handleChapterChecked = (event) => {
        let cat = qContext.chapters.find((chapter) => {
            return chapter.id === parseInt(event.target.value);
        });
        let question_ids = cat.questions.map((q) => {
            return q.id.toString();
        });
        if (event.target.checked) {
            setQuestions([...new Set([...questions, ...question_ids])]);
        } else {
            setQuestions(questions.filter((question) => {
                return !question_ids.includes(question);
            }));
        }
    };
    const exportAllQuery = useQuery(
        "export-all-questions",
        () => axios(reportByQuestionURL, {responseType: 'blob'})
            .then((res) => FileDownload(res.data, "RT-export-by-questions.xlsx")), {
            enabled: false
        });
    const exportByQuestions = useQuery(
        ['export-by-question', questions],
        () => axios(reportByQuestionURL, {
            responseType: 'blob',
            params: {
                questions: JSON.stringify(questions)
            }
        }).then((res) => FileDownload(res.data, "RT-export-by-questions.xlsx")), {
            enabled: false
        });
    const handleExportAll = () => {
        exportAllQuery.refetch();
    };
    const handleExportQuestions = () => {
        exportByQuestions.refetch();
    };
    return (
        <MyDialog
            id="report-by-question"
            open={props.open}
            onClose={props.onClose}
            isLoading={exportByQuestions.isLoading || exportAllQuery.isLoading}
            title={<FormattedMessage
                defaultMessage="Export Report by Questions"
            />}
            description={<FormattedMessage
                defaultMessage="Select chapters and questions to export."
            />}
            buttons={<React.Fragment>
                <Button variant="contained" onClick={handleExportAll}>Export all Questions</Button>
                <Button variant="contained" color="primary" onClick={handleExportQuestions}
                        disabled={questions.length === 0}>Export selected</Button>
            </React.Fragment>}
        >
            <ChaptersAccordion
                qContext={qContext}
                isAnon={isAnon}
                questions={questions}
                handleChapterChecked={handleChapterChecked}
                handleChange={handleChange}
            />
        </MyDialog>
    );
};

export default ReportByQuestionModal;