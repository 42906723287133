import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from "@material-table/core";
// import {ExportCsv, ExportPdf} from "@material-table/exporters";
import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {getChapterAdminURL} from "../../../utils/api";
import UserContext from "../../../context/user-context";
import {intToRoman} from "../../../utils/general-helpers";
import {Divider, Fab, FormControl, InputLabel, MenuItem, Select, Tooltip} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import QuestionEditDialog from "./QuestionEditDialog";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import LoadingOverlay from 'react-loading-overlay';
import ChapterEditDialog from "./ChapterEditDialog";
import {useSnackbar} from "notistack";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import {dangerColor, successColor} from "../../../assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles((theme) => ({
    answeredIcon: {
        color: successColor[0]
    },
    notAnsweredIcon: {
        color: dangerColor[0]
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fab: {
        float: 'right',
        margin: '5px',
    },
    questionInactive: {
        backgroundColor: '#888'
    },
}));

const QuestionTable = () => {
    const ctx = useContext(UserContext);
    const intl = useIntl();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const initialQuestionDialogState = {
        open: false,
        addOrEdit: 'add',
        chapterId: null,
        id: null,
        order: null,
        textDe: '',
        textEn: '',
        isMarkedAsSpecial: false,
        active: true,
        public: false,
        explanationDe: '',
        explanationEn: '',
        answers: [{
            order: 1,
            text_de: '',
            text_en: ''
        }],
        attachmentSet: []
    };
    const initialChapterDialogState = {
        open: false,
        addOrEdit: 'add',
        id: null,
        order: null,
        nameDe: '',
        nameEn: '',
        explanationDe: '',
        explanationEn: '',
        weight: 0,
        active: true,
        public: false
    };
    const [chapter, setChapter] = useState(null);
    const [selectedChapterId, setSelectedChapterId] = useState('');
    const [questionDialogState, setQuestionDialogState] = useState(initialQuestionDialogState);
    const [chapterDialogState, setChapterDialogState] = useState(initialChapterDialogState);
    const classes = useStyles();
    const chapterAdminQuery = useQuery('chapterAdmin', () => axios(getChapterAdminURL), {
        onSuccess: (data) => {
            if (selectedChapterId === '' && data && data.data && data.data.length > 0) {
                setSelectedChapterId(data.data[0].id);
            }
        },
    });
    const deleteChapterAdminQuery = useMutation(
        () => axios.delete(getChapterAdminURL + chapter.id + "/"), {
            onSuccess: () => {
                enqueueSnackbar(intl.formatMessage({defaultMessage: "Chapter deleted!"}), {variant: 'success'});
            },
            onSettled: () => {
                queryClient.invalidateQueries('chapterAdmin');
                queryClient.invalidateQueries('chapters');
            }
        }
    );
    useEffect(() => {
        if (chapterAdminQuery.data && chapterAdminQuery.data.data) {
            setChapter(chapterAdminQuery.data.data.find(c => {
                return c.id === selectedChapterId;
            }));
        }
    }, [selectedChapterId, chapterAdminQuery]);
    useEffect(() => {
        if (chapter) {
            setQuestionDialogState({...questionDialogState, chapterId: chapter.id});
        }
    }, [chapter]);
    const columns = [
        {title: "#", field: "order", width: "5%", render: rowData => rowData["order"] + ".)"},
        {title: "Text", field: ctx.locale === 'en' ? "text_en" : "text_de", width: "50%"},
        {title: "Special Attention", field: "is_marked_as_special", render: rowData => (rowData["is_marked_as_special"] ?
                <CheckCircleOutlineIcon className={classes.answeredIcon} /> :
                <CancelIcon className={classes.notAnsweredIcon} />)},
        {title: "Public", field: "public", render: rowData => (rowData["public"] ?
                <CheckCircleOutlineIcon className={classes.answeredIcon} /> :
                <CancelIcon className={classes.notAnsweredIcon} />)},
        // {title: "Active", field: "active", render: rowData => (rowData["active"] ?
        //         <CheckCircleOutlineIcon className={classes.answeredIcon} /> :
        //         <CancelIcon className={classes.notAnsweredIcon} />)},
    ];

    const handleQuestionClose = () => {
        const order = chapter.questions.length > 0 ? chapter.questions[chapter.questions.length - 1].order + 1 : 1;
        setQuestionDialogState({
            ...initialQuestionDialogState,
            order: order,
            chapterId: chapter.id
        });
    };
    const handleRowClick = (event, rowData) => {
        setQuestionDialogState({
            open: true,
            addOrEdit: 'edit',
            order: rowData['order'],
            chapterId: chapter.id,
            id: rowData['id'],
            textDe: rowData["text_de"] ?? '',
            textEn: rowData["text_en"] ?? '',
            isMarkedAsSpecial: rowData["is_marked_as_special"],
            active: rowData["active"],
            public: rowData["public"],
            explanationDe: rowData["explanation_de"] ?? '',
            explanationEn: rowData["explanation_en"] ?? '',
            answers: rowData["answers"],
            attachmentSet: rowData["attachment_set"]
        });
    };
    const handleSelectChapter = (event) => {
        setSelectedChapterId(event.target.value);
    };
    const handleAddQuestionClick = () => {
        const last_active_question = chapter.questions.slice().reverse().find( question => question.active === true);
        let order = 1;
        if (last_active_question) {
            order = last_active_question.order + 1;
        }
        setQuestionDialogState({
            ...initialQuestionDialogState,
            open: true,
            order: order,
            chapterId: chapter.id
        });
    };
    const handleAddChapterClick = () => {
        const order = chapterAdminQuery.data.data.length > 0 ?
            chapterAdminQuery.data.data[chapterAdminQuery.data.data.length - 1].order + 1 : 1;
        setChapterDialogState({...initialChapterDialogState, open: true, order: order});
    };
    const handleChapterAdded = (id) => {
        setSelectedChapterId(id);
    };
    const handleEditChapterClick = () => {
        setChapterDialogState({
            open: true,
            addOrEdit: 'edit',
            id: chapter.id,
            nameDe: chapter.name_de,
            nameEn: chapter.name_en,
            explanationDe: chapter.explanation_de,
            explanationEn: chapter.explanation_en,
            weight: chapter.weight,
            order: chapter.order,
            active: chapter.active,
            public: chapter.public
        });
    };
    const handleChapterClose = () => {
        setChapterDialogState(initialChapterDialogState);
    };
    const handleDeleteChapterClick = () => {
        deleteChapterAdminQuery.mutate();
        // TODO: This should select the first category if exists (same in DownloadsTable)
        setSelectedChapterId('');
    };
    const handleReorderQuestions = async () => {
        const response = await axios.get("/reorder_questions/").then(
            () => {
                queryClient.invalidateQueries('chapterAdmin');
                queryClient.invalidateQueries('chapters');
            }
        );
    };
    return (
            <>
            {chapterAdminQuery.data && chapterAdminQuery.data.data &&
                (
            <>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel><FormattedMessage defaultMessage="Chapter"/></InputLabel>
                        <Select
                            value={selectedChapterId}
                            onChange={handleSelectChapter}
                        >
                            {chapterAdminQuery.data.data.map(chapter => (
                                <MenuItem key={chapter.id} value={chapter.id}>{intToRoman(chapter.order) + " " +
                                (ctx.locale === 'en' ? chapter["name_en"] : chapter["name_de"])}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12} sm={6}>
                    <Fab aria-label="edit chapter" onClick={handleEditChapterClick}
                         variant="extended" className={classes.fab}
                    >
                        <FormattedMessage defaultMessage="Edit Chapter"/>
                        <EditIcon/>
                    </Fab>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {chapter &&
                        <Tooltip title={intl.formatMessage({defaultMessage: "Delete all questions first"})}>
                            <span>
                                <Fab aria-label="delete chapter" onClick={handleDeleteChapterClick}
                                     variant="extended" disabled={chapter.questions.length > 0}
                                >
                                    <FormattedMessage defaultMessage="Delete Chapter"/>
                                    <DeleteIcon/>
                                </Fab>
                            </span>
                        </Tooltip>
                    }
                </Grid>
                <Grid item xs={12} sm={6}>

                <Fab color="primary" aria-label="add chapter" onClick={handleAddChapterClick}
                         variant="extended" className={classes.fab}
                    >
                        <FormattedMessage defaultMessage="Add Chapter"/>
                        <AddIcon/>
                    </Fab>
                </Grid>

                <Grid item xs={12} sm={12}>

                    {chapter &&
                        <Fab color="primary" aria-label="add question" onClick={handleAddQuestionClick}
                             variant="extended" className={classes.fab}
                        >
                            <FormattedMessage defaultMessage="Add Question"/>
                            <AddIcon/>
                        </Fab>
                    }
                    <Fab color="primary" onClick={handleReorderQuestions} variant="extended" className={classes.fab}>
                        <FormattedMessage defaultMessage="Re-order All Questions" />
                    </Fab>
                </Grid>
            </Grid>

            </>
            )
            }
            <Divider/>
            {chapter &&
            <div>
                <LoadingOverlay
                    active={chapterAdminQuery.isFetching || !chapterAdminQuery.data || !chapterAdminQuery.data.data || !chapter}
                    spinner
                >
                <MaterialTable
                    key={chapter.id}
                    onRowClick={handleRowClick}
                    title={intToRoman(chapter.order) + " " +
                    (ctx.locale === 'en' ? chapter["name_en"] : chapter["name_de"])}
                    columns={columns}
                    data={chapter.questions}
                    // actions={[
                    //     rowData => ({
                    //         icon: () => <StarBorderIcon/>,
                    //         tooltip: 'Mark as "Needs attention"',
                    //         hidden: rowData.is_marked_as_special
                    //     }),
                    //     rowData => ({
                    //         icon: () => <StarIcon/>,
                    //         tooltip: 'Unmark as "Needs attention"',
                    //         hidden: !rowData.is_marked_as_special
                    //     }),
                    //     rowData => ({
                    //         icon: () => <BlockIcon/>,
                    //         tooltip: 'Set to inactive',
                    //         hidden: !rowData.active
                    //     }),
                    //     rowData => ({
                    //         icon: () => <DeleteIcon/>,
                    //         tooltip: 'Delete',
                    //         hidden: rowData.active
                    //     }),
                    //     rowData => ({
                    //         icon: () => <PublicIcon/>,
                    //         tooltip: 'Set public',
                    //         hidden: rowData.public
                    //     }),
                    //     rowData => ({
                    //         icon: () => <VpnLockIcon/>,
                    //         tooltip: 'Unset public',
                    //         hidden: !rowData.public
                    //     }),
                    //
                    // ]}
                    options={{
                        // pageSize: 100,
                        paging: false,
                        // header: false,
                        tableLayout: "fixed",
                        // exportMenu: [{
                        //     label: 'Export PDF',
                        //     //// You can do whatever you wish in this function. We provide the
                        //     //// raw table columns and table data for you to modify, if needed.
                        //     // exportFunc: (cols, datas) => console.log({ cols, datas })
                        //     exportFunc: (cols, datas) => ExportPdf(cols, datas, 'RT_Exported_Questions_Chapter_' + chapter.order)
                        // }, {
                        //     label: 'Export CSV',
                        //     exportFunc: (cols, datas) => ExportCsv(cols, datas, 'RT_Exported_Questions_Chapter_' + chapter.order)
                        // }],
                        actionsColumnIndex: -1,
                        rowStyle: (rowData) => {
                            return {
                              backgroundColor: rowData.active ? "white" : "#888"
                            };
                        }
                    }}
                />
                </LoadingOverlay>

                <Divider/>
            </div>
            }
            <QuestionEditDialog dialogState={questionDialogState} chapters={chapterAdminQuery.isSuccess && chapterAdminQuery.data.data} onClose={handleQuestionClose}/>
            <ChapterEditDialog dialogState={chapterDialogState} onClose={handleChapterClose}/>
</>
    );
};

export default QuestionTable;