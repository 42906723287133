import React, {useContext} from 'react';
import UserContext from "../../context/user-context";
import {FormControlLabel, FormGroup, Switch} from "@material-ui/core";
import {useIntl} from "react-intl";
import {useQueryClient} from "react-query";
import {makeStyles, withStyles} from "@material-ui/core/styles";
const WhiteBgSwitch = withStyles({
    switchBase: {
        // '&$checked': {
        //     color: purple[500],
        // },
        '&$checked + $track': {
            backgroundColor: '#eee',
            opacity: '0.9'
        },
    },
    checked: {},
    track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
    formControlLabel: {
        color: '#fff',
        paddingLeft: '5px'
    }
}));

const RoSwitch = () => {
    const classes = useStyles();
    const ctx = useContext(UserContext);
    const handleChange = (event) => {
        event.target.checked ? ctx.onSetRoSwitchTrue() : ctx.onSetRoSwitchFalse();
    };
    return (
        <FormGroup row>
            <FormControlLabel
                className={classes.formControlLabel}
                control={
                    <WhiteBgSwitch
                        checked={ctx.roSwitchEnabled}
                        onChange={handleChange}
                        // color="default"
                    />}
                label="RO"
            />
        </FormGroup>
    );
};

export default RoSwitch;