import React from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import {getCountryAdminURL} from "../../../utils/api";
import {Dialog, DialogActions, DialogContent, DialogContentText, Grid} from "@material-ui/core";
import DialogTitle from "../../base/modals/DialogTitle";
import {Formik, Field, Form, ErrorMessage, useField} from 'formik';
import * as Yup from 'yup';
import MyTextInput from "../../form/MyTextInput";
import MyCheckbox from "../../form/MyCheckbox";
import Button from "@material-ui/core/Button";
import {useSnackbar} from "notistack";


const CountryEditDialog = (props) => {
    const queryClient = useQueryClient();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const addCountry = useMutation(
        country => axios.post(getCountryAdminURL, {
            name_de: country.nameDe,
            name_en: country.nameEn,
            public: country.public,
            active: country.active,
            code: country.code,
            code_label: country.codeLabel
        }), {
            onSuccess: () => {
                enqueueSnackbar(intl.formatMessage({defaultMessage: "Country added!"}), {variant: 'success'});
                props.onClose();
            },
            onSettled: () => {
                queryClient.invalidateQueries('countryAdmin');
                queryClient.invalidateQueries('countries');
            }
        }
    );
    const updateCountry = useMutation(
        country => axios.put(getCountryAdminURL + country.id + "/", {
            name_de: country.nameDe,
            name_en: country.nameEn,
            public: country.public,
            active: country.active,
            code: country.code,
            code_label: country.codeLabel
        }), {
            onSuccess: () => {
                enqueueSnackbar(intl.formatMessage({defaultMessage: "Country edited!"}), {variant: 'success'});
                queryClient.invalidateQueries('countryAdmin');
                queryClient.invalidateQueries('countries');
                props.onClose();
            }
        }
    );
    return (
        <Dialog open={props.dialogState.open} onClose={props.onClose}>
            <DialogTitle>{
                props.dialogState.addOrEdit === 'add' ? <FormattedMessage defaultMessage="Add country"/> :
                    <FormattedMessage defaultMessage="Edit country"/>
            }</DialogTitle>
            <Formik
                initialValues={{
                    nameDe: props.dialogState.nameDe,
                    nameEn: props.dialogState.nameEn,
                    code: props.dialogState.code,
                    codeLabel: props.dialogState.codeLabel,
                    public: props.dialogState.public,
                    active: props.dialogState.active,
                }}
                validationSchema={Yup.object({
                    nameDe: Yup.string().max(50, ""),
                    nameEn: Yup.string().max(50, ""),
                    code: Yup.string().max(4, ""),
                    codeLabel: Yup.string().max(4, ""),
                    public: Yup.boolean(),
                    active: Yup.boolean(),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    if (props.dialogState.addOrEdit === 'add') {
                        addCountry.mutate(values);
                    } else {
                        values["id"] = props.dialogState.id;
                        updateCountry.mutate(values);
                    }
                }}
                enableReinitialize={true}
            >
                {
                    formik => (
                        <Form>
                            <DialogContent>
                                <DialogContentText>

                                </DialogContentText>
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <MyTextInput
                                            name="nameDe"
                                            label="name DE"
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MyTextInput
                                            name="nameEn"
                                            label="name EN"
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MyTextInput
                                            name="code"
                                            label="Code"
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MyTextInput
                                            name="codeLabel"
                                            label="Code Label"
                                            type="text"
                                        />
                                    </Grid>
                                </Grid>
                                <MyCheckbox
                                    name="active"
                                    label={intl.formatMessage({defaultMessage: "is active"})}
                                />
                                <MyCheckbox
                                    name="public"
                                    label={intl.formatMessage({defaultMessage: "is public"})}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={props.onClose} color="secondary" disabled={formik.isSubmitting}>
                                    <FormattedMessage defaultMessage="Cancel"/>
                                </Button>
                                <Button type="submit" color="primary" disabled={formik.isSubmitting}>
                                    <FormattedMessage defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </Form>
                    )
                }
            </Formik>
        </Dialog>
    );
};

export default CountryEditDialog;