import React, {useContext} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography,} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import QuestionRadios from "../question/QuestionRadios";
import {makeStyles} from "@material-ui/core/styles";
import {
    dangerBoxShadow,
    dangerColor,
    successBoxShadow,
    successColor
} from "../../assets/jss/material-dashboard-pro-react";
import Button from "../CustomButtons/Button.js";
import {FormattedMessage, useIntl} from "react-intl";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {rtColors} from "../../assets/jss/rt-theme";
import UserContext from "../../context/user-context";
import ConditionalWrapper from "../../utils/ConditionalWrapper";
import {useHistory} from "react-router-dom";
import cx from "classnames";
import Grid from "@material-ui/core/Grid";
import {AnsweredIcon, NotAnsweredIcon, StarIcon} from "../misc/icons";
import Explanation from "../question/Explanation";

const useStyles = makeStyles((theme) => ({
    answered: {
        color: rtColors.grey1,
        ...successBoxShadow,
        border: "1px solid " + successColor[0]

    },
    notAnswered: {
        ...dangerBoxShadow,
        border: "1px solid " + dangerColor[0]

    },
    specialQuestion: {
    },

    accordionDetails: {
        padding: "8px 32px 16px"
    },
    showMapBtn: {

    },
    iframeMap: {
        height: "600px",
        border: 'none',
        width: '100%'
    }
}));

const QuestionAccordion = (props) => {
    const ctx = useContext(UserContext);
    const classes = useStyles();
    let history = useHistory();
    const anonOrRo = ctx.getIsAnonOrRo();
    const isAnon = ctx.getIsAnon();
    const handleDetailViewClick = (event, questionId) => {
        event.stopPropagation();
        history.push("/question/" + questionId + "/");
    };
    const authString = ctx.isLoggedIn ? "&auth=" + ctx.accessToken : "";
    const forceRoString = ctx.roSwitchEnabled ? "&force_ro=1" : "";
    return <React.Fragment>

            {props.questions.map((question, index) => (
                <ConditionalWrapper
                    key={index}
                    condition={isAnon && !question.public}
                    wrapper={(children) => <Tooltip title={<FormattedMessage
                        defaultMessage="This question is currently under review"/>}>{children}</Tooltip>}
                >
                    <Accordion key={index} TransitionProps={{ unmountOnExit: true }} disabled={isAnon && !question.public}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                        className={cx({[classes.answered]: !anonOrRo && question.is_answered},
                                            {[classes.notAnswered]: !anonOrRo && !question.is_answered},
                                            {[classes.specialQuestion]: question.is_marked_as_special})}>
                        {anonOrRo ? (
                            <Grid container>
                                <Grid item  md={8} sm={6} >
                                    <p>
                                        {question.order}. {question.text}
                                    </p>
                                </Grid>
                                <Grid item xs={1}>
                                    <span>
                                        {
                                            question.is_marked_as_special &&
                                            <StarIcon />
                                        }
                                    </span>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="button"
                                        size="sm"
                                        style={{
                                            marginLeft: "auto",
                                            height: "18px"
                                        }}
                                        onClick={((e) => handleDetailViewClick(e, question.id))}
                                    >
                                        <FormattedMessage
                                            defaultMessage="detail view"
                                        />
                                        <ArrowForwardIosIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container>
                                <Grid item xs={1}>
                                    <span>
                                        {question.is_answered ?
                                                <AnsweredIcon /> :
                                                <NotAnsweredIcon />
                                        }
                                    </span>
                                    <span>
                                        {
                                            question.is_marked_as_special &&
                                            <StarIcon />
                                        }
                                    </span>
                                </Grid>
                                <Grid item md={8} sm={6}>
                                    <p>
                                        {question.order}. {question.text}
                                    </p>
                                </Grid>
                                {/*<Grid item xs={1}>*/}
                                {/*   */}
                                {/*</Grid>*/}
                                <Grid item xs={2}>
                                    <Button
                                        type="button"
                                        size="sm"
                                        style={{
                                            marginLeft: "auto",
                                            height: "18px"
                                        }}
                                        onClick={((e) => handleDetailViewClick(e, question.id))}
                                    >
                                        <FormattedMessage
                                            defaultMessage="detail view"
                                        />
                                        <ArrowForwardIosIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </AccordionSummary>
                    {!(isAnon && !question.public) &&
                    <AccordionDetails>
                        <Grid container>

                        {
                            !anonOrRo &&
                                <Grid item xs={4}>
                            <QuestionRadios question={question}/>
                                </Grid>
                        }
                        <Grid item xs={!anonOrRo ? 8 : 12}>
                        <iframe className={classes.iframeMap}
                                id={"map-iframe-" + question.id}
                                title="result chart"
                                // srcDoc={question.iframeContent}
                                src={"/map-service/" + question.id + "/?lang=" + ctx.locale + authString + forceRoString}
                        />
                        </Grid>
                        {question.explanation && question.explanation.length > 0 &&
                            <Grid item xs={12}>
                                <Explanation explanation={question.explanation} />
                            </Grid>
                        }

                        </Grid>

                    </AccordionDetails>
                        }
                </Accordion>
                </ConditionalWrapper>
            ))}
        </React.Fragment>;
};


export default QuestionAccordion;