import React, {useContext, useEffect} from 'react';
import BaseLayout from './components/base/BaseLayout';
import UserContext from "./context/user-context";
import axios from "axios";
import axios_init_config, {
    getChapterURL,
    getCountriesURL,
    getUserinfoURL
} from "./utils/api";
import ErrorPage from "./components/misc/ErrorPage";
import QuestionnaireContext from "./context/questionnaire-context";
import {useQuery, useQueryClient} from "react-query";
import CountryContext from "./context/country-context";
import LoadingPage from "./components/base/LoadingPage";
import {useHistory} from "react-router-dom";
import AppInfoContext from "./context/appinfo-context";
import Login from "./components/login/Login";


const RestoredApp = () => {
    const ctx = useContext(UserContext);
    const questionnaireCtx = useContext(QuestionnaireContext);
    const countryCtx = useContext(CountryContext);
    const appCtx = useContext(AppInfoContext);
    const history = useHistory();
    const queryClient = useQueryClient();
    axios_init_config(ctx);
    useEffect(() => {
        if (
            ctx.isLoggedIn &&
            (location.pathname.includes("/login") || location.pathname === "/")
        ) {
            history.push("/dashboard");
        } else if (!ctx.isLoggedIn && !appCtx.rtAllowAnonUsers &&
            (location.pathname !== "/" || !location.pathname.includes("/login"))) {
            history.push("/login");
        }
    }, [ctx.isLoggedIn]);
    useEffect(() => {
        // Chapters need to be reloaded when language changes or current country changes
        axios.defaults.headers['Accept-Language'] = ctx.locale;
        // questionnaireCtx.onReloadChapters(null);
        queryClient.invalidateQueries('appInfo');   // This should probably be in the next useEffect instead
        queryClient.invalidateQueries('chapters');
        queryClient.invalidateQueries('countries');
    }, [ctx.locale, ctx.currentCountry]);
    useEffect(() => {
        axios.defaults.headers['Accept-Language'] = ctx.locale;
        queryClient.invalidateQueries('userinfo');
        queryClient.invalidateQueries('downloadables');
    }, [ctx.locale]);
    // useEffect(() => {
    //     // Chapters need to be reloaded when language changes or current country changes
    //     if (ctx.currentCountry && countryCtx.countries && countryCtx.countries.length > 0){
    //         const updatedCurrentCountry = countryCtx.countries.find((country) => country.code === ctx.currentCountry.code);
    //         ctx.onSetCurrentCountry(updatedCurrentCountry);
    //     }
    // }, [countryCtx.countries]);
    useEffect(() => {
        queryClient.invalidateQueries('chapters');
        queryClient.invalidateQueries('countries');
        queryClient.invalidateQueries('downloadables');
    }, [ctx.roSwitchEnabled]);

    const userInfoQuery = useQuery('userinfo', () => axios(getUserinfoURL), {
        enabled: ctx.isLoggedIn === true,
        onError: error => {
            // console.log(error);
        },
        onSuccess: (data) => {
            if (!data) {
                // console.log(data);
            } else if (!data.data) {
                // console.log(data.props.message);
            } else {
                ctx.onUserInfo(data.data);
            }
        },
    });
    // const appInfoQuery = useQuery('appInfo', () => axios(getAppinfoURL), {
    //     enabled: true,
    //     onSuccess: (data) => {
    //         appCtx.onAppInfo(data.data);
    //
    //     },
    // });
    const fetchChapters = (country) => {
        return axios(getChapterURL, {
            params: country ? {
                country: country
            } : {}
        });
    };
    const countryCode = !ctx.getIsAnonOrRo() && ctx.currentCountry ? ctx.currentCountry.code : null;
    const chapterQuery = useQuery(['chapters', countryCode],
        () => fetchChapters(countryCode), {
            onSuccess: (data) => {
                questionnaireCtx.onReloadChapters(data.data);
            },
        }
    );
    const countryQuery = useQuery('countries',
        () => axios(getCountriesURL), {
            enabled: ctx.initialized,
            onSuccess: (data) => {
                countryCtx.reloadCountries(data.data);
            },
        });

    if (chapterQuery.isError || countryQuery.isError || userInfoQuery.isError) {
        return <ErrorPage message={"An unexpected error occured"}/>;
    }
    
    if (ctx.accessToken) {
        axios.defaults.headers['Authorization'] = "JWT " + ctx.accessToken;
    }
    axios.defaults.headers['Accept-Language'] = ctx.locale;
    return (
        appCtx.rtPreviousDeadline &&
        questionnaireCtx.chapters &&
        countryCtx.countries &&
        (
            (ctx.isLoggedIn === false) ||
            // logged in has RO set
            (ctx.isLoggedIn && ctx.isReadonly === false && ctx.currentCountry && ctx.userCountries) ||
            (ctx.isLoggedIn && ctx.isReadonly === true)
        ) ?
            location.pathname === "/login" || location.pathname === '/login/' ? <Login /> :
            <>
                <BaseLayout/>
            </>
            :
            <LoadingPage />
    );
};

export default RestoredApp;

