import React, {useEffect, useState} from 'react';
import {IntlProvider} from "react-intl";
import German from "../../compiled-lang/de";
import English from "../../compiled-lang/en";
// TODO: token probably dont have to be stored in context

const initialState = {
    accessToken: '',
    refreshToken: '',
    userCountries: [],
    currentCountry: {},
    locale: 'en',
    messages: {},
    isReadonly: false,
    isAdmin: false,
    firstname: "",
    lastname: "",
    isLoggedIn: false,
    initialized: false,
    showWelcomeMsg: true,
    roSwitchEnabled: false,
    disclaimerAccepted: false
};
const UserContext = React.createContext({
    ...initialState,
    onLogin: (userData) => {
    },
    onLogout: () => {
    },
    onSetCurrentCountry: () => {
    },
    onLocaleChange: (code) => {
    },
    onNewTokens: (access, refresh) => {
    },
    onUserInfo: (userInfo) => {
    },
    onAppInfo: (appInfo) => {
    },
    onCurrentCountryConfirmed: () => {
    },
    onSetRoSwitchTrue : () => {

    },
    onSetRoSwitchFalse : () => {

    },
    getIsAnonOrRo: () => {

    },
    getIsAnon: () => {

    },
    onSetDisclaimerAccepted: () => {

    }
});

export const UserContextProvider = (props) => {
    const [userState, setUserState] = useState(
        initialState
    );
    useEffect(() => {
        restoreUserData();
    }, []);
    const setStored = (storedString, default_, parseJson) => {
        if (parseJson){
            return storedString !== null && storedString !== 'undefined' ? JSON.parse(storedString) : default_;
        } else {
            return storedString !== null && storedString !== 'undefined' ? storedString : default_;
        }
    };
    const restoreUserData = () => {
        const storedAccessToken = localStorage.getItem('accessToken');
        const storedRefreshToken = localStorage.getItem('refreshToken');
        const storedCurrentCountry = localStorage.getItem('currentCountry');
        const storedCountries = localStorage.getItem('userCountries');
        const storedIsReadonly = localStorage.getItem('isReadonly');
        const storedIsAdmin = localStorage.getItem('isAdmin');
        const storedFirstname = localStorage.getItem('firstname');
        const storedLastname = localStorage.getItem('lastname');
        const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
        const storedShowWelcomeMsg = localStorage.getItem('showWelcomeMsg');
        const storedRoSwitchEnabled = localStorage.getItem('roSwitchEnabled');
        const storedDisclaimerAccepted = localStorage.getItem('disclaimerAccepted');
        const storedLocale = localStorage.getItem('locale') ?? 'en';
        const messages = storedLocale === 'de' ? German : English;
        setUserState({
            locale: storedLocale === 'de' ? 'de' : 'en',
            messages: messages,
            accessToken: setStored(storedAccessToken, initialState.accessToken, false),
            refreshToken: setStored(storedRefreshToken,initialState.refreshToken, false),
            firstname: setStored(storedFirstname, initialState.firstname, false),
            lastname: setStored(storedLastname, initialState.lastname, false),
            userCountries: setStored(storedCountries, initialState.userCountries, true),
            currentCountry: setStored(storedCurrentCountry, initialState.currentCountry, true),
            isReadonly: setStored(storedIsReadonly, initialState.isReadonly, true),
            isAdmin: setStored(storedIsAdmin, initialState.isAdmin, true),
            isLoggedIn: setStored(storedIsLoggedIn, initialState.isLoggedIn, true),
            showWelcomeMsg: setStored(storedShowWelcomeMsg, initialState.showWelcomeMsg, true),
            initialized: true,
            roSwitchEnabled: setStored(storedRoSwitchEnabled, initialState.roSwitchEnabled, true),
            disclaimerAccepted : setStored(storedDisclaimerAccepted, initialState.disclaimerAccepted, true)
        });
    };
    const loginHandler = (userData) => {
        localStorage.setItem('accessToken', userData.access);
        localStorage.setItem('refreshToken', userData.refresh);
        localStorage.setItem('isLoggedIn', JSON.stringify(true));
        restoreUserData();
    };
    const setUserInfo = (userInfo) => {
        const isReadOnly = userInfo.isReadonly;
        localStorage.setItem('isReadonly', isReadOnly);
        localStorage.setItem('isAdmin', userInfo.isAdmin);
        localStorage.setItem('firstname', userInfo.firstname);
        localStorage.setItem('lastname', userInfo.lastname);
        const storedLocale = localStorage.getItem('locale') ?? 'en';
        localStorage.setItem('locale', storedLocale);
        const messages = storedLocale === 'de' ? German : English;
        let currentCountry;
        if (!isReadOnly) {
            const userCountries = JSON.stringify(userInfo.countries);
            const storedCurrentCountry = localStorage.getItem('currentCountry');
            if (storedCurrentCountry !== null && storedCurrentCountry !== 'undefined' && storedCurrentCountry !== 'null') {
                currentCountry = JSON.parse(storedCurrentCountry);
                // check if currentCountry is available in userCountries
                if(!userInfo.countries.find(country => {
                    return country.code === currentCountry.code;
                })) {
                    currentCountry = userInfo.countries[0];
                }
            } else {
                currentCountry = userInfo.countries[0];
            }
            localStorage.setItem('userCountries', userCountries);
            localStorage.setItem('currentCountry', JSON.stringify(currentCountry));
        } else {
            currentCountry = {};
        }
        setUserState({
            ...userState,
            locale: storedLocale === 'de' ? 'de' : 'en',
            messages: messages,
            userCountries: userInfo.countries,
            currentCountry: currentCountry,
            isReadonly: userInfo.isReadonly,
            isAdmin: userInfo.isAdmin,
            firstname: userInfo.firstname,
            lastname: userInfo.lastname
        });

    };
    const currentCountryHandler = (country) => {
        localStorage.setItem('currentCountry', JSON.stringify(country));
        setUserState({
            ...userState,
            currentCountry: country,
        });
    };
    const logoutHandler = () => {
        // Keep language setting
        const locale = localStorage.getItem('locale', 'en');
        // Keep current country
        const currentCountry = localStorage.getItem('currentCountry', {});
        localStorage.clear();
        localStorage.setItem('locale', locale);
        localStorage.setItem('currentCountry', currentCountry);
        restoreUserData();
        // setUserState({
        //     ...initialState,
        //     initialized: true
        // });
    };
    const localeChangeHandler = (code) => {
        localStorage.setItem('locale', code);

        let messages = code === 'de' ? German : English;
        setUserState({
            ...userState,
            locale: code,
            messages: messages,
        });
    };
    const setNewTokens = (access, refresh = undefined) => {
        localStorage.setItem('accessToken', access);
        if (refresh) {
            localStorage.setItem('refreshToken', refresh);
            setUserState({
                ...userState,
                accessToken: access,
                refreshToken: refresh,
            });
        } else {
            setUserState({
                ...userState,
                accessToken: access
            });
        }
    };
    const setCurrentCountryConfirmed = (confirmed) => {
        let newCurrentCountry = {...userState.currentCountry, checked_upcoming_dl_valid: confirmed};
        localStorage.setItem('currentCountry', JSON.stringify(newCurrentCountry));
        setUserState({
            ...userState,
            currentCountry: newCurrentCountry
        });
    };
    const setShowWelcomeMsgFalse = () => {
        localStorage.setItem('showWelcomeMsg', "false");
        setUserState({
            ...userState,
            showWelcomeMsg: false
        });
    };
    const setRoSwitchTrue = () => {
        localStorage.setItem('roSwitchEnabled', "true");
        setUserState({
            ...userState,
            roSwitchEnabled: true
        });
    };
    const setRoSwitchFalse = () => {
        localStorage.setItem('roSwitchEnabled', "false");
        setUserState({
            ...userState,
            roSwitchEnabled: false
        });
    };
    const setDisclaimerAccepted = () => {
      localStorage.setItem('disclaimerAccepted', "true");
      setUserState({
          ...userState,
          disclaimerAccepted: true
      });
    };
    const isAnonOrRo = () => {
        return !(userState.isLoggedIn && !userState.isReadonly && !userState.roSwitchEnabled);
    };

    const isAnon = () => {
        return !userState.isLoggedIn;
    };
    return (
        <UserContext.Provider value={
            {
                accessToken: userState.accessToken,
                refreshToken: userState.refreshToken,
                userCountries: userState.userCountries,
                currentCountry: userState.currentCountry,
                locale: userState.locale,
                isReadonly: userState.isReadonly,
                isAdmin: userState.isAdmin,
                firstname: userState.firstname,
                lastname: userState.lastname,
                isLoggedIn: userState.isLoggedIn,
                initialized: userState.initialized,
                showWelcomeMsg: userState.showWelcomeMsg,
                roSwitchEnabled: userState.roSwitchEnabled,
                disclaimerAccepted: userState.disclaimerAccepted,
                onLogin: loginHandler,
                onLogout: logoutHandler,
                onSetCurrentCountry: currentCountryHandler,
                onLocaleChange: localeChangeHandler,
                onNewTokens: setNewTokens,
                onUserInfo: setUserInfo,
                onCurrentCountryConfirmed: setCurrentCountryConfirmed,
                onSetWelcomeMsgFalse: setShowWelcomeMsgFalse,
                onSetRoSwitchTrue: setRoSwitchTrue,
                onSetRoSwitchFalse: setRoSwitchFalse,
                getIsAnonOrRo: isAnonOrRo,
                getIsAnon: isAnon,
                onSetDisclaimerAccepted: setDisclaimerAccepted
            }
        }>
            <IntlProvider locale={userState.locale}
                        messages={userState.messages}
                        onError={() => {}}
            >
                {props.children}
            </IntlProvider>
        </UserContext.Provider>
    );
};

export default UserContext;
