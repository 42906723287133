
import React from "react";
import {
    Dialog, DialogActions, DialogContent, DialogContentText
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import LoadingOverlay from 'react-loading-overlay';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

const styles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        margin: 0,
        padding: theme.spacing(2)
    },
    paper: {
        // width: '80%',
        maxHeight: 435,
        textAlign: 'center'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const MyDialog = (props) => {
    const classes = styles();
    return (
        <Dialog
            aria-labelledby={props.id + "-modal-title"}
            aria-describedby={props.id + "-modal-description"}
            open={props.open}
            onClose={props.onClose}
            scroll={"paper"}
        >
            <LoadingOverlay
                active={props.isLoading}
                spinner
            >
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">{props.title}</Typography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                </MuiDialogTitle>
                <DialogContent
                    className={classes.paper}
                    dividers
                >
                    <DialogContentText tabIndex={-1} id={props.id + "-modal-description"}>
                        {props.description}
                    </DialogContentText>

                    {props.children}

                </DialogContent>
                <DialogActions>
                    {props.buttons}
                </DialogActions>
            </LoadingOverlay>
        </Dialog>
    );
};

export default MyDialog;