import {
    Checkbox, Chip,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControlLabel,
    FormGroup,
    FormLabel, Input, InputLabel, MenuItem,
    Radio,
    RadioGroup,
    TextField
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {FormattedMessage, useIntl} from "react-intl";
import React, {useContext} from "react";
import Button from "@material-ui/core/Button";
import {Formik, Field, Form, ErrorMessage, useField} from 'formik';
import * as Yup from 'yup';
import CountryContext from "../../../context/country-context";
import {Select} from "formik-material-ui";
import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import {getUsersURL} from "../../../utils/api";
import MyTextInput from "../../form/MyTextInput";
import MyRadios from "../../form/MyRadios";
import MyCheckbox from "../../form/MyCheckbox";
import {useSnackbar} from "notistack";


const UserEditDialog = (props) => {
    const intl = useIntl();
    const countryCtx = useContext(CountryContext);
    const queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar();
    const addUser = useMutation(
        user => axios.post(getUsersURL, {
            email: user.email,
            first_name: user.firstName,
            last_name: user.lastName,
            is_active: user.isActive,
            is_readonly: user.userType === 'ro',
            countries: user.selectedCountries
        }), {
            onError: error => {
                let errorString = "";
                for (let key in error.response.data) {
                    errorString = key + ": " + error.response.data[key];
                }
                enqueueSnackbar(intl.formatMessage({defaultMessage: "Error adding user: "}) + errorString, {variant: 'error'});
            },
            onSuccess: () => {
                enqueueSnackbar(intl.formatMessage({defaultMessage: "User added!"}), {variant: 'success'});
                props.onClose();
            },
            onSettled: () => {
                queryClient.invalidateQueries('users');
            }
        }
    );
    const updateUser = useMutation(
        user => axios.put(getUsersURL + user.id + "/", {
            email: user.email,
            first_name: user.firstName,
            last_name: user.lastName,
            is_active: user.isActive,
            is_readonly: user.userType === 'ro',
            countries: user.selectedCountries
        }), {
            onError: error => {
                let errorString = "";
                for (let key in error.response.data) {
                    errorString = key + ": " + error.response.data[key];
                }
                enqueueSnackbar(intl.formatMessage({defaultMessage: "Error editing user: "}) + errorString, {variant: 'error'});
            },
            onSuccess: () => {
                enqueueSnackbar(intl.formatMessage({defaultMessage: "User edited!"}), {variant: 'success'});
                props.onClose();
            },
            onSettled: () => {
                queryClient.invalidateQueries('users');
            }
        }
    );
    return (
        <Dialog open={props.dialogState.open} onClose={props.onClose} aria-labelledby="user-add-edit-title">
            <DialogTitle id="user-add-edit-title">{
                props.dialogState.addOrEdit === 'add' ? <FormattedMessage defaultMessage="Add user"/> :
                    <FormattedMessage defaultMessage="Edit user"/>
            }</DialogTitle>
            <Formik
                initialValues={{
                    id: props.dialogState.id,
                    firstName: props.dialogState.firstName,
                    lastName: props.dialogState.lastName,
                    email: props.dialogState.email,
                    isActive: props.dialogState.isActive,
                    userType: props.dialogState.userType,
                    selectedCountries: props.dialogState.countries
                }}
                validationSchema={Yup.object({
                    firstName: Yup.string()
                        .max(50, intl.formatMessage({defaultMessage: "Must be 50 characters or less"})),
                    lastName: Yup.string()
                        .max(50, intl.formatMessage({defaultMessage: "Must be 50 characters or less"})),
                    email: Yup.string().email(intl.formatMessage({defaultMessage: "Invalid email address"}))
                        .required('Required'),
                    isActive: Yup.boolean(),
                    userType: Yup.string().oneOf(['expert', 'ro'],
                        intl.formatMessage({defaultMessage: "Invalid User Type"}))
                        .required('Required'),
                    selectedCountries: Yup.array().when('userType', {
                        is: 'expert',
                        then: Yup.array().required().min(1,
                            intl.formatMessage({defaultMessage: "Experts need at least one country"}))
                    })
                })}
                // validator={() => ({})}
                onSubmit={(values, {setSubmitting, setErrors}) => {
                    // only IDs needed for API
                    let valuesFormatted = {
                        ...values, selectedCountries: values.selectedCountries.map(country => {
                            return country.id;
                        })
                    };
                    if (props.dialogState.addOrEdit === 'add') {
                        addUser.mutate(valuesFormatted);
                    } else {
                        updateUser.mutate(valuesFormatted);
                    }
                    setSubmitting(false);
                }}
                enableReinitialize={true}
            >
                {formik => (
                    <Form>
                        <DialogContent>
                            <DialogContentText>
                                {
                                    props.dialogState.addOrEdit === 'add' &&
                                    <FormattedMessage defaultMessage="When creating a new user the new user will receive an
                            email containing instructions on how to set up his password."/>
                                }
                            </DialogContentText>
                            <MyTextInput
                                name="email"
                                label="E-Mail"
                                type="email"
                                fullWidth
                            />
                            {/*{addUser.isError ? (<span>{addUser.error.message}</span>) : null}*/}
                            {/*{updateUser.isError ? (<span>{updateUser.error.message}</span>) : null}*/}
                            <MyCheckbox
                                name="isActive"
                                label={intl.formatMessage({defaultMessage: "is active"})}
                            />
                            <MyTextInput
                                name="firstName"
                                label={intl.formatMessage({defaultMessage: "First name"})}
                                type="text"
                                fullWidth
                            />
                            <MyTextInput
                                name="lastName"
                                label={intl.formatMessage({defaultMessage: "Last name"})}
                                type="text"
                                fullWidth
                            />

                            <MyRadios
                                label={intl.formatMessage({defaultMessage: "User type"})}
                                aria-label="user type"
                                name="userType"
                            >
                                <FormControlLabel value="ro" control={<Radio/>} label="Readonly"/>
                                <FormControlLabel value="expert" control={<Radio/>} label="Expert"/>
                            </MyRadios>
                            <FormControl fullWidth>
                                <InputLabel shrink={true} htmlFor="selectedCountries">
                                    <FormattedMessage defaultMessage="Countries"/>
                                </InputLabel>
                                <Field
                                    disabled={formik.values.userType === 'ro'}
                                    component={Select}
                                    name="selectedCountries"
                                    inputProps={{
                                        name: "selectedCountries",
                                        code: "selectedCountries"
                                    }}
                                    renderValue={(selected) => (
                                        <div>
                                            {selected.map((value) => {
                                                return (
                                                    <Chip key={`chip-${value.code}`} label={value.name}/>
                                                );
                                            })}
                                        </div>
                                    )}
                                    multiple
                                >
                                    {countryCtx.countries.map((country) => {
                                        return <MenuItem key={`item-${country.code}`}
                                                         value={country}>{country.name}</MenuItem>;
                                    })}
                                </Field>
                                {formik.touched.selectedCountries && formik.errors.selectedCountries ? (
                                    <div>{formik.errors.selectedCountries}</div>
                                ) : null}
                            </FormControl>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={props.onClose} color="secondary" disabled={formik.isSubmitting}>
                                <FormattedMessage defaultMessage="Cancel"/>
                            </Button>
                            <Button type="submit" color="primary" disabled={formik.isSubmitting}>
                                <FormattedMessage defaultMessage="Save"/>
                            </Button>
                        </DialogActions>
                    </Form>
                )}

            </Formik>
        </Dialog>
    );
};

export default UserEditDialog;