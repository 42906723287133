import {Tooltip} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import {Star} from "@material-ui/icons";
import React from "react";
import {useIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import {
    dangerColor,
    successColor
} from "../../assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles((theme) => ({
    starIcon: {
        color: "#A3A300"
    },
    answeredIcon: {
        color: successColor[0]
    },
    notAnsweredIcon: {
        color: dangerColor[0]
    },
})) ;
export const AnsweredIcon = () => {
    const intl = useIntl();
    const classes = useStyles();
    return (<Tooltip title={intl.formatMessage({defaultMessage: "answered"})}>
            <CheckCircleOutlineIcon fontSize='medium' className={classes.answeredIcon}/></Tooltip>
    );
};
export const NotAnsweredIcon = () => {
    const intl = useIntl();
    const classes = useStyles();

    return (<Tooltip title={intl.formatMessage({defaultMessage: "not answered"})}>
            <CancelIcon fontSize='medium' className={classes.notAnsweredIcon}/></Tooltip>
    );
};
export const StarIcon = () => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Tooltip title={intl.formatMessage({defaultMessage: "new Question"})}>
            <Star fontSize='medium' className={classes.starIcon}/>
        </Tooltip>
    );
};