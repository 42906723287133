import React from 'react';
import {useMutation, useQueryClient} from "react-query";
import {getDownloadsURL} from "../../../utils/api";
import {FormattedMessage, useIntl} from "react-intl";
import {useSnackbar} from "notistack";
import axios from "axios";
import DialogTitle from "../../base/modals/DialogTitle";
import {Formik, Field, Form, ErrorMessage, useField, FieldArray, useFormikContext} from 'formik';
import * as Yup from "yup";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid} from "@material-ui/core";
import MyTextInput from "../../form/MyTextInput";
import {SimpleFileUpload} from "formik-material-ui";
import MyCheckbox from "../../form/MyCheckbox";
import GetAppIcon from "@material-ui/icons/GetApp";

const DownloadEditDialog = (props) => {
    const intl = useIntl();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const addDownload = ( download ) => {
        let formData = new FormData();
        formData.append('order', download.order);
        formData.append('name', download.name);
        formData.append('file', download.file);
        formData.append('active', download.active);
        formData.append('public', download.public);
        formData.append('for_readonly', download.forReadonly);
        formData.append('for_experts', download.forExperts);
        formData.append('category', props.dialogState.categoryId);
        axios.post(getDownloadsURL, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Download added!"}), {variant: "success"});
            queryClient.invalidateQueries('downloadableCategoriesAdmin');
            queryClient.invalidateQueries('downloadables');
            props.onClose();
        }).catch((err) => {
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Adding Download failed... "}) + `Error-Msg: [${err}]`,{variant: 'error'});
        });
    };

    const editDownload = async ( download ) => {
        let formData = new FormData();
        if (!(typeof (download.file) === "string")) {
            formData.append('file', download.file);
        }
        formData.append('order', download.order);
        formData.append('name', download.name);
        formData.append('active', download.active);
        formData.append('public', download.public);
        formData.append('for_readonly', download.forReadonly);
        formData.append('for_experts', download.forExperts);
        formData.append('category', props.dialogState.categoryId);
        axios.patch(getDownloadsURL + props.dialogState.id + "/", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Download edited!"}), {variant: "success"});
            queryClient.invalidateQueries('downloadableCategoriesAdmin');
            queryClient.invalidateQueries('downloadables');
            props.onClose();
        }).catch((err) => {
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Adding Download failed... "} + `Error-Msg: [${err}]`), {variant: 'error'});
        });
    };
    const handleDelete = () => {
        axios.delete(getDownloadsURL + props.dialogState.id + "/"
        ).then((response) => {
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Download deleted!"}), {variant: "success"});
            queryClient.invalidateQueries('downloadableCategoriesAdmin');
            queryClient.invalidateQueries('downloadables');
            props.onClose();
        }).catch((err) => {
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Deleting Download failed... "} + `Error-Msg: [${err}]`), {variant: 'error'});
        });
    };
    return (
        <div>
            <Dialog open={props.dialogState.open} onClose={props.onClose} aria-labelledby="question-add-edit-title"
                    maxWidth="lg"
            >
                <DialogTitle id="question-add-edit-title" onClose={props.onClose}>{
                    props.dialogState.addOrEdit === 'add' ? <FormattedMessage defaultMessage="Add Download"/> :
                        <FormattedMessage defaultMessage="Edit Download"/>
                }</DialogTitle>
                <Formik
                    initialValues={{
                        order: props.dialogState.order,
                        name: props.dialogState.name,
                        file: props.dialogState.file,
                        active: props.dialogState.active,
                        public: props.dialogState.public,
                        forExperts: props.dialogState.forExperts,
                        forReadonly: props.dialogState.forReadonly,
                    }}
                    validationSchema={Yup.object({
                        file: Yup.mixed().required("A file is required"),
                        name: Yup.string().required('Name is Required')
                    })}
                    onSubmit={async (values, formikHelpers, setSubmitting) => {

                        if (props.dialogState.addOrEdit === 'add') {
                            addDownload(values);
                        } else {
                            editDownload(values);
                        }
                    }}
                >
                    {formik => (
                        <Form>
                            <DialogContent>
                                <DialogContentText>
                                </DialogContentText>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <MyTextInput
                                            name="order"
                                            label="Order"
                                            type="number"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <MyTextInput
                                            name="name"
                                            label="Name"
                                            type="text"
                                            multiline
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {
                                            formik.values.file && formik.values.file.length > 0 &&
                                            <div>
                                            <div><span><FormattedMessage defaultMessage="Current file: "/></span>
                                                <Grid container>
                                                    <Grid item>
                                                        <a href={formik.values.file} download><GetAppIcon /> {formik.values.file.split("/").pop()}
                                                        </a>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                                <br />
                                            <div><span><FormattedMessage defaultMessage="Choose different file: "/></span></div>
                                            </div>

                                            }
                                        <Field
                                            component={SimpleFileUpload}
                                            name="file"
                                            label="File Upload"
                                            onChange={(event) => {
                                                console.log("yo");
                                                formik.setFieldValue("file", event.target.files[0]);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <MyCheckbox
                                    name="active"
                                    label={intl.formatMessage({defaultMessage: "is active"})}
                                />
                                <MyCheckbox
                                    name="public"
                                    label={intl.formatMessage({defaultMessage: "is public"})}
                                />
                                <MyCheckbox
                                    name="forExperts"
                                    label={intl.formatMessage({defaultMessage: "for experts"})}
                                />
                                <MyCheckbox
                                    name="forReadonly"
                                    label={intl.formatMessage({defaultMessage: "for readonly"})}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={props.onClose} color="secondary" disabled={formik.isSubmitting}>
                                    <FormattedMessage defaultMessage="Cancel"/>
                                </Button>
                                {
                                    props.dialogState.addOrEdit === 'edit' &&
                                    <Button onClick={handleDelete} color="secondary" style={{marginRight: "auto"}} disabled={formik.isSubmitting}>
                                        <FormattedMessage defaultMessage="Delete Download"/>
                                    </Button>
                                }
                                <Button type="submit" color="primary" >
                                    <FormattedMessage defaultMessage="Save" disabled={formik.isSubmitting}/>
                                </Button>
                            </DialogActions>
                        </Form>
                        )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default DownloadEditDialog;