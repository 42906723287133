import React, {useContext, useEffect} from "react";
import Login from "./components/login/Login";
import UserContext from "./context/user-context";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {createTheme, ThemeProvider} from "@material-ui/core";
import LoadingPage from "./components/base/LoadingPage";
import {Route, Router, Switch, useHistory, useLocation} from "react-router-dom";
import ResetPasswordPage from "./components/login/ResetPasswordPage";
import EditPasswordPage from "./components/login/EditPasswordPage";
import RestoredApp from "./RestoredApp";
import AppInfoContext from "./context/appinfo-context";
import {useIntl} from "react-intl";
import {useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {getAppinfoURL} from "./utils/api";
import ErrorPage from "./components/misc/ErrorPage";
import CookieConsent from "./components/misc/CookieConsent";

const App = () => {
    const ctx = useContext(UserContext);
    const location = useLocation();
    const appCtx = useContext(AppInfoContext);
    const intl = useIntl();
    const appInfoQuery = useQuery("appInfo", () => axios(getAppinfoURL), {
        enabled: true,
        onSuccess: (data) => {
            appCtx.onAppInfo(data.data);
        },
    });
    // scroll top after navigating around
    useEffect(() => {
        const top_el = document.querySelector('#main-wrapper');
        if (top_el) {
            top_el.scrollTo(0, 0);
        }
    }, [location]);
    useEffect(() => {
        if (appCtx.labelShort) document.title = appCtx.labelShort;
        else if (appCtx.label) document.title = appCtx.label;
        else document.title = intl.formatMessage({defaultMessage: "Round Table"});
    }, [appCtx.label, appCtx.labelShort]);
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    // type: prefersDarkMode ? 'dark' : 'light',
                    primary: {
                        // light: '#ed2c83',
                        light: "#d91560",
                        main: "#b01159",
                        dark: "#b21058",
                        darker: "#861d48",
                        contrastText: "#fff",
                    },
                    secondary: {
                        light: "#ff7961",
                        main: "#b01159",
                        dark: "#ba000d",
                        contrastText: "#000",
                    },
                    textColor: {
                        grey1: "#575656",
                    },
                },
                boxShadow: {},
            }),
        [prefersDarkMode]
    );

    if (appInfoQuery.isError) {
        return <ErrorPage message={"An unexpected error occured"}/>;
    }
    if (appCtx.disableTranslation && ctx.locale !== 'en') {
        ctx.onLocaleChange('en');
    }

    return (
        <ThemeProvider theme={theme}>
            {ctx.initialized && appInfoQuery.isSuccess ? (
                <>
                    {appCtx.enableAnalytics && <CookieConsent/>}
                    <Switch>
                        <Route path="/resetPassword">
                            <ResetPasswordPage/>
                        </Route>
                        <Route path="/editPassword">
                            <EditPasswordPage/>
                        </Route>
                        <Route path="/">
                            {appCtx.rtAllowAnonUsers || ctx.isLoggedIn ? <RestoredApp/> : <Login/>}
                        </Route>
                    </Switch>
                </>

            ) : (
                <LoadingPage/>
            )}
        </ThemeProvider>
    );
};

export default App;
