import React from 'react';
import {useMutation, useQueryClient} from "react-query";
import {getDownloadableCategoriesAdminURL} from "../../../utils/api";
import axios from "axios";
import {FormattedMessage, useIntl} from "react-intl";
import {useSnackbar} from "notistack";
import DialogTitle from "../../base/modals/DialogTitle";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid} from "@material-ui/core";
import {Formik, Field, Form, ErrorMessage, useField, FieldArray} from 'formik';
import * as Yup from "yup";
import MyTextInput from "../../form/MyTextInput";

const DownloadCategoryEditDialog = (props) => {
    const queryClient = useQueryClient();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const addCategory = useMutation(category => axios.post(getDownloadableCategoriesAdminURL, {
        name_de: category.nameDe,
        name_en: category.nameEn
    }), {
        onSuccess: (data, variables, context) => {
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Added Download Category!"}), {variant: 'success'});
            props.onClose();
        },
        onSettled: () => {
            queryClient.invalidateQueries('downloadableCategoriesAdmin');
            queryClient.invalidateQueries('downloadables');
        }
    });
    const updateCategory = useMutation(category => axios.put(getDownloadableCategoriesAdminURL + props.dialogState.id + "/", {
        name_de: category.nameDe,
        name_en: category.nameEn
    }));
    return (
        <div>
            <Dialog open={props.dialogState.open} onClose={props.onClose}>
                <DialogTitle onClose={props.onClose}>
                    {
                        props.dialogState.addOrEdit === 'add' ? <FormattedMessage defaultMessage="Add Download Category"/> :
                            <FormattedMessage defaultMessage="Edit Download Category"/>
                    }
                </DialogTitle>
                <Formik
                    initialValues={{
                        nameDe: props.dialogState.nameDe,
                        nameEn: props.dialogState.nameEn,
                    }}
                    validationSchema={Yup.object({}).shape({
                        'nameDe': Yup.string()
                            .when('nameEn', {
                                is: (nameEn) => !nameEn || nameEn.length === 0,
                                then: Yup.string()
                                    .required('At least one of the fields is required')
                            }),
                        'nameEn': Yup.string()
                            .when('nameDe', {
                                is: (nameDe) => !nameDe || nameDe.length === 0,
                                then: Yup.string()
                                    .required('At least one of the fields is required')
                            }),
                    }, ['nameDe', 'nameEn'])}
                    onSubmit={(values, setSubmitting) => {
                        if (props.dialogState.addOrEdit === 'add') {
                            addCategory.mutate(values);
                        } else {
                            updateCategory.mutate(values);
                        }
                    }}
                >
                    {formik => (
                        <Form>
                            <DialogContent>
                                <DialogContentText>

                                </DialogContentText>
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <MyTextInput
                                            name="nameDe"
                                            label="Name DE"
                                            type="text"
                                            multiline
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MyTextInput
                                            name="nameEn"
                                            label="Name EN"
                                            type="text"
                                            multiline
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={props.onClose} color="secondary" disabled={formik.isSubmitting}>
                                    <FormattedMessage defaultMessage="Cancel"/>
                                </Button>
                                <Button type="submit" color="primary" disabled={formik.isSubmitting}>
                                    <FormattedMessage defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default DownloadCategoryEditDialog;