import React from "react";


const ErrorPage = (props) => {
    return (
        <div>
            <p><b>Error:</b> {props.message}</p>
            <p>Please check you internet connection and contact the admin.</p>
        </div>
    );
};

export default ErrorPage;