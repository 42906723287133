import React, {useContext} from "react";

import {Dashboard, MenuBook} from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import AssessmentIcon from '@material-ui/icons/Assessment';
import {FormattedMessage} from "react-intl";
import InfoIcon from "@material-ui/icons/Info";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AppInfoContext from "../../context/appinfo-context";

const getRoutes = (label) => {
  const appCtx = useContext(AppInfoContext);
  const aboutName = <FormattedMessage defaultMessage="About {labelShort}" values={{labelShort: label}} />;
  let routes = [
        {
            path: "/dashboard/",
            name: <FormattedMessage defaultMessage="Dashboard" />,
            icon: Dashboard,
            layout: ""
        },
        {
            path: "/questionnaire/",
            name: <FormattedMessage defaultMessage="Questionnaire" />,
            icon: MenuBook,
            layout: ""
        },
        {
            collapse: true,
            name: "Reports",
            icon: FileCopyIcon,
            state: "reportsCollapse",
            views: [
                {
                    path: "#",
                    name: <FormattedMessage defaultMessage="Export by Country" />,
                    layout: "",
                    icon: DescriptionIcon,
                    modalId: "exportByCountry"
                },
                {
                    path: "#",
                    name: <FormattedMessage defaultMessage="Export by Question" />,
                    layout: "",
                    icon: DescriptionIcon,
                    modalId: "exportByQuestion"
                },
                {
                    path: "#",
                    name: <FormattedMessage defaultMessage="Export Maps" />,
                    layout: "",
                    icon: DescriptionIcon,
                    modalId: "exportMaps"
                },
            ]
        },
        {
            path: appCtx.infoUrl1,
            name: aboutName,
            icon: InfoIcon,
            layout: "",
            external: true
        },
        {
            path: "/disclaimer/",
            name: <FormattedMessage defaultMessage="Disclaimer" />,
            icon: InfoIcon,
            layout: "",
            external: true
        },
    ];
    if (appCtx.infoUrl1.length === 0) {
        routes = routes.filter(r => {
            return r.name !== aboutName;
        });
    }
    if (appCtx.labelShort === 'Round Table Covered Bond Legislation') {
        routes.push(
            {
                path: "/glossary/",
                name: <FormattedMessage defaultMessage="List of abbreviations" />,
                icon: InfoIcon,
                layout: "",
                external: true
            }
        );
    }
    return routes;
};
export default getRoutes;