
import {useLocation } from 'react-router-dom';
import {useQuery} from "react-query";
import axios from "axios";
import List from "@material-ui/core/List";
import {Checkbox, FormControlLabel, ListItem, ListItemText, Tooltip} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Highlighter from "react-highlight-words";
import Box from "@material-ui/core/Box";
import {getCommentsURL, getQuestionsURL} from "../../utils/api";
import {intToRoman} from "../../utils/general-helpers";
import Comment from "../question/Comment";
import CountryContext from "../../context/country-context";
import {FormattedMessage, useIntl} from "react-intl";
import UserContext from "../../context/user-context";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import QuestionnaireContext from "../../context/questionnaire-context";


function useQueryParam() {
    return new URLSearchParams(useLocation().search);
}
function searchContent(searchTerm, questionnaireCtx) {
    const foundContent = {
        chapters: [],
        questions: [],
    };

    questionnaireCtx.chapters.forEach(chapter => {
        if (chapter.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            // Found a matching chapter
            foundContent.chapters.push(chapter);
        }
        chapter.questions.forEach(question => {
            let questionWithChapter = {...question, chapter: { order: chapter.order }};
            // Check if the question text includes the search term
            if (question.text.toLowerCase().includes(searchTerm.toLowerCase())) {
                foundContent.questions.push(questionWithChapter);
            } else {
                // Check if any answer includes the search term
                const foundInAnswers = question.answers.some(answer =>
                    answer.text.toLowerCase().includes(searchTerm.toLowerCase())
                );
                if (foundInAnswers) {
                    foundContent.questions.push(questionWithChapter);
                }
            }
        });
    });

    return foundContent;
}

const SearchPage = () =>{
    const countryCtx = useContext(CountryContext);
    const queryParams = useQueryParam();
    const searchTerm = queryParams.get("search");
    const ctx = useContext(UserContext);
    const isAnon = ctx.getIsAnon();
    const intl = useIntl();
    const [foundQuestions, setFoundQuestions] = useState([]);
    const [foundChapters, setFoundChapters] = useState([]);
    const [noResults, setNoResults] = useState(false);
    const [showChapters, setShowChapters] = useState(true);
    const [showQuestions, setShowQuestions] = useState(true);
    const [showComments, setShowComments] = useState(true);
    const questionnaireCtx = useContext(QuestionnaireContext);
    const {
        data: comments,
        isLoading: isLoadingComments,
        isError: isErrorComments,
        error: errorComments
    } = useQuery(['search-comments', searchTerm],
        () => axios(getCommentsURL, {params: {
                search: searchTerm
            }}), {
            onSuccess: data => {
                let cs = data.data;
                cs.forEach(comment => comment.country = countryCtx.getCountryByCode(comment.country));
                return cs;
            }
        });
    useEffect(() => {
        const contentFromCtx = searchContent(searchTerm, questionnaireCtx);
            if (contentFromCtx.questions.length > 0) {
                setFoundQuestions(contentFromCtx.questions);
            }
            if (contentFromCtx.chapters.length > 0) {
                setFoundChapters(contentFromCtx.chapters);
            }
        }, [searchTerm, questionnaireCtx]);
    let commentResults;
    if ( comments ){
        commentResults =
            <div>
                <Box>
                    {comments.data.map((comment, index) => (
                        <Comment comment={comment} key={index}  linkToQuestion={true}/>
                    ))}
                </Box>
            </div>;
    }
    if (isErrorComments) {
        commentResults = <p>{errorComments.message}</p>;
    }
    if (isLoadingComments) {
        commentResults = <p>Loading...</p>;
    }
    useEffect(() => {
        if (foundChapters.length === 0 && foundQuestions.length === 0 && comments && comments.data.length === 0) {
            setNoResults(true);
        } else {
            setNoResults(false);
        }
    }, [foundChapters, foundQuestions, comments]);
    return (
        <div>
            {noResults ?
                <h2><FormattedMessage defaultMessage="No results for " /> <span style={{color: primaryColor[2]}}>{searchTerm}</span></h2>
                :
                <>
                    <FormControlLabel
                        control={<Checkbox checked={showChapters} onChange={() => setShowChapters(prev => !prev)} />}
                        label={intl.formatMessage({defaultMessage: "show Chapters"})}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={showQuestions} onChange={() => setShowQuestions(prev => !prev)} />}
                        label={intl.formatMessage({defaultMessage: "show Questions"})}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={showComments} onChange={() => setShowComments(prev => !prev)} />}
                        label={intl.formatMessage({defaultMessage: "show Comments"})}
                    />
                    {showChapters &&
                        <>
                            <h2><FormattedMessage defaultMessage="Chapters" />:</h2>
                            <List>
                                {foundChapters.map((chapter, index) => (
                                    <ListItem key={index} component="a"
                                        href={"/questionnaire?chapter=" + chapter.id}>
                                        <ListItemText
                                            primary={
                                                <Highlighter
                                                    highlightClassName="highlight-search-term"
                                                    searchWords={[searchTerm]}
                                                    autoEscape={true}
                                                    textToHighlight={intToRoman(chapter.order) + ". " + chapter.name}
                                                />}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    }
                    {showQuestions &&
                        <>
                            <h2><FormattedMessage defaultMessage="Questions" />:</h2>
                            <List>
                            {foundQuestions.map((question, index) => {
                                    const listItem = (
                                        <ListItem key={index} component="a"
                                              href={isAnon && !question.public ? false : "/question/" + question.id + "/"}
                                              disabled={isAnon && !question.public}
                                    >
                                    <ListItemText
                                        component="span"
                                        primary={
                                            <Highlighter
                                                highlightClassName="highlight-search-term"
                                                searchWords={[searchTerm]}
                                                autoEscape={true}
                                                textToHighlight={intToRoman(question.chapter.order) + ". " + question.order + ".) " + question.text}
                                            />}
                                        secondary={
                                            <React.Fragment>
                                                <Typography component="span">
                                                    {"> " + question.chapter.name}
                                                </Typography>
                                                    {!(isAnon && !question.public) && question.answers ? question.answers.map((answer, index) => (
                                                        <span key={index} style={{paddingLeft: "10px"}}>
                                                            <br />
                                                            <span>{index+1 + ". "}
                                                                <Highlighter
                                                                    highlightClassName="highlight-search-term"
                                                                    searchWords={[searchTerm]}
                                                                    autoEscape={true}
                                                                    textToHighlight={answer.text}
                                                                />
                                                        </span>
                                                        </span>
                                                    )) : ""}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem> );
                                    return (isAnon && !question.public) ?
                                        <Tooltip title={intl.formatMessage({defaultMessage: "This question is currently under review"})}>
                                            {listItem}
                                        </Tooltip> :
                                        listItem;
                            })}
                        </List>
                        </>
                    }
                    {showComments &&
                        <>
                            <h2><FormattedMessage defaultMessage="Comments" />:</h2>
                            {commentResults}
                        </>
                    }
                    </>
            }
        </div>
    );
};

export default SearchPage;