import React, {useContext} from 'react';
import {Typography} from "@material-ui/core";
import AppinfoContext from "../../context/appinfo-context";
import {FormattedMessage} from "react-intl";
import Container from "@material-ui/core/Container";

const InfoPage = () => {
    const appCtx = useContext(AppinfoContext);
    return (
        <div>
            <Container fluid>
            <Typography variant="h3">{appCtx.label}</Typography>
                <br />
                <br />
            <Typography>{appCtx.description}
                {
                    appCtx.infoUrl1 && appCtx.infoUrl1.length > 0 &&
                    <a href={appCtx.infoUrl1}> <FormattedMessage defaultMessage="More information" />&gt;</a>
                }
            </Typography>
            {/*<Typography variant="h3"><FormattedMessage defaultMessage="User Guide" /></Typography>*/}
            </Container>
        </div>
    );
};

export default InfoPage;