import React, {useState} from 'react';
import {Box} from "@material-ui/core";
import {useContext} from "react";
import UserContext from "../../context/user-context";
import {useQuery} from "react-query";
import axios from "axios";
import {getCommentsURL} from "../../utils/api";
import CountryContext from "../../context/country-context";
import Comment from "./Comment";
import {FormattedMessage} from "react-intl";


const AllComments = (props) => {
    const [comments, setComments] = useState([]);
    const ctx = useContext(UserContext);
    const countryCtx = useContext(CountryContext);
    const {isLoading, isError, data, error} = useQuery(['allComments', props.question.id],
        () => axios(getCommentsURL, {
            params: {
                question: props.question.id,
            },
        }), {
            onSuccess: data => {
                let cs = data.data;
                cs.forEach(comment => comment.country = countryCtx.getCountryByCode(comment.country));
                setComments(cs);
            }
        }
    );
    comments.forEach((comment) => {
        if (!comment.country){
            console.log(comment);
        }
    });
    return (
        <div>
            <h3><FormattedMessage defaultMessage="All Comments"/></h3>
            {isError && <p>{error}</p>}
            {isLoading ? (
                <p>Loading...</p>
            ) : (
            <Box>
                {comments && comments.length > 0 &&
                    comments.map((comment, index) => (
                        ( comment.country && (ctx.getIsAnonOrRo() || comment.country.code !== ctx.currentCountry.code))
                        &&
                   <Comment comment={comment} key={index}/>
                ))}
            </Box>
                )}
        </div>
    );
};
export default AllComments;