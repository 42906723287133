import React, {useContext, useEffect, useState} from 'react';
import {useQuery} from "react-query";
import {getUsersURL} from "../../../utils/api";
import axios from "axios";
import {FormattedMessage, useIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import {dangerColor, successColor} from "../../../assets/jss/material-dashboard-pro-react";
import MaterialTable from '@material-table/core';
import AddIcon from '@material-ui/icons/Add';
// import {ExportCsv, ExportPdf} from '@material-table/exporters';
import {Fab, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import UserEditDialog from "./UserEditDialog";
import CountryContext from "../../../context/country-context";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    answeredIcon: {
        color: successColor[0]
    },
    notAnsweredIcon: {
        color: dangerColor[0]
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    tableOptions: {
        position: 'relative',
        margin: theme.spacing(1)
    }
}));
const UserTable = () => {
    const initialDialogState = {
        open: false,
        addOrEdit: 'add',
        id: '',
        email: '',
        isActive: true,
        firstName: '',
        lastName: '',
        userType: "expert",
        countries: []
    };
    const [dialogState, setDialogState] = useState(initialDialogState);
    const [usersData, setUsersData] = useState([]);
    const [userType, setUserType] = useState('all');
    const [activeState, setActiveState] = useState('all');
    const classes = useStyles();
    const countryCtx = useContext(CountryContext);

    useEffect(() => {
        if (usersQuery.data && usersQuery.data.data) {
            let filteredUserData;
            if (userType === 'expert') {
                filteredUserData = usersQuery.data.data.filter(obj => {
                    return obj["is_expert"];
                });
            } else if (userType === 'ro') {
                filteredUserData = usersQuery.data.data.filter(obj => {
                    return obj["is_readonly"];
                });
            } else {
                filteredUserData = usersQuery.data.data;
            }
            if (activeState === 'active') {
                filteredUserData = filteredUserData.filter(obj => {
                    return obj["is_active"];
                });
            } else if (activeState === 'notActive') {
                filteredUserData = filteredUserData.filter(obj => {
                    return !obj["is_active"];
                });
            }
            setUsersData(filteredUserData);
        }
    }, [userType, activeState]);

    const intl = useIntl();
    const usersQuery = useQuery('users', () => axios(getUsersURL), {
        onSuccess: (data) => {
            if (data && data.data) {
                data.data.forEach((obj, index) => {
                    data.data[index]["name"] = obj["first_name"] + " " + obj["last_name"];
                });
            }
            setUsersData(data.data);
        }
    });

    const howFarInThePast = (dateTimeString) => {
        const compareDate = new Date(dateTimeString);
        const now = new Date();
        const diff = (now.getTime() - compareDate.getTime()) / (1000 * 60);
        if (compareDate.getFullYear() < 2000) {
            return "invalid date";
        } else if (diff / (60 * 24 * 356) >= 1) {
            return ((diff / (60 * 24 * 356)) | 0).toString() + " years ago";
        } else if (diff / (60 * 24 * 30) >= 1) {
            return ((diff / (60 * 24 * 30)) | 0).toString() + " months ago";
        } else if (diff / (60 * 24) >= 1) {
            return ((diff / (60 * 24)) | 0).toString() + " days ago";
        } else if (diff / (60) >= 1) {
            return ((diff / (60)) | 0).toString() + " hours ago";
        } else if (diff >= 1) {
            return (now.getMinutes() - compareDate.getMinutes()).toString() + " minutes ago";
        } else {
            return "few seconds ago";
        }
    };

    const columns = [
        {title: "Name", field: "name"},
        {title: "E-Mail", field: "email"},
        {
            title: intl.formatMessage({defaultMessage: "Last Login"}), field: "last_login", type: 'datetime',
            render: rowData => howFarInThePast(rowData["last_login"])
        },
        {
            title: intl.formatMessage({defaultMessage: "Joined"}), field: "date_joined", type: 'datetime',
            render: rowData => howFarInThePast(rowData["date_joined"])
        },
        {
            title: intl.formatMessage({defaultMessage: "Is active?"}), field: "is_active",
            render: rowData => rowData["is_active"] ? <CheckCircleOutlineIcon className={classes.answeredIcon}/> :
                <CancelIcon className={classes.notAnsweredIcon}/>
        },
        {
            title: intl.formatMessage({defaultMessage: "Is expert?"}), field: "is_expert",
            render: rowData => rowData["is_expert"] ? <CheckCircleOutlineIcon className={classes.answeredIcon}/> :
                <CancelIcon className={classes.notAnsweredIcon}/>
        }
    ];
    const handleClose = () => {
        setDialogState(initialDialogState);
    };
    const handleRowClick = (event, rowData) => {

        const rowCountries = rowData["is_expert"] ? countryCtx.countries.filter(country => {
            return rowData["countries"].includes(country.id);}) : [];
        setDialogState({
            open: true,
            addOrEdit: 'edit',
            id: rowData["id"],
            email: rowData["email"],
            isActive: rowData["is_active"],
            firstName: rowData["first_name"],
            lastName: rowData["last_name"],
            userType: rowData["is_expert"] ? "expert" : "ro",
            countries: rowCountries
        });
    };
    const handleAddUserClick = () => {
        setDialogState({...initialDialogState, open: true});
    };
    const handleUserTypeChange = (event) => {
        setUserType(event.target.value);
    };
    const handleActiveStateChange = (event) => {
        setActiveState(event.target.value);
    };
    return (
        usersData && usersData.length > 0 &&
        (
            <React.Fragment>
                <div className={classes.tableOptions}>
                <FormControl component="fieldset">
                    <FormLabel component="legend"><FormattedMessage defaultMessage="User type"/></FormLabel>
                    <RadioGroup aria-label="user type" name="user-type" value={userType}
                                onChange={handleUserTypeChange}>
                        <FormControlLabel value="all" control={<Radio/>}
                                          label={intl.formatMessage({defaultMessage: "all"})}/>
                        <FormControlLabel value="ro" control={<Radio/>} label="Readonly"/>
                        <FormControlLabel value="expert" control={<Radio/>} label="Expert"/>
                    </RadioGroup>
                </FormControl>
                <FormControl component="fieldset">
                    <FormLabel component="legend"><FormattedMessage defaultMessage="Active"/></FormLabel>
                    <RadioGroup aria-label="active" name="active" value={activeState}
                                onChange={handleActiveStateChange}>
                        <FormControlLabel value="all" control={<Radio/>}
                                          label={intl.formatMessage({defaultMessage: "all"})}/>
                        <FormControlLabel value="active" control={<Radio/>}
                                          label={intl.formatMessage({defaultMessage: "active"})}/>
                        <FormControlLabel value="notActive" control={<Radio/>}
                                          label={intl.formatMessage({defaultMessage: "not active"})}/>
                    </RadioGroup>
                </FormControl>
                <Fab color="primary" aria-label="add user" onClick={handleAddUserClick} variant="extended"
                     className={classes.fab}>
                    <FormattedMessage defaultMessage="Add User"/>
                    <AddIcon/>
                </Fab>
                </div>
                <MaterialTable
                    onRowClick={handleRowClick}
                    columns={columns}
                    data={usersData}
                    title="Users"
                    options={{
                        pageSize: 25,
                        pageSizeOptions: [25, 50, 100],
                        // exportMenu: [{
                        //     label: 'Export PDF',
                        //     //// You can do whatever you wish in this function. We provide the
                        //     //// raw table columns and table data for you to modify, if needed.
                        //     // exportFunc: (cols, datas) => console.log({ cols, datas })
                        //     exportFunc: (cols, datas) => ExportPdf(cols, datas, 'RT_Exported_Users')
                        // }, {
                        //     label: 'Export CSV',
                        //     exportFunc: (cols, datas) => ExportCsv(cols, datas, 'RT_Exported_Users')
                        // }],
                    }}
                >
                </MaterialTable>
                <UserEditDialog dialogState={dialogState} onClose={handleClose}/>
            </React.Fragment>
        )

    );

};

export default UserTable;