import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {dangerColor, successColor} from "../../assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Cookies from 'js-cookie';
import AuthBase from "./AuthBase";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        // backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    errorMessage: {
        textAlign: "center",
        color: dangerColor[0]
    },
    successMessage: {
        textAlign: "center",
        color: successColor[0]
    }
}));

export default function ResetPasswordPage(props) {
    const classes = useStyles();
    const [email, setEmail] = useState();
    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = async e => {
        setErrorMsg("");
        setIsSubmitting(true);
        e.preventDefault();
        fetch('/api/password_reset/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify({
                email
            }),
        })
            .then(response => {
                if (response.ok) {
                    setSuccessMsg("Password Reset Link has been sent to your E-Mail Address!");
                } else {
                    throw new Error(response.status);
                }
            })
            .catch((error) => {
                setIsSubmitting(false);
                switch (error.message) {
                    case "400":
                    case "401":
                        setErrorMsg("Wrong E-Mail Address");
                        break;
                    default:
                        setErrorMsg(error.message);
                        break;
                }
            });
    };
    return (
        <AuthBase>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Request Password Reset
            </Typography>
            {errorMsg !== "" &&
            <Typography className={classes.errorMessage} component="p" variant="h5">{errorMsg}</Typography>}
            {successMsg !== "" &&
            <Typography className={classes.successMessage} component="p" variant="h5">{successMsg}</Typography>}
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="username"
                    autoComplete="email"
                    autoFocus
                    onChange={e => setEmail(e.target.value)}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isSubmitting}
                >
                    Send reset Link
                </Button>
            </form>
        </AuthBase>
    );
}