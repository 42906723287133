import React, {useContext} from "react";
import {FormControl, MenuItem, Select} from "@material-ui/core";
import UserContext from "../../context/user-context";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles((theme) => ({
    select: {
        color: 'white',
        minWidth: '70px',
    },
    selectItem: {
        textOverflow: 'unset'
    },
    root: {
        textOverflow: 'unset'
    }
}));
const LanguageSelect = (props) => {
    const classes = useStyles();
    const ctx = useContext(UserContext);
    const handleLanguageChange = (event) => {
        const code = event.target.value;
        // axios.defaults.headers['Accept-Language'] = code;
        // queryClient.invalidateQueries();
        ctx.onLocaleChange(code);
    };
    return (
        <FormControl className={classes.formControl}>
            <Select
                value={ctx.locale}
                onChange={handleLanguageChange}
                displayEmpty
                className={classes.select}
                classes={{root: classes.root}}
                inputProps={{ 'aria-label': 'Without label', style: {textOverflow: 'unset'} }}
            >
                    <MenuItem value="en">
                        {props.mobile ? "EN" :
                            <FormattedMessage defaultMessage="English"/>
                        }
                    </MenuItem>
                    <MenuItem value="de">
                        {props.mobile ? <FormattedMessage defaultMessage="GER"/> :
                            <FormattedMessage defaultMessage="German"/>
                        }
                    </MenuItem>
            </Select>
        </FormControl>
    );
};

export default LanguageSelect;