import React, {useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import AllComments from "../question/AllComments";
import QuestionAttachments from "../question/QuestionAttachments";
import Card from "../../theme-components/components/Card/Card";
import CardBody from "../../theme-components/components/Card/CardBody";
import {makeStyles} from "@material-ui/styles";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import {useContext} from "react";
import UserContext from "../../context/user-context";
import QuestionnaireContext from "../../context/questionnaire-context";
import LoadingOverlay from 'react-loading-overlay';
import {intToRoman} from "../../utils/general-helpers";
import Grid from "@material-ui/core/Grid";
import Button from "../CustomButtons/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {rtColors} from "../../assets/jss/rt-theme";
import Explanation from "../question/Explanation";
import QuestionNotFound from "../misc/QuestionNotFound";

const useStyles = makeStyles((theme) => ({
    iframeMap: {
        height: "600px",
        border: 'none',
        width: '100%'
    },
    forwardBtn: {
        float: 'right',
        backgroundColor: rtColors.primary
    },
    backBtn: {
        float: 'left',
        backgroundColor: rtColors.primary
    },
    accordion: {
        width: '100%'
    },
}));

const ROQuestionPage = () => {
    const ctx = useContext(UserContext);
    const questionnaireCtx = useContext(QuestionnaireContext);
    const classes = useStyles();
    const params = useParams();
    const isAnon = ctx.getIsAnon();
    const nextQuestion = questionnaireCtx.getNextQuestionById(parseInt(params.questionId), isAnon);
    const previousQuestion = questionnaireCtx.getPreviousQuestionById(parseInt(params.questionId), isAnon);
    const authString = ctx.isLoggedIn ? "&auth=" + ctx.accessToken : "";
    const forceRoString = ctx.roSwitchEnabled ? "&force_ro=1" : "";
    const map_iframe_src = "/map-service/" + params.questionId + "/?lang=" + ctx.locale + authString + forceRoString;
    const [chapter, question] = questionnaireCtx.getChapterAndQuestionById(parseInt(params.questionId));
    if (isAnon && !question.public) {
        return (
            <QuestionNotFound/>
        );
    }
    return (
        <LoadingOverlay
            active={questionnaireCtx.chaptersLoading}
            spinner
        >
            <div><Card>
                <CardBody>
                    <h6 align="center">{intToRoman(chapter.order)}. {chapter.name}</h6>

                    <Grid container spacing={3}>
                        <Grid item sm={2} xs={6}>
                            <Link to={previousQuestion ? "/question/" + previousQuestion.id + "/" : "#"}>
                                <Button className={classes.backBtn} justIcon round
                                        disabled={!previousQuestion}>
                                    <ArrowBackIosIcon/>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <h3 align="center">{question.order}. {question.text}</h3>
                        </Grid>
                        <Grid item sm={2} xs={6}>
                            <Link to={nextQuestion ? "/question/" + nextQuestion.id + "/" : "#"}>
                                <Button className={classes.forwardBtn} justIcon round
                                        disabled={!nextQuestion}>
                                    <ArrowForwardIosIcon/>
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </CardBody>
            </Card>
                <Card>
                    <CardBody>
                        <GridContainer justifyContent="center">
                            <GridItem xs={12} sm={12} lg={12}>
                                <iframe className={classes.iframeMap}
                                        id="map-iframe"
                                        title="result chart"
                                        src={map_iframe_src}></iframe>
                            </GridItem>
                        </GridContainer>

                    </CardBody>
                </Card>
                {question.explanation && question.explanation.length > 0 &&
                    <Explanation explanation={question.explanation}/>
                }
                <QuestionAttachments question={question}/>
                <Card>
                    <CardBody>
                        <AllComments question={question}/>
                    </CardBody>
                </Card>
            </div>
            ;
        </LoadingOverlay>
    );
};

export default ROQuestionPage;