import React, {useState} from "react";
import {Button, Fade, ListItemIcon, Menu, MenuItem, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";
import GroupIcon from '@material-ui/icons/Group';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PublicIcon from '@material-ui/icons/Public';
import {Link} from 'react-router-dom';
import Divider from "@material-ui/core/Divider";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import {CloudDownload} from "@material-ui/icons";
import AssessmentIcon from "@material-ui/icons/Assessment";

const useStyles = makeStyles((theme) => ({
    select: {
        color: 'white'
    },
    selectMenu: {}
}));
const AdminSelect = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <React.Fragment>
            <Button className={classes.select} onClick={handleClick}>Admin</Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <Link to="/users/" style={{textDecoration: 'none', outline: 'none'}} onClick={handleClose}>
                    <MenuItem>
                        <ListItemIcon>
                            <GroupIcon/>
                        </ListItemIcon>
                        <Typography><FormattedMessage defaultMessage="Users"/></Typography>
                    </MenuItem>
                </Link>
                <Link to="/questions/" style={{textDecoration: 'none', outline: 'none'}} onClick={handleClose}>
                    <MenuItem>
                        <ListItemIcon>
                            <LiveHelpIcon/>
                        </ListItemIcon>
                        <Typography><FormattedMessage defaultMessage="Questions"/></Typography>
                    </MenuItem>
                </Link>
                <Link to="/countries/" style={{textDecoration: 'none', outline: 'none'}} onClick={handleClose}>
                    <MenuItem>
                        <ListItemIcon>
                            <PublicIcon/>
                        </ListItemIcon>
                        <Typography><FormattedMessage defaultMessage="Countries"/></Typography>
                    </MenuItem>
                </Link>
                <Link to="/weights/" style={{textDecoration: 'none', outline: 'none'}} onClick={handleClose}>
                    <MenuItem>
                        <ListItemIcon>
                            <FormatListNumberedIcon/>
                        </ListItemIcon>
                        <Typography><FormattedMessage defaultMessage="Weights & Scores"/></Typography>
                    </MenuItem>
                </Link>
                <Link to="/downloads/" style={{textDecoration: 'none', outline: 'none'}} onClick={handleClose}>
                    <MenuItem>
                        <ListItemIcon>
                            <CloudDownload/>
                        </ListItemIcon>
                        <Typography><FormattedMessage defaultMessage="Downloads"/></Typography>
                    </MenuItem>
                </Link>
                <Divider />
                <Link to="/charts/" style={{textDecoration: 'none', outline: 'none'}} onClick={handleClose}>
                    <MenuItem>
                        <ListItemIcon>
                            <AssessmentIcon/>
                        </ListItemIcon>
                        <Typography><FormattedMessage defaultMessage="Charts"/></Typography>
                    </MenuItem>
                </Link>
            </Menu>
        </React.Fragment>

    );
};

export default AdminSelect;