import React, {useContext, useEffect, useState} from "react";
import {
    Checkbox, FormControl, FormControlLabel, MenuItem, Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";
import {useQuery} from "react-query";
import axios from "axios";
import Box from "@material-ui/core/Box";
import Flag from "react-flagkit";
import FileDownload from 'js-file-download';
import CountryContext from "../../../context/country-context";
// @material-ui/icons
// core components
import Button from "~/components/CustomButtons/Button.js";

import styles from "~/assets/jss/material-dashboard-pro-react/modalStyle.js";
import MyDialog from "./MyDialog";
import UserContext from "../../../context/user-context";
import AppInfoContext from "../../../context/appinfo-context";

const useStyles = makeStyles(styles);


const DisclaimerModalRTCBL = (props) => {
    const [open, setOpen] = React.useState(true);
    const ctx = useContext(UserContext);
    const appCtx = useContext(AppInfoContext);
    const handleDisclaimerAccepted = () => {
        ctx.onSetDisclaimerAccepted();
        setOpen(false);
    };
    return (<MyDialog
            id="disclaimer-popup"
            open={open}
            title={<FormattedMessage
                defaultMessage="Disclaimer"
            />}
            description={<FormattedMessage
                defaultMessage="Please accept the disclaimer."
            />}
            buttons={<>
                <Button variant="contained" color="primary" onClick={handleDisclaimerAccepted}>
                    <FormattedMessage defaultMessage="Accept"/>
                </Button>
            </>}
        >

            <Box>
                <p>
                    <>
                        <Typography>
                            <FormattedMessage
                                defaultMessage="The vdpExpertise GmbH (vdpExpertise), the Association of German
                                Pfandbrief Banks (vdp), the members of the Round Table Covered Bond Legislation
                                (RTCBL) and other contributors do not assume any liability for the completeness or
                                accuracy of any information presented here or for the information being up-to-date.
                                The contents of the database are only for general information and scholarly purposes
                                and are in no way intended to form the basis for specific decisions."/>
                        </Typography>
                        <br/>
                        <Typography>
                            <FormattedMessage
                                defaultMessage="The legal issues covered do not capture all information that may be
                                relevant to a particular situation. Comparative law deals with complex issues, so not
                                all details can be reproduced. Localized regulations are covered only in exceptional
                                cases. Also, errors and omissions cannot be ruled out. Many informations have been
                                translated and therefore can lead to differences in interpretation."/>
                        </Typography>
                        <br/>
                        <Typography>
                            <FormattedMessage defaultMessage="Specific advice should be sought from a suitably
                            qualified professional before taking a decision on any particular situation and the
                            information made herein cannot form the basis of any decision."/>
                        </Typography>

                        <br/>
                        <Typography>
                            <FormattedMessage defaultMessage="No responsibility is assumed for any action (or lack of
                            such action) taken as a result of reliance on or use of the information contained herein,
                            and no liability is assumed for any damage or harm resulting from reliance on or use of
                            such information."/>
                        </Typography>
                        <br/>
                        <Typography>
                            <FormattedMessage defaultMessage="Downloading and otherwise obtaining content by way of
                            use of the database is at your own risk and no reliability is accepted for any damage to
                            computer systems or loss of data resulting from downloading or otherwise obtaining such
                            data."/>
                        </Typography>
                        <br/>
                        <Typography><FormattedMessage defaultMessage="Links to other third-party web resources are
                        provided for informational purposes only; the content is not provided or verified by the
                        vdpExpertise, the vdp, the members of the Round Table Covered Bond Legislation, or any other
                        contributors, and no liability or responsibility is assumed for such content."/>
                        </Typography>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <Typography variant="h4">Download/Copyright</Typography>
                        <br/>
                        <Typography><FormattedMessage defaultMessage="The copyright is automatically inserted in each
                        download. "/> </Typography>
                        <br/>
                        <Typography><FormattedMessage defaultMessage="If content from the database is used, the
                        following source must always be cited with the date of inspection:"/></Typography>
                        <br/>
                        <Typography style={{textAlign: "center"}}><i>„{appCtx.label}, vdpExpertise, <a
                            href={appCtx.infoUrl1}>{appCtx.infoUrl1}</a>"</i></Typography>
                    </>
                </p>
            </Box>
        </MyDialog>);
};
export default DisclaimerModalRTCBL;