import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {dangerColor, successColor} from "../../assets/jss/material-dashboard-pro-react";
import Cookies from 'js-cookie';
import {useLocation} from "react-router-dom";
import AuthBase from "./AuthBase";
import {Link} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    errorMessage: {
        textAlign: "center",
        color: dangerColor[0]
    },
    successMessage: {
        textAlign: "center",
        color: successColor[0]
    }
}));

function useQueryParam() {
    return new URLSearchParams(useLocation().search);
}

export default function EditPasswordPage(props) {
    const classes = useStyles();
    const queryParams = useQueryParam();
    const token = queryParams.get("token");
    const [password, setPassword] = useState();
    const [password2, setPassword2] = useState();
    const [errorMsgs, setErrorMsgs] = useState([]);
    const [successMsg, setSuccessMsg] = useState("");

    const handleSubmit = async e => {
        e.preventDefault();
        if(!(password === password2)){
            setErrorMsgs(["Passwords do not match"]);
            return;
        }
        fetch('/api/password_reset/confirm/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify({
                token: token,
                password
            }),
        })
            .then(response => {
                if (response.ok) {
                    setSuccessMsg("Password successfully reset");
                } else {
                    return response.json();
                }
            })
            .then(data => {
                setErrorMsgs(data["password"]);
            });
    };
    return (
        <AuthBase>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Set new password
            </Typography>
            {errorMsgs.map((errorMsg, index) => (
                <Typography key={index} className={classes.errorMessage} component="p" variant="h5">{errorMsg}</Typography>
            ))
            }
            {successMsg !== "" &&
            <Typography className={classes.successMessage} component="p" variant="h5">{successMsg}</Typography>}
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    autoFocus
                    onChange={e => setPassword(e.target.value)}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password2"
                    label="Confirm Password"
                    name="password2"
                    type="password"
                    autoFocus
                    onChange={e => setPassword2(e.target.value)}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={successMsg.length > 0}
                >
                    Reset Password
                </Button>
                <Link href="/login/">Back to Loginpage</Link>
            </form>
        </AuthBase>
    );
}