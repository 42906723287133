import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import React, {useContext, useEffect, useState} from "react";
import CountrySelect from "./CountrySelect";
import LanguageSelect from "./LanguageSelect";
import SearchInput from "./SearchInput";
import {Link, useLocation} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import QuestionnaireContext from "../../context/questionnaire-context";
import {intToRoman} from "../../utils/general-helpers";
import {makeStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import {Menu} from "@material-ui/icons";
import {rtColors} from "../../assets/jss/rt-theme";
import UserContext from "../../context/user-context";
import Button from "@material-ui/core/Button";
import AdminSelect from "./AdminSelect";
import RoSwitch from "./ROSwitch";
import AppInfoContext from "../../context/appinfo-context";


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
        backgroundColor: rtColors.primary,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    textNotEllipsis: {
        textOverflow: 'unset',
    }
}));

const TopBar = (props) => {
    const [title, setTitle] = useState("");
    const location = useLocation();
    const appCtx = useContext(AppInfoContext);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    //choose the screen size
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });
    const titleBase = appCtx.labelShort + " | ";
    // finally you can render components conditionally if  is True or False
    useEffect(() => {
        let _title = titleBase;
        switch (location.pathname) {
            case "/":
                _title += "Start";
                break;
            case "/login":
            case "/login/":
                _title += "Login";
                break;
            case "/dashboard":
            case "/dashboard/":
                _title += "Dashboard";
                break;
            case "/questionnaire":
            case "/questionnaire/":
                _title += intl.formatMessage({defaultMessage: "Questionnaire"});
                break;
            case "/search":
            case "/search/":
                _title += intl.formatMessage({defaultMessage: "Search results"});
                break;
            case "/resetPassword":
            case "/resetPassword/":
                _title += intl.formatMessage({defaultMessage: "Password reset"});
                break;
            case "/info":
            case "/info/":
                _title += intl.formatMessage({defaultMessage: "About"});
                break;
            case "/glossary":
            case "/glossary/":
                _title += intl.formatMessage({defaultMessage: "Glossary"});
                break;
            default:
                if (location.pathname.includes("/question/")) {
                    // Since we expect the URL to end with a / (Django convention)
                    let id = parseInt(location.pathname.split("/").slice(0, -1).pop());
                    const [chapter, question] = questionnaireContext.getChapterAndQuestionById(id);
                    if (chapter) {
                        _title += intl.formatMessage({defaultMessage: "Chapter {chapterRoman}, Question {questionNumber}"},
                            {
                                chapterRoman: intToRoman(chapter.order),
                                questionNumber: question.order
                            });
                    }
                }
        }
        document.title = _title;
        setTitle(_title);
    }, [location.pathname]);
    const questionnaireContext = useContext(QuestionnaireContext);
    const ctx = useContext(UserContext);
    const classes = useStyles();
    const intl = useIntl();

    return (
        <AppBar position="absolute" className={clsx(classes.appBar)}>
            <Toolbar className={classes.toolbar}>
                {!props.simple &&
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.onIconClick}
                        className={clsx(classes.menuButton, props.open && classes.menuButtonHidden)}
                    >
                        <MenuIcon/>
                    </IconButton>
                }
                <Typography component="h1" variant="h6" color="inherit" className={classes.title}>
                    <Link to="/" style={{color: "inherit"}}>
                        {screenWidth < 1200 ? title.replace(titleBase, "") : title}
                    </Link>

                </Typography>
                {!props.simple &&
                    <SearchInput />
                }
                {!appCtx.disableTranslation && <LanguageSelect mobile={screenWidth < 1000}/>}
                {!ctx.getIsAnonOrRo() && <CountrySelect mobile={screenWidth < 1000}/>}
                {/*{ctx.isAdmin && <Button color="inherit" href="/admin/">Admin</Button>}*/}
                {ctx.isAdmin && <AdminSelect/>}
                {ctx.isAdmin && <RoSwitch/>}

                {!props.simple &&
                    <Hidden mdUp implementation="css">
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.handleDrawerToggle}
                        >
                            <Menu/>
                        </IconButton>
                    </Hidden>
                }
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;