import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import {UserContextProvider} from "./context/user-context";
import React from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {QuestionnaireContextProvider} from "./context/questionnaire-context";
import { ReactQueryDevtools } from 'react-query/devtools';


/*
axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';

axios.interceptors.request.use(request => {
    console.log(request);
    // Edit request config
    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    console.log(response);
    // Edit response config
    return response;
}, error => {
    console.log(error);
    return Promise.reject(error);
});
*/

import "./assets/css/material-dashboard-pro-react.css";
import "./assets/css/rtStyle.css";
import {CountryContextProvider} from "./context/country-context";
import {AppInfoContextProvider} from "./context/appinfo-context";
import {SnackbarProvider} from "notistack";
import axios from "axios";
import {authRefreshURL} from "./utils/api";

const isRoOrAnon = () => {
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    const storedIsReadonly = localStorage.getItem('isReadonly');
    const storedRoSwitchEnabled = localStorage.getItem('roSwitchEnabled');
    const isLoggedIn = storedIsLoggedIn !== null && storedIsLoggedIn !== 'undefined' ? JSON.parse(storedIsLoggedIn) : false;
    const isReadonly = storedIsReadonly !== null && storedIsReadonly !== 'undefined' ? JSON.parse(storedIsReadonly) : false;
    const roSwitchEnabled = storedRoSwitchEnabled !== null && storedRoSwitchEnabled !== 'undefined' ? JSON.parse(storedRoSwitchEnabled) : false;
    return !(isLoggedIn && !isReadonly && !roSwitchEnabled);
};


axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(config => {
    if(isRoOrAnon()){
        if (config.url.substring(0,4) === '/api'){
            if (!config.url.includes(authRefreshURL)){
                config.url = '/ro' + config.url;
            }
        }
    }
    return config;
});


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

ReactDOM.render(
    // <h1>Hi
    // </h1>
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <SnackbarProvider maxSnack={5}>
                <UserContextProvider>
                    <QuestionnaireContextProvider>
                        <CountryContextProvider>
                            <AppInfoContextProvider>
                                <App/>
                            </AppInfoContextProvider>
                        </CountryContextProvider>
                    </QuestionnaireContextProvider>
                </UserContextProvider>
            </SnackbarProvider>
            {/*<ReactQueryDevtools initialIsOpen={false} />*/}
        </QueryClientProvider>

    </BrowserRouter>
    , document.getElementById('root'));
