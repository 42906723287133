import React, {useContext, useEffect, useState} from 'react';
import MaterialTable from "@material-table/core";
import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {getDownloadableCategoriesAdminURL, getDownloadsURL} from "../../../utils/api";
import UserContext from "../../../context/user-context";
import {Divider, Fab, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import LoadingOverlay from 'react-loading-overlay';
import {useSnackbar} from "notistack";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import {dangerColor, successColor} from "../../../assets/jss/material-dashboard-pro-react";
import DownloadEditDialog from "./DownloadEditDialog";
import DownloadCategoryEditDialog from "./DownloadCategoryEditDialog";
import {CloudDownload} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    answeredIcon: {
        color: successColor[0]
    },
    notAnsweredIcon: {
        color: dangerColor[0]
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fab: {
        float: 'right',
        margin: '5px',
    },
    questionInactive: {
        backgroundColor: '#888'
    },
}));

const DownloadsTable = () => {
    const ctx = useContext(UserContext);
    const intl = useIntl();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const initialDownloadDialogState = {
        open: false,
        addOrEdit: 'add',
        categoryId: null,
        id: null,
        order: null,
        name: '',
        file: '',
        active: true,
        public: false,
        forReadonly: true,
        forExperts: true,
    };
    const initialCategoryDialogState = {
        open: false,
        addOrEdit: 'add',
        id: null,
        nameDe: '',
        nameEn: '',
    };
    const [category, setCategory] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [downloadDialogState, setDownloadDialogState] = useState(initialDownloadDialogState);
    const [categoryDialogState, setCategoryDialogState] = useState(initialCategoryDialogState);
    const classes = useStyles();
    const categoryAdminQuery = useQuery('downloadableCategoriesAdmin', () => axios(getDownloadableCategoriesAdminURL), {
        onSuccess: (data) => {
            if (selectedCategoryId === '' && data && data.data && data.data.length > 0) {
                setSelectedCategoryId(data.data[0].id);
            }
        },
    });
    const deleteCategoryAdminQuery = useMutation(
        () => axios.delete(getDownloadableCategoriesAdminURL + category.id + "/"), {
            onSuccess: () => {
                enqueueSnackbar(intl.formatMessage({defaultMessage: "Download Category deleted!"}), {variant: 'success'});
            },
            onSettled: () => {
                queryClient.invalidateQueries('downloadableCategoriesAdmin');
                queryClient.invalidateQueries('downloadables');
            }
        }
    );
    useEffect(() => {
        if (categoryAdminQuery.data && categoryAdminQuery.data.data) {
            setCategory(categoryAdminQuery.data.data.find(c => {
                return c.id === selectedCategoryId;
            }));
        }
    }, [selectedCategoryId, categoryAdminQuery]);
    useEffect(() => {
        if (category) {
            setDownloadDialogState({...downloadDialogState, categoryId: category.id});
        }
    }, [category]);
    const columns = [
        {title: "#", field: "order", width: "5%", render: rowData => rowData["order"] + ".)"},
        {title: "Name", field: "name", width: "40%"},
        {title: "Public", field: "public", render: rowData => (rowData["public"] ?
                <CheckCircleOutlineIcon className={classes.answeredIcon} /> :
                <CancelIcon className={classes.notAnsweredIcon} />)},
        {title: "For Readonly", field: "for_readonly", render: rowData => (rowData["for_readonly"] ?
                <CheckCircleOutlineIcon className={classes.answeredIcon} /> :
                <CancelIcon className={classes.notAnsweredIcon} />)},
        {title: "For Experts", field: "for_experts", render: rowData => (rowData["for_experts"] ?
                <CheckCircleOutlineIcon className={classes.answeredIcon} /> :
                <CancelIcon className={classes.notAnsweredIcon} />)},
        {title: "Download", field: "file", render: rowData => (<a href={rowData["file"]} download><CloudDownload /></a>)},

    ];
    const handleDownloadClose = () => {
        const order = category.downloadables.length > 0 ? category.downloadables[category.downloadables.length - 1].order + 1 : 1;
        setDownloadDialogState({
            ...initialDownloadDialogState,
            order: order,
            categoryId: category.id
        });
    };
    const handleRowClick = (event, rowData) => {
        setDownloadDialogState({
            open: true,
            addOrEdit: 'edit',
            order: rowData['order'],
            categoryId: category.id,
            id: rowData['id'],
            name: rowData["name"] ?? '',
            file: rowData["file"] ?? '',
            active: rowData["active"],
            public: rowData["public"],
            forExperts: rowData["for_experts"],
            forReadonly: rowData["for_readonly"],
        });
    };
    const handleSelectCategory = (event) => {
        setSelectedCategoryId(event.target.value);
    };
    const handleAddDownloadClick = () => {
        const order = category.downloadables.length > 0 ? category.downloadables[category.downloadables.length - 1].order + 1 : 1;
        setDownloadDialogState({
            ...initialDownloadDialogState,
            open: true,
            order: order,
            categoryId: category.id
        });
    };
    const handleAddCategoryClick = () => {
        const order = categoryAdminQuery.data.data.length > 0 ?
            categoryAdminQuery.data.data[categoryAdminQuery.data.data.length - 1].order + 1 : 1;
        setCategoryDialogState({...initialCategoryDialogState, open: true, order: order});
    };
    const handleCategoryAdded = (id) => {
        setSelectedCategoryId(id);
    };
    const handleEditCategoryClick = () => {
        setCategoryDialogState({
            open: true,
            addOrEdit: 'edit',
            id: category.id,
            nameDe: category.name_de,
            nameEn: category.name_en,
        });
    };
    const handleCategoryClose = () => {
        setCategoryDialogState(initialCategoryDialogState);
    };
    const handleDeleteCategoryClick = () => {
        deleteCategoryAdminQuery.mutate();
        // TODO: This should select the first chapter if exists (same in QuestionsTable)
        setSelectedCategoryId('');
    };
    return (
            <>
            {categoryAdminQuery.data && categoryAdminQuery.data.data &&
                (
                    <>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel><FormattedMessage defaultMessage="Download Category"/></InputLabel>
                        <Select
                            value={selectedCategoryId}
                            onChange={handleSelectCategory}
                        >
                            {categoryAdminQuery.data.data.map(category => (
                                <MenuItem key={category.id} value={category.id}>{ctx.locale === 'en' ?
                                    category["name_en"] : category["name_de"]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12} sm={6}>

                    <Fab aria-label="delete category" onClick={handleDeleteCategoryClick}
                         variant="extended" className={classes.fab}
                    >
                        <FormattedMessage defaultMessage="Delete Download Category"/>
                        <DeleteIcon/>
                    </Fab>
                    <Fab aria-label="edit category" onClick={handleEditCategoryClick}
                         variant="extended" className={classes.fab}
                    >
                        <FormattedMessage defaultMessage="Edit Download Category"/>
                        <EditIcon/>
                    </Fab>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Fab color="secondary" aria-label="add category" onClick={handleAddCategoryClick}
                         variant="extended" className={classes.fab}
                    >
                        <FormattedMessage defaultMessage="Add Category"/>
                        <AddIcon/>
                    </Fab>
                </Grid>

                <Grid item xs={12} sm={12}>

                    {category &&
                        <Fab color="primary" aria-label="add download" onClick={handleAddDownloadClick}
                             variant="extended" className={classes.fab}
                        >
                            <FormattedMessage defaultMessage="Add Download"/>
                            <AddIcon/>
                        </Fab>
                    }
                </Grid>
            </Grid>

            </>
            )
            }
            <Divider/>
            {category &&
            <div>
                <LoadingOverlay
                    active={categoryAdminQuery.isFetching || !categoryAdminQuery.data || !categoryAdminQuery.data.data || !category}
                    spinner
                >
                <MaterialTable
                    key={category.id}
                    onRowClick={handleRowClick}
                    title={ctx.locale === 'en' ? category["name_en"] : category["name_de"]}
                    columns={columns}
                    data={category.downloadables}
                    options={{
                        // pageSize: 100,
                        paging: false,
                        // header: false,
                        tableLayout: "fixed",
                        actionsColumnIndex: -1,
                        rowStyle: (rowData) => {
                            return {
                              backgroundColor: rowData.active ? "white" : "#888"
                            };
                        }
                    }}
                />
                </LoadingOverlay>

                <Divider/>
            </div>
            }
            <DownloadEditDialog dialogState={downloadDialogState} onClose={handleDownloadClose}/>
            <DownloadCategoryEditDialog dialogState={categoryDialogState} onClose={handleCategoryClose}/>
</>
    );
};

export default DownloadsTable;