import React from 'react';
import List from "@material-ui/core/List";
import {ListItem} from "@material-ui/core";
import {useQuery} from "react-query";
import axios from "axios";
import {getAttachmentsURL} from "../../utils/api";
import Link from "@material-ui/core/Link";
import GetAppIcon from '@material-ui/icons/GetApp';
import {FormattedMessage} from "react-intl";

const QuestionAttachments = (props) => {
    const {isLoading, isError, data, error} = useQuery(['attachments', props.question.id],
        () => axios(getAttachmentsURL, {
            params: {
                question: props.question.id,
            },
        }), {}
    );
    return (
        <div>
            {isError && <p>{error}</p>}
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <div>
                {data && data.data && data.data.length > 0 && (
                        <div>
                            <h3><FormattedMessage defaultMessage="Attachments" /></h3>
                            <List>
                                {data.data && data.data.map((attachment, index) => (
                                    <ListItem key={index}>
                                        <Link href={attachment.file} target="_blank"><GetAppIcon /> {attachment.name}</Link>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
export default QuestionAttachments;