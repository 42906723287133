import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid} from "@material-ui/core";
import DialogTitle from "../../base/modals/DialogTitle";
import {FormattedMessage, useIntl} from "react-intl";
import {Formik, Field, Form, ErrorMessage, useField, FieldArray} from 'formik';
import {useMutation, useQueryClient} from "react-query";
import {getChapterAdminURL} from "../../../utils/api";
import {useSnackbar} from "notistack";
import MyTextInput from "../../form/MyTextInput";
import MyCheckbox from "../../form/MyCheckbox";
import * as Yup from 'yup';
import axios from "axios";

const ChapterEditDialog = (props) => {
    const queryClient = useQueryClient();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const addChapter = useMutation(
        chapter => axios.post(getChapterAdminURL, {
            order: chapter.order,
            name_de: chapter.nameDe,
            name_en: chapter.nameEn,
            explanation_de: chapter.explanationDe,
            explanation_en: chapter.explanationEn,
            weight: chapter.weight,
            active: chapter.active,
            public: chapter.public
        }), {
            onSuccess: (data, variables, context) => {
                enqueueSnackbar(intl.formatMessage({defaultMessage: "Added Chapter!"}), {variant: 'success'});
                props.onClose();
            },
            onSettled: () => {
                queryClient.invalidateQueries('chapterAdmin');
                queryClient.invalidateQueries('chapters');
            }
        }
    );
    const updateChapter = useMutation(
        chapter => axios.put(getChapterAdminURL + props.dialogState.id + "/", {
            order: chapter.order,
            name_de: chapter.nameDe,
            name_en: chapter.nameEn,
            explanation_de: chapter.explanationDe,
            explanation_en: chapter.explanationEn,
            weight: chapter.weight,
            active: chapter.active,
            public: chapter.public
        }), {
            onSuccess: (data, variables, context) => {
                enqueueSnackbar(intl.formatMessage({defaultMessage: "Chapter edited!"}), {variant: 'success'});
                props.onClose();
            },
            onSettled: () => {
                queryClient.invalidateQueries('chapterAdmin');
                queryClient.invalidateQueries('chapters');
            }
        }
    );
    return (
        <>
            <Dialog open={props.dialogState.open} onClose={props.onClose}>
                <DialogTitle onClose={props.onClose}>
                    {
                        props.dialogState.addOrEdit === 'add' ? <FormattedMessage defaultMessage="Add Chapter"/> :
                            <FormattedMessage defaultMessage="Edit Chapter"/>
                    }
                </DialogTitle>
                <Formik
                    initialValues={{
                        order: props.dialogState.order,
                        nameDe: props.dialogState.nameDe,
                        nameEn: props.dialogState.nameEn,
                        explanationDe: props.dialogState.explanationDe,
                        explanationEn: props.dialogState.explanationEn,
                        weight: props.dialogState.weight,
                        active: props.dialogState.active,
                        public: props.dialogState.public,
                    }}
                    validationSchema={Yup.object({

                    }).shape({
                        'nameDe': Yup.string()
                            .when('nameEn', {
                                is: (nameEn) => !nameEn || nameEn.length === 0,
                                then: Yup.string()
                                    .required('At least one of the fields is required')
                            }),
                        'nameEn': Yup.string()
                            .when('nameDe', {
                                is: (nameDe) => !nameDe || nameDe.length === 0,
                                then: Yup.string()
                                    .required('At least one of the fields is required')
                            }),
                    }, ['nameDe', 'nameEn'])}
                    onSubmit={(values, setSubmitting) => {
                        if (props.dialogState.addOrEdit === 'add') {
                            addChapter.mutate(values);
                        } else {
                            updateChapter.mutate(values);
                        }
                    }}
                >
                    {formik => (
                        <Form>
                            <DialogContent>
                                <DialogContentText>

                                </DialogContentText>
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <MyTextInput
                                            name="order"
                                            label="Order"
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MyTextInput
                                            name="weight"
                                            label="Weight"
                                            type="number"
                                            step="0.001"

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MyTextInput
                                            name="nameDe"
                                            label="Name DE"
                                            type="text"
                                            multiline
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MyTextInput
                                            name="nameEn"
                                            label="Name EN"
                                            type="text"
                                            multiline
                                        />
                                    </Grid>
                                    <h4><FormattedMessage defaultMessage="Explanation" /></h4>
                                    <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <MyTextInput
                                            name="explanationDe"
                                            label="Explanation DE"
                                            type="text"
                                            multiline
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MyTextInput
                                            name="explanationEn"
                                            label="Explanation EN"
                                            type="text"
                                            multiline
                                        />
                                    </Grid>
                                    </Grid>
                                    <MyCheckbox
                                        name="active"
                                        label={intl.formatMessage({defaultMessage: "is active"})}
                                    />

                                    <MyCheckbox
                                        name="public"
                                        label={intl.formatMessage({defaultMessage: "is public"})}
                                    />
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={props.onClose} color="secondary" disabled={formik.isSubmitting}>
                                    <FormattedMessage defaultMessage="Cancel"/>
                                </Button>
                                <Button type="submit" color="primary" disabled={formik.isSubmitting}>
                                    <FormattedMessage defaultMessage="Save"/>
                                </Button>
                            </DialogActions>

                        </Form>
                    )}
                </Formik>
            </Dialog>
        </>
    );
};

export default ChapterEditDialog;