import React from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {intToRoman} from "../../../utils/general-helpers";

function ChaptersAccordion({ qContext, isAnon, questions, handleChapterChecked, handleChange }) {
    return (
        <Box>
            {qContext.chapters && qContext.chapters.map((chapter) => (
                <Accordion key={chapter.id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <FormControlLabel
                            control={<Checkbox onChange={handleChapterChecked} value={chapter.id}/>}
                            label={intToRoman(chapter.order) + " " + chapter.name}
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            {chapter.questions.map((question) => {
                                if (isAnon && !question.public) {
                                    return null;
                                }
                                return (
                                    <FormControlLabel key={question.id}
                                                      control={
                                                          <Checkbox
                                                              checked={questions.includes(question.id.toString())}
                                                              onChange={handleChange}
                                                              value={question.id}
                                                          />
                                                      }
                                                      label={question.order + ". " + question.text}
                                    />
                                );
                            })}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}

export default ChaptersAccordion;