import React, {useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {useMutation, useQueryClient} from "react-query";
import {getAttachmentsURL, getChapterAdminURL, getQuestionAdminURL} from "../../../utils/api";
import axios from "axios";
import {
    Button,
    ButtonGroup,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    MenuItem, Snackbar
} from "@material-ui/core";
import {Formik, Field, Form, ErrorMessage, useField, FieldArray, useFormikContext} from 'formik';
import MyTextInput from "../../form/MyTextInput";
import {Select, SimpleFileUpload} from "formik-material-ui";
import GridContainer from "../../Grid/GridContainer";
import MyCheckbox from "../../form/MyCheckbox";
import * as Yup from 'yup';
import {Alert} from "@material-ui/lab";
import {useSnackbar} from "notistack";
import GetAppIcon from '@material-ui/icons/GetApp';
import DialogTitle from "../../base/modals/DialogTitle";
import {useHistory} from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import QuestionnaireContext from "../../../context/questionnaire-context";
import {intToRoman} from "../../../utils/general-helpers";
import UserContext from "../../../context/user-context";


const QuestionEditDialog = (props) => {
    const intl = useIntl();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [attachmentSetState, setAttachmentSetState] = useState([]);
    const questionnaireCtx = useContext(QuestionnaireContext);
    const ctx = useContext(UserContext);
    useEffect(() => {
        setAttachmentSetState(props.dialogState.attachmentSet);
    }, [props.dialogState.attachmentSet]);
    const uploadFile = (questionId, attachment) => {
        let formData = new FormData();
        formData.append('name', attachment.name);
        formData.append('file', attachment.file);
        formData.append('question', questionId);
        axios.post(getAttachmentsURL, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).catch((err) => {
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Question got saved, but Attachments could\n" +
                    "                not be saved. Maybe the file is too large? (Max. 5MB)"}) + `Error-Msg: [${err}]`, {variant: 'error'});

        });
    };
    const addQuestion = useMutation(
        question => axios.post(getQuestionAdminURL, {
            chapter: question.chapter,
            order: question.order,
            text_de: question.textDe,
            text_en: question.textEn,
            is_marked_as_special: question.isMarkedAsSpecial,
            active: question.active,
            public: question.public,
            explanation_de: question.explanationDe,
            explanation_en: question.explanationEn,
            answers: question.answers,
        }), {
            onError: (error, variables, context) => {

            },
            onSuccess: (data, variables, context) => {
                // now upload attachments
                variables.attachmentSet.forEach((attachment) => {
                    uploadFile(data.data.id, attachment);
                    // setAttachmentSetState(attachmentSetState.concat([attachment]));
                });
                enqueueSnackbar(intl.formatMessage({defaultMessage: "Question added!"}), {variant: 'success'});
                props.onClose();
            },
            onSettled: (data) => {
                queryClient.invalidateQueries('chapterAdmin');
                queryClient.invalidateQueries('chapters');
            }
        }
    );
    const updateQuestion = useMutation(
        question => axios.put(getQuestionAdminURL + props.dialogState.id + "/", {
            chapter: question.chapter,
            order: question.order,
            text_de: question.textDe,
            text_en: question.textEn,
            is_marked_as_special: question.isMarkedAsSpecial,
            active: question.active,
            public: question.public,
            explanation_de: question.explanationDe,
            explanation_en: question.explanationEn,
            answers: question.answers,
        }), {
            onSuccess: (data, variables, context) => {
                // now upload attachments
                variables.attachmentSet.forEach((attachment) => {
                    uploadFile(data.data.id, attachment);
                });
                enqueueSnackbar(intl.formatMessage({defaultMessage: "Question edited!"}), {variant: 'success'});
                props.onClose();
            },
            onSettled: () => {
                queryClient.invalidateQueries('chapterAdmin');
                queryClient.invalidateQueries('chapters');
            }
        }
    );
    const deleteQuestion = useMutation(
        questionId => axios.delete(getQuestionAdminURL + questionId + "/"), {
            onSuccess: () => {
                enqueueSnackbar(intl.formatMessage({defaultMessage: "Question deleted!"}), {variant: 'success'});
            },
            onSettled: () => {
                queryClient.invalidateQueries('chapterAdmin');
                queryClient.invalidateQueries('chapters');
            }
        }
    );
    const deleteAttachment = useMutation(
        attachmentId => axios.delete(getAttachmentsURL + attachmentId + "/"),
        {
            onSuccess: (data, variables, context) => {
                enqueueSnackbar(intl.formatMessage({defaultMessage: "Attachment deleted!"}), {variant: 'success'});
                setAttachmentSetState(attachmentSetState.filter(a => {return a.id !== variables;}));
            },
            onSettled: () => {
                queryClient.invalidateQueries('chapterAdmin');
                queryClient.invalidateQueries('chapters');
            }
        }
    );
    const handleDelete = () => {
        deleteQuestion.mutate(props.dialogState.id);
        props.onClose();
    };
    const handleDeleteFile = (event) => {
        deleteAttachment.mutate(parseInt(event.currentTarget.dataset.id));
    };
    return (
        <>
        <Dialog open={props.dialogState.open} onClose={props.onClose} aria-labelledby="question-add-edit-title"
                maxWidth="lg" fullWidth={true}
        ><LoadingOverlay
            active={addQuestion.isLoading || updateQuestion.isLoading}
            spinner
        >
            <DialogTitle id="question-add-edit-title" onClose={props.onClose}>{
                props.dialogState.addOrEdit === 'add' ? <FormattedMessage defaultMessage="Add Question"/> :
                    <FormattedMessage defaultMessage="Edit Question"/>
            }</DialogTitle>
            <Formik
                initialValues={{
                    chapter: props.dialogState.chapterId,
                    order: props.dialogState.order,
                    textDe: props.dialogState.textDe,
                    textEn: props.dialogState.textEn,
                    isMarkedAsSpecial: props.dialogState.isMarkedAsSpecial,
                    active: props.dialogState.active,
                    public: props.dialogState.public,
                    explanationDe: props.dialogState.explanationDe,
                    explanationEn: props.dialogState.explanationEn,
                    answers: props.dialogState.answers,
                    attachmentSet: []
                }}
                validationSchema={Yup.object({
                    // answers: Yup.array().required().min(2, intl.formatMessage({
                    //     defaultMessage: "At least 2 Answers required"})).of(
                    //         Yup.object().shape({
                    //             text_de: Yup.string().when('text_en', {
                    //                 is: (text_en) => !text_en || text_en.length === 0,
                    //                 then: Yup.string().required('At least one of the fields is required')
                    //             })
                    //         })
                    // ),
                    attachmentSet: Yup.array().of(
                        Yup.object().shape({
                            name: Yup.string().required("Name required"),
                            file: Yup.mixed().required("A file is required")
                        })
                    )
                }).shape({
                    'textDe': Yup.string()
                        .when('textEn', {
                            is: (textEn) => !textEn || textEn.length === 0,
                            then: Yup.string()
                                .required('At least one of the fields is required'),
                        }),
                    'textEn': Yup.string()
                        .when('textDe', {
                            is: (textDe) => !textDe || textDe.length === 0,
                            then: Yup.string()
                                .required('At least one of the fields is required')
                        }),

                    // 'answers': Yup.array().of(Yup.object().shape({
                    //     order: Yup.number().required(),
                        // text_de: Yup.string().when('text_en', {
                        //     is: (text_en) => !text_en || text_en.length === 0,
                        //     then: Yup.string().required('At least one of the fields is required')
                        // }),
                        // text_en: Yup.string().when('text_de', {
                        //     is: (text_de) => !text_de || text_de.length === 0,
                        //     then: Yup.string().required('At least one of the fields is required')
                        // }),
                    // }))
                }, ['textDe', 'textEn'])}
                // validator={() => ({})}
                onSubmit={async (values, formikHelpers, setSubmitting) => {
                    let id;
                    if (props.dialogState.addOrEdit === 'add') {
                        const newQuestion = await addQuestion.mutateAsync(values, {});
                        id = newQuestion.data.id;
                    } else {
                        await updateQuestion.mutateAsync(values);
                        id = props.dialogState.id;
                    }
                    if (values.hasOwnProperty('redirect') && values['redirect'] === true){
                        questionnaireCtx.onReloadChapters(null);
                        history.push("/question/" + id + "/");
                    }
                }}
            >{formik => (
                <Form>
                    <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Field
                                    component={Select}
                                    name="chapter"
                                    label="Chapter"
                                >
                                    {
                                        props.chapters.map((chapter, i) => (
                                            <MenuItem key={i + 1}
                                                      value={chapter.id}>{intToRoman(chapter.order) + " " +
                                                (ctx.locale === 'en' ? chapter["name_en"] : chapter["name_de"])}</MenuItem>
                                        ))
                                    }
                                </Field>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <MyTextInput
                                    name="order"
                                    label="Order"
                                    type="number"
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <MyTextInput
                                    name="textDe"
                                    label="Text DE"
                                    type="text"
                                    multiline
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MyTextInput
                                    name="textEn"
                                    label="Text EN"
                                    type="text"
                                    multiline
                                />
                            </Grid>

                        </Grid>
                        <h4><FormattedMessage defaultMessage="Answers" /></h4>
                        <FieldArray name="answers">
                            {
                                (fieldArrayProps) => {
                                    const {push, remove, form} = fieldArrayProps;
                                    const {values} = form;
                                    const {answers} = values;
                                    return (
                                        <div>
                                            {answers.sort((a, b) => {
                                                return a.order > b.order ? 1 :
                                                    (a.order < b.order ? -1 : 0);
                                            }).map((answer, index) => (
                                                <Grid container key={index}>
                                                    <Grid item xs={2}>
                                                        <Field
                                                            component={Select}
                                                            name={`answers[${index}].order`}
                                                            label="Order"
                                                        >
                                                            {
                                                                [...Array(10)].map((e, i) => (
                                                                    <MenuItem key={i + 1}
                                                                              value={i + 1}>{i + 1}</MenuItem>
                                                                ))
                                                            }
                                                        </Field>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <MyTextInput
                                                            name={`answers[${index}].text_de`}
                                                            label="Text DE"
                                                            type="text"
                                                            multiline
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <MyTextInput
                                                            name={`answers[${index}].text_en`}
                                                            label="Text EN"
                                                            type="text"
                                                            multiline
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <ButtonGroup>
                                                            {
                                                                index > 0 &&
                                                                <Button type="button"
                                                                        onClick={() => remove(index)}> - </Button>
                                                            }
                                                            <Button type="button" onClick={() => push({
                                                                order: index + 2,
                                                                text_de: '', text_en: ''
                                                            })}> + </Button>
                                                        </ButtonGroup>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            {formik.touched.answers && formik.errors.answers &&
                                            <div>{formik.errors.answers}</div>
                                            }
                                        </div>
                                    );
                                }
                            }
                        </FieldArray>
                        <h4><FormattedMessage defaultMessage="Explanation" /></h4>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <MyTextInput
                                    name="explanationDe"
                                    label="Explanation DE"
                                    type="text"
                                    multiline
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MyTextInput
                                    name="explanationEn"
                                    label="Explanation EN"
                                    type="text"
                                    multiline
                                />
                            </Grid>
                        </Grid>
                        <MyCheckbox
                            name="active"
                            label={intl.formatMessage({defaultMessage: "is active"})}
                        />

                        <MyCheckbox
                            name="public"
                            label={intl.formatMessage({defaultMessage: "is public"})}
                        />

                        <MyCheckbox
                            name="isMarkedAsSpecial"
                            label={intl.formatMessage({defaultMessage: "needs attention"})}
                        />
                        <h4><FormattedMessage defaultMessage="Attachments" /></h4>
                        {
                            attachmentSetState && attachmentSetState.length > 0 &&
                               <div><span><FormattedMessage defaultMessage="Current files: "/></span>
                                {
                                    attachmentSetState.map((attachment, index) => (
                                    <Grid container key={index}>
                                    <Grid item>
                                    <a href={attachment.file} download><GetAppIcon /> {attachment.name}
                                    </a>
                                    </Grid>
                                    <Grid item>
                                    <Button onClick={handleDeleteFile} data-id={attachment.id}>
                                    <FormattedMessage defaultMessage="Delete" />
                                    </Button>
                                    </Grid>
                                    </Grid>
                                    ))
                                }
                               </div>
                        }
                        <FieldArray name="attachmentSet">
                            {
                                (fieldArrayProps) => {
                                    const {push, remove, form} = fieldArrayProps;
                                    const {values} = form;
                                    const {attachmentSet} = values;
                                    return (
                                        <div>
                                            {attachmentSet.length > 0 ? (attachmentSet.map((attachment, index) => (
                                                <Grid container key={index}>
                                                    <Grid item xs={5}>
                                                        <MyTextInput
                                                            name={`attachmentSet[${index}].name`}
                                                            label="Name"
                                                            type="text"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5}>

                                                        <Field
                                                            component={SimpleFileUpload}
                                                            name={`attachmentSet[${index}].file`}
                                                            label="File Upload"
                                                            onChange={(event) => {
                                                                formik.setFieldValue(`attachmentSet[${index}].file`, event.target.files[0]);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <ButtonGroup>
                                                            <Button type="button"
                                                                    onClick={() => remove(index)}> - </Button>
                                                            <Button type="button" onClick={() => push({
                                                                name: '',
                                                                file: ''
                                                            })}> + </Button>
                                                        </ButtonGroup>
                                                    </Grid>
                                                </Grid>
                                            ))) : (
                                                <Button type="button" onClick={() => push({name: '', file: ''})}>
                                                    <FormattedMessage defaultMessage="Add an attachment"/>
                                                </Button>
                                            )}
                                        </div>
                                    );
                                }
                            }
                        </FieldArray>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onClose} color="secondary" disabled={formik.isSubmitting}>
                            <FormattedMessage defaultMessage="Cancel"/>
                        </Button>
                        {
                            props.dialogState.addOrEdit === 'edit' &&
                            <Button onClick={handleDelete} color="secondary" style={{marginRight: "auto"}} disabled={formik.isSubmitting}>
                                <FormattedMessage defaultMessage="Delete Question"/>
                            </Button>
                        }
                        <Button type="submit" color="primary" >
                            <FormattedMessage defaultMessage="Save" disabled={formik.isSubmitting}/>
                        </Button>
                        <Button type="submit" color="primary" onClick={() => formik.setFieldValue("redirect", true)}>
                            <FormattedMessage defaultMessage="Save & go to Question" disabled={formik.isSubmitting || !formik.values.active}/>
                        </Button>
                    </DialogActions>
                </Form>
            )}

            </Formik>
        </LoadingOverlay>
        </Dialog>
        </>
    );
};

export default QuestionEditDialog;