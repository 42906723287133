import React from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import List from "@material-ui/core/List";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import {rtColors} from "../../assets/jss/rt-theme";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    dlCategoryText: {
        color: rtColors.grey1
    }
}));

const DownloadablesList = (props) => {
    const classes = useStyles();
    function ListItemLink(props) {
        return <ListItem button component="a" {...props} download/>;
    }
    return (
        <div>
            {props.downloadablesCategories.map((category, index) => (
                <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.dlCategoryText}>{category.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List component="div" disablePadding>
                            {category.downloadables.map((downloadable, index) => (
                                <ListItemLink key={index} href={downloadable.file} target="_blank">
                                    <ListItemIcon>
                                        <DescriptionIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={downloadable.name} />
                                </ListItemLink>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
                ))}

        </div>
    );
};

export default DownloadablesList;