import React from 'react';
import {useContext} from "react";
import ChapterTabs from "./ChapterTabs";
import QuestionnaireContext from "../../context/questionnaire-context";
import {makeStyles} from "@material-ui/core/styles";
import {rtBoxShadow} from "../../assets/jss/rt-theme";
import UserContext from "../../context/user-context";
import DisclaimerModal from "../base/modals/DisclaimerModal";
import AppInfoContext from "../../context/appinfo-context";
import DisclaimerModalRTCBL from "../base/modals/DisclaimerModalRTCBL";

const useStyles = makeStyles((theme) => ({
    questionnaireBox: {
        boxShadow: rtBoxShadow.grey,
        overflow: "auto"
    },

}));

const Questionnaire = () => {
    const classes = useStyles();
    const ctx = useContext(UserContext);
    const appCtx = useContext(AppInfoContext);
    const questionnaireCtx = useContext(QuestionnaireContext);

    return (
        <div className={classes.questionnaireBox}>
            {appCtx.enableDisclaimer && !ctx.disclaimerAccepted &&
               (appCtx.labelShort === 'Round Table Covered Bond Legislation' ? <DisclaimerModalRTCBL /> : <DisclaimerModal />)
            }
            {questionnaireCtx.chapters && <ChapterTabs chapters={questionnaireCtx.chapters}/> }
        </div>
    );
};

export default Questionnaire;