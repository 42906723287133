import React, {useEffect, useState} from 'react';


const QuestionnaireContext = React.createContext({
    chapters: null,
    chaptersLoading: false,
    onReloadChapters: (chapters) => {
    },
    getQuestionCount: () => {
    },
    getAnsweredCount: () => {
    },
    getMinQuestionCount: () => {
    },
    getMaxQuestionCount: () => {
    },
    getAvgQuestionCount: () => {
    },
    getChapterAndQuestionById: (id) => {
    },
    getNextQuestionById: (id, only_public) => {
    },
    getPreviousQuestionById: (id, only_public) => {
    },
});

export const QuestionnaireContextProvider = (props) => {
    const [chapters, setChapters] = useState(null);
    const [chaptersLoading, setChaptersLoading] = useState(false);
    useEffect( () => {
        const storedChapters = localStorage.getItem('chapters');
        if (storedChapters && Array.isArray(storedChapters)) {
            setChapters(JSON.parse(storedChapters));
        }
    }, []);
    const reloadChapters = (chapters) => {
        localStorage.setItem('chapters', JSON.stringify(chapters));
        setChapters(chapters);
    };
    const getQuestionCount = () => {
        if (chapters.length > 0)
            return chapters.map(c => c.questions.length).reduce((acc, cur) => acc + cur);
        return null;
    };
    const getMinQuestionCount = () => {
        if (chapters.length > 0)
            return Math.min(...chapters.map(c => c.questions.length));
        return null;
    };
    const getMaxQuestionCount = () => {
        if (chapters.length > 0)
            return Math.max(...chapters.map(c => c.questions.length));
        return null;
    };
    const getAvgQuestionCount = () => {
        if (chapters.length > 0){
            let arr = (chapters.map(c => c.questions.length));
            return arr.reduce((acc, cur) => acc + cur) / arr.length | 0;
        }
        return null;
    };
    const getChapterAndQuestionById = (id) => {
        if (!chapters || chapters.length === 0)
            return [null, null];
        for (let i = 0; i < chapters.length; i++) {
            for (let j = 0; j < chapters[i].questions.length; j++) {
                if (id === chapters[i].questions[j].id) {
                    return [chapters[i], chapters[i].questions[j]];
                }
            }
        }
        return [null, null];

    };

    // TODO: This should be stored in a variable for performance
    const getAnsweredCount = () => {
        let counter = 0;
        if (chapters) {
            for (let i = 0; i < chapters.length; i++) {
                for (let j = 0; j < chapters[i].questions.length; j++) {
                    if (chapters[i].questions[j].is_answered) {
                        counter++;
                    }
                }
            }
        }

        return counter;
    };
    const getNextQuestionById = (id, only_public=false) => {
        if (chapters) {
            for (let i = 0; i < chapters.length; i++) {
                for (let j = 0; j < chapters[i].questions.length; j++) {
                    // found ID
                    if (id === chapters[i].questions[j].id) {
                        // found next q in chapter
                        if (chapters[i].questions.length > (j+1)){
                            if (only_public && !chapters[i].questions[j+1].public) {
                                return getNextQuestionById(chapters[i].questions[j+1].id, only_public);
                            }
                            return chapters[i].questions[j+1];
                            // last question in chapter
                        } else if(chapters.length > (i+1) && chapters[i+1].questions.length > 0) {
                            if (only_public && !chapters[i+1].questions[0].public) {
                                return getNextQuestionById(chapters[i+1].questions[0].id, only_public);
                            }
                            return chapters[i+1].questions[0];
                            // last question
                        } else {
                            return false;
                        }
                    }
                }
            }
        }
    };

    const getPreviousQuestionById = (id, only_public) => {
        if (chapters) {
            for (let i = 0; i < chapters.length; i++) {
                for (let j = 0; j < chapters[i].questions.length; j++) {
                    // found ID
                    if (id === chapters[i].questions[j].id) {
                        // return previous question
                        if (j !== 0){
                            if (only_public && !chapters[i].questions[j-1].public) {
                                return getPreviousQuestionById(chapters[i].questions[j-1].id, only_public);
                            }
                            return chapters[i].questions[j-1];
                            // return last q from previous chapter
                        } else if(i !== 0 && chapters[i-1].questions.length > 0) {
                            if (only_public && !chapters[i-1].questions[chapters[i-1].questions.length-1].public) {
                                return getPreviousQuestionById(chapters[i-1].questions[chapters[i-1].questions.length-1].id, only_public);
                            }
                            return chapters[i-1].questions[chapters[i-1].questions.length - 1];
                            // first question
                        } else {
                            return false;
                        }
                    }
                }
            }
        }
    };

    return (
        <QuestionnaireContext.Provider value={
            {
                chapters: chapters,
                chaptersLoading: chaptersLoading,
                onReloadChapters: reloadChapters,
                getQuestionCount: getQuestionCount,
                getAnsweredCount: getAnsweredCount,
                getMinQuestionCount: getMinQuestionCount,
                getMaxQuestionCount: getMaxQuestionCount,
                getAvgQuestionCount: getAvgQuestionCount,
                getChapterAndQuestionById: getChapterAndQuestionById,
                getNextQuestionById: getNextQuestionById,
                getPreviousQuestionById: getPreviousQuestionById
            }
        }>
            {props.children}
        </QuestionnaireContext.Provider>
    );

};

export default QuestionnaireContext;