export const intToRoman = function (num) {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
            "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
            "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
};
export const romanTitle = (order, name) => {
    return intToRoman(order) + " " + name;
};
export const pythonDateTimeToDate = (datetime) => {
    return datetime.split("T")[0];
};
export const pythonDateToHumanDate = (date) => {
    const parts = date.split("-");
    return [parts[2], parts[1], parts[0]].join(".");
};

const getFileBlobUsingURL = function (url, convertBlob) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener('load', function() {
        convertBlob(xhr.response);
    });
    xhr.send();
};

const blobToFile = function (blob, name) {
    blob.lastModifiedDate = new Date();
    blob.name = name;
    return blob;
};

export const getFileObjectFromURL = async function(filePathOrUrl, convertBlob) {
    await getFileBlobUsingURL(filePathOrUrl, function (blob) {
        convertBlob(blobToFile(blob, 'testFile.jpg'));
    });
};
