import {useField} from "formik";
import {TextField} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {dangerColor, successColor} from "../../assets/jss/material-dashboard-pro-react";
const useStyles = makeStyles((theme) => ({
    error: {
        color: dangerColor[0]
    },
    textInput: {
        padding: "5px"
    }
}));
const MyTextInput = ({...props}) => {
    const classes = useStyles();
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (<>
            <TextField {...props} {...field} variant="filled" className={classes.textInput} fullWidth
            />{meta.touched && meta.error ? (
            <div className={classes.error}>{meta.error}</div>
        ) : null}
        </>
    );
};
export default MyTextInput;