import React, {useContext, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import UserContext from "../../context/user-context";
import {dangerColor} from "../../assets/jss/material-dashboard-pro-react";
import AuthBase from "./AuthBase";
import {rtColors} from "../../assets/jss/rt-theme";
import AppInfoContext from "../../context/appinfo-context";
import {FormattedMessage, useIntl} from "react-intl";


const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        "&:focus,&:hover": {
            backgroundColor: rtColors.primaryLight,
            color: "white"
        },
        margin: theme.spacing(3, 0, 2),
        backgroundColor: rtColors.primary,
        color: "white",

    },

    errorMessage: {
        textAlign: "center",
        color: dangerColor[0]
    }
}));

export default function Login(props) {
    const classes = useStyles();
    const [email, setUserName] = useState();
    const [password, setPassword] = useState();
    const [errorMsg, setErrorMsg] = useState("");
    const ctx = useContext(UserContext);
    const appInfoCtx = useContext(AppInfoContext);
    const intl = useIntl();
    const handleSubmit = async e => {
        e.preventDefault();
        fetch('/api/token/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password
            }),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(response.status);
            })
            .then((data) => {
                ctx.onLogin(data);
            })
            .catch((error) => {
                switch (error.message) {
                    case "401":
                        setErrorMsg("Wrong E-Mail Address or Password");
                        break;
                    default:
                        setErrorMsg(error.message);
                        break;
                }
            });
    };
    return (
        <AuthBase>
            <div style={{textAlign: "center"}}>
            <Avatar className={classes.avatar} style={{margin: "8px auto"}}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                <FormattedMessage defaultMessage="Sign in" />
            </Typography>
            {errorMsg !== "" &&
            <Typography className={classes.errorMessage} component="p" variant="h5">{errorMsg}</Typography>}
            </div>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={intl.formatMessage({defaultMessage: "Email Address"})}
                    name="username"
                    autoComplete="email"
                    autoFocus
                    onChange={e => setUserName(e.target.value)}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={intl.formatMessage({defaultMessage: "Password"})}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={e => setPassword(e.target.value)}

                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                >
                    <FormattedMessage defaultMessage="Sign in" />
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link href="/resetPassword/" variant="body2">
                        <FormattedMessage defaultMessage="Forgot password?"/>
                        </Link>
                    </Grid>
                </Grid>
                {
                    appInfoCtx.rtAllowAnonUsers &&
                    <Grid container>
                        <Grid item xs>
                            <Link href="/dashboard/" variant="body2">
                            <FormattedMessage defaultMessage="Continue as guest" />
                            </Link>
                        </Grid>
                    </Grid>
                }
                <Box mt={5}>
                </Box>
            </form>
        </AuthBase>
    );
}