import React, {useContext} from "react";
import UserContext from "../../context/user-context";
import Flag from "react-flagkit";
import {FormControl, MenuItem, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    select: {
        color: 'white',
        marginLeft: '15px'
    },
    selectMenu: {

    }
}));

const CountrySelect = (props) => {
    const classes = useStyles();
    const ctx = useContext(UserContext);
    const handleCurrentCountryChange = (event) => {
        const value = event.target.value;
        const country = ctx.userCountries.find(country => country.code === value);
        ctx.onSetCurrentCountry(country);
    };
    return (
        <React.Fragment>
        {
            ctx.userCountries.length !== 0 &&
            <FormControl className={classes.formControl}>
                <Select
                    value={ctx.currentCountry.code}
                    onChange={handleCurrentCountryChange}
                    displayEmpty
                    className={classes.select}
                    inputProps={{'aria-label': 'Without label'}}
                >
                    {ctx.userCountries.map((country, index) => (
                        <MenuItem key={index} value={country.code}>
                            <Flag country={country.code} style={{paddingRight: "5px"}}/>{!props.mobile && country.name}
                        </MenuItem>
                    ))
                    }
                </Select>
            </FormControl>

        }
        </React.Fragment>
    );
};

export default CountrySelect;