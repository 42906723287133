import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

const GlossaryPage = () => {
    const rows = [
        {'abbreviation': "A", 'meaning': "All property types (chapter IV)"},
        {'abbreviation': "ABS", 'meaning': "Assed Backed Securities"},
        {'abbreviation': "Art.", 'meaning': "Article"},
        {'abbreviation': "BRRD", 'meaning': "Directive 2014/59/EU of the European Parliament and the Council of 15 May 2014 establishing a framework for the recovery and resolution of credit institutions and investment firms and amending Council Directive 82/891/EEC, and Directives 2001/24/EC, 2002/47/EC, 2004/25/EC, 2005/56/EC, 2007/36/EC, 2011/35/EU, 2012/30/EU and 2013/36/EU, and Regulations (EU) No 1093/2010 and (EU) No 648/2012, of the European Parliament and of the Council (12.06.2014; OJ L 173/190-348), as amended"},
        {'abbreviation': "C", 'meaning': "Only commercial properties and multi family houses (Commercial) (chapter IV)"},
        {'abbreviation': "CB (CBs)", 'meaning': "Covered Bond (Covered Bonds)"},
        {'abbreviation': "CBD", 'meaning': "Covered Bond Directive (Directive (EU) 2019/2162 of the European Parliament and the Council of 27 November 2019 on the issue of covered bonds and covered bond public supervision and amending Directives 2009/65/EC and 2014/59/EU; OJ 18.12.2019, L 328/29-57)"},
        {'abbreviation': "CBM", 'meaning': "Covered Bond Model"},
        {'abbreviation': "CH", 'meaning': "Switzerland"},
        {'abbreviation': "CPM", 'meaning': "Cover Pool Monitor"},
        {'abbreviation': "CRR", 'meaning': "Regulation (EU) No 575/2013 of the European Parliament and the Council of 26 June 2013 on prudential requirements for credit institutions and investment firms and amending Regulation (EU) No 648/2012 (OJ 27.06.2013, L 176/1-337), as amended"},
        {'abbreviation': "EBA", 'meaning': "European Banking Authority"},
        {'abbreviation': "ECA", 'meaning': "Export Credit Agencies"},
        {'abbreviation': "ECAI", 'meaning': "External Credit Assessment Institution "},
        {'abbreviation': "ECAI", 'meaning': "External Credit Assessment Institution "},
        {'abbreviation': "ECBC HTT", 'meaning': "European Covered Bond Council - Harmonized Transparency Template"},
        {'abbreviation': "EEA", 'meaning': "European Economic Area"},
        {'abbreviation': "EU", 'meaning': "European Union"},
        {'abbreviation': "FINMA", 'meaning': "Financial Market Supervisory Authority"},
        {'abbreviation': "FSA", 'meaning': "Financial Supervision Authority"},
        {'abbreviation': "H", 'meaning': "Only apartments and family houses (Housing) (chapter IV)"},
        {'abbreviation': "ISIN", 'meaning': "International Securities Identification Number"},
        {'abbreviation': "LCR", 'meaning': "Commission delegated Regulation (EU) 2015/61 of 10 October 2014 to supplement Regulation (EU) No 575/2013 of the European Parliament and the Council with regard to liquidity coverage requirement for Credit Institutions (OJ 17.01.2015; L 11/1-36), as amended"},
        {'abbreviation': "LTV", 'meaning': "Loan-to-Value"},
        {'abbreviation': "M1", 'meaning': "Covered Bond Model 1 (chapter XI)"},
        {'abbreviation': "M4", 'meaning': "Covered Bond Model 4 (chapter XII)"},
        {'abbreviation': "M5", 'meaning': "Covered Bond Model 5 (chapter XIII)"},
        {'abbreviation': "MBS", 'meaning': "Mortgage Backed Securities"},
        {'abbreviation': "MCB", 'meaning': "Mortgage Covered Bond"},
        {'abbreviation': "MDB", 'meaning': "Multilateral Development Banks"},
        {'abbreviation': "MREL", 'meaning': "Minimum Requirement for Own Funds and Eligible Liabilities"},
        {'abbreviation': "npv", 'meaning': "net present value"},
        {'abbreviation': "OC", 'meaning': "Over-Collateralisation"},
        {'abbreviation': "OECD", 'meaning': "Organization for Economic Co-operation and Development"},
        {'abbreviation': "OJ", 'meaning': "Official Journal of the European Union"},
        {'abbreviation': "para.", 'meaning': "paragraph"},
        {'abbreviation': "PSCB", 'meaning': "Public Sector Covered Bond"},
        {'abbreviation': "sec.", 'meaning': "section"},
        {'abbreviation': "SPE", 'meaning': "Special Purpose Entity"},
        {'abbreviation': "SPV", 'meaning': "Special Purpose Vehicle"},
        {'abbreviation': "SRMR", 'meaning': "Regulation (EU) No 806/2014 of the European Parliament and the Council of 15 July 2014 establishing uniform rules and a uniform procedure for the resolution of credit institutions and certain investment firms in the framework of a Single Resolution Mechanism and a Single Resolution Fund and amending Regulation (EU) No 1093/2010 (30.07.2014; OJ L 225/1-90), as amended"},
        {'abbreviation': "subpar.", 'meaning': "subparagraph"},
        {'abbreviation': "SupA", 'meaning': "Supervisory Authority"},
        {'abbreviation': "UK", 'meaning': "United Kingdom of Great Britain and Northern Ireland"},
        {'abbreviation': "USA", 'meaning': "United States of America"},
    ];
    return (
        <div>

            <Container fluid>
                <Typography variant="h3">List of Abbrevations</Typography>
                <br/>
                <br/>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>abbreviation</TableCell>
                                <TableCell>meaning</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell>{row.abbreviation}</TableCell>
                                    <TableCell>{row.meaning}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/*<Typography variant="h3"><FormattedMessage defaultMessage="User Guide" /></Typography>*/}
            </Container>
        </div>
    );
};

export default GlossaryPage;