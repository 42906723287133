import React, {useContext, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {useQuery} from "react-query";
import axios from "axios";
import {AppBar, Box, LinearProgress, Tab, Tabs, Typography} from "@material-ui/core";
import AppInfoContext from "../../context/appinfo-context";
import CountryContext from "../../context/country-context";
import Flag from "react-flagkit";
import {makeStyles} from "@material-ui/core/styles";
import {getPointOfViewsURL} from "../../utils/api";
import {FormattedMessage, useIntl} from "react-intl";
import {rtColors} from "../../assets/jss/rt-theme";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    barrootAll: {
        backgroundColor: rtColors.primary
    },
    rootAll: {
        height: "22px",
        backgroundColor: rtColors.primaryLight
    },
    barrootWeighted: {
        backgroundColor: rtColors.grey2
    },
    rootWeighted: {
        height: "8px",
        backgroundColor: rtColors.grey3
    }
}));
function SingleLinearProgressWithLabel(props) {
    const classes = useStyles();
    const intl = useIntl();
    const perCent = (props.score / props.maxScore) * 100.;
    return (
        <Box display="flex" alignItems="center" mt={1}>
            <Box minWidth={35} mr={1}>
                <Flag country={props.country.code}></Flag>
            </Box>
            <Box minWidth={120} mr={1}>
                <h5 variant="body2" color="textSecondary">{props.country.name}</h5>
            </Box>
            <Typography
                style={{
                    position: "absolute",
                    left: "50%",
                    zIndex: "999",
                    color: "white",
                    fontSize: "14px"
                }}
            >{`${props.score}/${props.maxScore}`}</Typography>
            <Box width="100%" mr={1}>
                <LinearProgress
                    title={intl.formatMessage({defaultMessage:"% of all questions"})}
                    variant="determinate"
                    value={perCent}
                    classes={{barColorPrimary: classes.barrootAll}}
                    className={classes.rootAll} />

            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${props.country.progress_weighted}%`}</Typography>
            </Box>
        </Box>
    );
}

const ChartsPage = () => {
    const countryCtx = useContext(CountryContext);
    const classes = useStyles();
    const [povIndex, setPovIndex] = useState(0);
    const [countriesWithScores, setCountriesWithScores] = useState([]);
    const povsQuery = useQuery('povs', () => axios(getPointOfViewsURL), {
        onSuccess: data => {
            setCountriesWithScores(countryCtx.countries.map(country => {
                const scores = data.data.map(pov => {
                    return pov.country_scores[country.code];
                });
                return {...country, scores: scores};
            }));
        }
    });
    const handleChange = (event, newValue) => {
      setPovIndex(newValue);
    };
    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={povIndex}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {povsQuery.isSuccess && povsQuery.data.data.map((pov, index) => (
                        <Tab label={pov.name} key={index} {...a11yProps(index)} />
                    ))}
                </Tabs>
                {povsQuery.isSuccess && povsQuery.data.data.map((pov, index) => (
                    <TabPanel value={povIndex} index={index} key={index}>
                            <Box style={{float: "right"}} minWidth={35}>
                                <FormattedMessage defaultMessage="Answered weighted %" />
                            </Box>
                        <br />
                        {countriesWithScores.sort((a,b) => {
                            return b["scores"][index] - a["scores"][index];
                        }).map(country => (
                            <SingleLinearProgressWithLabel country={country} maxScore={pov.max_score}
                                                           score={pov.country_scores[country.code]} key={country.id}/>
                        ))}
                    </TabPanel>
                ))}
            </AppBar>

        </div>
    );
};

export default ChartsPage;