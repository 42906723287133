import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import QuestionRadios from "./QuestionRadios";
import QuestionComment from "./QuestionComment";
import AllComments from "./AllComments";
import QuestionAttachments from "./QuestionAttachments";
import Card from "../../theme-components/components/Card/Card";
import CardBody from "../../theme-components/components/Card/CardBody";
import {makeStyles} from "@material-ui/styles";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import {useContext} from "react";
import UserContext from "../../context/user-context";
import QuestionnaireContext from "../../context/questionnaire-context";
import LoadingOverlay from 'react-loading-overlay';
import {intToRoman} from "../../utils/general-helpers";
import CardFooter from "../../theme-components/components/Card/CardFooter";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from '@material-ui/icons/Info';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {rtColors} from "../../assets/jss/rt-theme";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Button from "../CustomButtons/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Grid from "@material-ui/core/Grid";
import {FormattedMessage} from "react-intl";
import axios from "axios";
import Explanation from "./Explanation";

const useStyles = makeStyles((theme) => ({
    iframeMap: {
        height: "600px",
        border: 'none',
        width: '100%'
    },
    accordion: {
        width: '100%'
    },
    forwardBtn: {
        float: 'right',
        backgroundColor: rtColors.primary,
        // "&:focus": {
        //     backgroundColor: rtColors.primary + "!important"
        // },
    },

    backBtn: {
        float: 'left',
        backgroundColor: rtColors.primary
    }
}));

const Question = () => {
    const ctx = useContext(UserContext);
    const questionnaireCtx = useContext(QuestionnaireContext);
    const classes = useStyles();
    const params = useParams();
    const nextQuestion = questionnaireCtx.getNextQuestionById(parseInt(params.questionId));
    const previousQuestion = questionnaireCtx.getPreviousQuestionById(parseInt(params.questionId));
    const [chapter, question] = questionnaireCtx.getChapterAndQuestionById(parseInt(params.questionId));
    const authString = ctx.isLoggedIn ? "&auth=" + ctx.accessToken : "";
    const iframeSrc = "/map-service/" + question.id + "/?lang=" + ctx.locale + authString;
    
    return (
        <LoadingOverlay
            active={questionnaireCtx.chaptersLoading}
            spinner
        >
            <div>
                <Card>
                    <CardBody>
                        <h6 align="center">{intToRoman(chapter.order)}. {chapter.name}</h6>
                        <Grid container spacing={3}>
                            <Grid item sm={2} xs={6}>
                                <Link to={previousQuestion ? "/question/" + previousQuestion.id + "/" : "#"}>
                                    <Button className={classes.backBtn} justIcon round
                                            disabled={!previousQuestion}>
                                        <ArrowBackIosIcon/>
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid item sm={8} xs={12}>
                                <h3 align="center">{question.order}. {question.text}</h3>
                            </Grid>
                            <Grid item sm={2} xs={6}>
                                <Link to={nextQuestion ? "/question/" + nextQuestion.id + "/" : "#"}>
                                    <Button className={classes.forwardBtn} justIcon round
                                            disabled={!nextQuestion}>
                                        <ArrowForwardIosIcon/>
                                    </Button>
                                </Link>

                            </Grid>
                        </Grid>
                        <QuestionRadios question={question}/>
                        {question.explanation && question.explanation.length > 0 &&
                            <Explanation explanation={question.explanation}/>
                        }
                        <Container>
                            <QuestionAttachments question={question}/>
                        </Container>
                    </CardBody>
                    <CardFooter>

                    </CardFooter>
                </Card>
                <QuestionComment question={question}/>
                <Card>
                    <CardBody>
                        <GridContainer justifyContent="center">
                            <GridItem xs={12} sm={12} lg={12}>
                                <iframe className={classes.iframeMap}
                                        id="map-iframe"
                                        title="result chart"
                                        src={iframeSrc}
                                />
                            </GridItem>
                        </GridContainer>

                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <AllComments question={question}/>
                    </CardBody>
                </Card>
            </div>
        </LoadingOverlay>
    );
};

export default Question;