import {useQuery} from "react-query";
import axios from "axios";
import DownloadablesList from "./DownloadablesList";
import ConfirmationDialog from "./ConfirmationDialog";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Language, Public} from "@material-ui/icons";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import LoadingOverlay from 'react-loading-overlay';
import React, {useContext} from "react";
import UserContext from "../../context/user-context";
import QuestionnaireContext from "../../context/questionnaire-context";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../../theme-components/components/Card/Card";
import CardHeader from "../../theme-components/components/Card/CardHeader";
import CardIcon from "../../theme-components/components/Card/CardIcon";
import CardFooter from "../../theme-components/components/Card/CardFooter";
import {FormattedMessage} from "react-intl";
import CountryContext from "../../context/country-context";
import {Box, Typography} from "@material-ui/core";
import {getDownloadableCategoriesURL} from "../../utils/api";
import {rtColors} from "../../assets/jss/rt-theme";
import AppInfoContext from "../../context/appinfo-context";
import {pythonDateToHumanDate} from "../../utils/general-helpers";
import {Alert, AlertTitle} from "@material-ui/lab";
import CountryProgressChart from "../charts/CountryProgressCharts";

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: "0px"
    },
    cardCategory: {
        paddingTop: "10px",
        margin: "0",
        color: rtColors.grey2
    },
    cardTitle: {
        color: rtColors.grey1
    },
    stats: {
        color: rtColors.grey2
    }
}));

const DashboardPage = () => {
    const ctx = useContext(UserContext);
    const appCtx = useContext(AppInfoContext);
    const questionnaireCtx = useContext(QuestionnaireContext);
    const countryCtx = useContext(CountryContext);

    const classes = useStyles();
    const theme = useTheme();

    const {isLoading, isError, data, error} = useQuery('downloadables',
        () => axios(getDownloadableCategoriesURL));
    let downloadablesContent;
    if ( data && data.data.length > 0){
        downloadablesContent = <DownloadablesList downloadablesCategories={data.data}/>;
    }
    else if (isError) {
        downloadablesContent = <p>{error}</p>;
    }
    else if (isLoading) {
        downloadablesContent = <p>Loading...</p>;
    } else{
        downloadablesContent = <p><FormattedMessage defaultMessage="No downloads available." /></p>;
    }

    let questionCount = questionnaireCtx.getQuestionCount();
    let minQuestions = questionnaireCtx.getMinQuestionCount();
    let maxQuestions = questionnaireCtx.getMaxQuestionCount();
    let avgQuestions = questionnaireCtx.getAvgQuestionCount();
    let commentsCount = appCtx.commentsCount;

    let answeredCount, answeredPercentage, commentsCountryCount;
    const anonOrRo = ctx.getIsAnonOrRo();
    if (!anonOrRo){
        answeredCount = ctx.currentCountry.answered_count;
        answeredPercentage = ctx.currentCountry.progress_all;
        commentsCountryCount = ctx.currentCountry.comments_count;
    }
    return (
        <React.Fragment>
            {
                anonOrRo ? (
                        <Box mt={2}>
                            <Alert severity="info">
                                <AlertTitle>
                                    <FormattedMessage defaultMessage="Data Version Info" />
                                </AlertTitle>
                                <Typography><FormattedMessage defaultMessage="Current RO-Version:" /> {pythonDateToHumanDate(appCtx.rtPreviousDeadline)}</Typography>
                                <Typography><FormattedMessage defaultMessage="Next RO-Version:" /> {pythonDateToHumanDate(appCtx.rtDeadline)}</Typography>
                            </Alert>
                        </Box>
                ) : (

                    <Box mb={5}>
                        <h3><FormattedMessage defaultMessage="Allready confirmed?" /></h3>
                        <ConfirmationDialog />
                    </Box>
                )
            }

            <Box mb={5}>
                {
                    anonOrRo ? (
                            <h3><FormattedMessage defaultMessage="Round Table status" /></h3>


                        ) : (
                        <h3><FormattedMessage defaultMessage="Round Table status for {currentCountry}" values={{
                            currentCountry: ctx.currentCountry.name
                        }
                        }/></h3>
                        )
                }
                <GridContainer>
                    <GridItem xs={12} sm={6} md={3}>
                        <Card className={classes.card}>
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="warning">
                                    <Language />
                                </CardIcon>
                                <p className={classes.cardCategory}><FormattedMessage defaultMessage="Total Countries" /></p>
                                <h3 className={classes.cardTitle}>
                                    {countryCtx.countries && countryCtx.countries.length}
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                        <Public />

                                    {
                                        anonOrRo ? (
                                            <FormattedMessage defaultMessage="Latest country: {latestCountry}" values={{
                                                latestCountry: appCtx.latestCountryName
                                            }} />
                                        ) : (
                                            <FormattedMessage defaultMessage="Your country is: {currentCountry}" values={{
                                                currentCountry: ctx.currentCountry.name
                                            }} />
                                        )
                                    }
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                        <Card className={classes.card}>
                            <CardHeader color="danger" stats icon>
                                <CardIcon color="danger">
                                    <ImportContactsIcon />
                                </CardIcon>
                                <p className={classes.cardCategory}>
                                    <FormattedMessage
                                        defaultMessage="Total Chapters" />
                                </p>
                                <h3 className={classes.cardTitle}>
                                    {questionnaireCtx.chapters.length}
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                    <span style={{marginRight: theme.spacing(1)}}><b>Ø</b></span>
                                    <FormattedMessage
                                        defaultMessage="<b>{avgQuestions}</b> (from {minQuestions} to {maxQuestions} questions)"
                                        values={{
                                            b: chunks => <b>{chunks}</b>,
                                            minQuestions: minQuestions,
                                            maxQuestions: maxQuestions,
                                            avgQuestions: avgQuestions                                        }}
                                    />
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                        <Card className={classes.card}>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <LiveHelpIcon />
                                </CardIcon>
                                <p className={classes.cardCategory}>
                                    <FormattedMessage
                                        defaultMessage="Total Questions" />
                                </p>
                                <h3 className={classes.cardTitle}>
                                    {questionCount}
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                    <CheckCircleOutlineIcon />
                                    {
                                        anonOrRo ? (
                                            <></>
                                            // <FormattedMessage defaultMessage="{progressAllCountries} % answered" values={{
                                            //     progressAllCountries: appCtx.progressAllCountries
                                            // }} />
                                        ) : (
                                            <FormattedMessage
                                                defaultMessage="{answeredCount} answered questions ( {answeredPercentage}% )"
                                                values={{
                                                    b: chunks => <b>{chunks}</b>,
                                                    answeredCount: answeredCount,
                                                    answeredPercentage: answeredPercentage,
                                                }}
                                            />
                                        )
                                    }

                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                       <Card className={classes.card}>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <QuestionAnswerIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>
                                <FormattedMessage
                                    defaultMessage="Total Comments" />
                            </p>
                            <h3 className={classes.cardTitle}>
                                {commentsCount}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <BorderColorIcon />
                                {
                                    anonOrRo ? (
                                        <FormattedMessage defaultMessage="{commentsLeader} has most comments ({commentsLeaderCount})" values={{
                                            commentsLeader: appCtx.commentsLeader,
                                            commentsLeaderCount: appCtx.commentsLeaderCount
                                        }} />
                                    ) : (
                                        <FormattedMessage
                                            defaultMessage="{commentsCountryCount} comments written by {country}"
                                            values={{
                                                b: chunks => <b>{chunks}</b>,
                                                commentsCountryCount: commentsCountryCount,
                                                country: ctx.currentCountry.name,
                                            }}
                                        />
                                    )
                                }

                            </div>
                        </CardFooter>
                    </Card>
                    </GridItem>

                </GridContainer>

            </Box>
            <Box mb={5}>

            <h3>Downloads</h3>
            {downloadablesContent}
            </Box>
            {
                !anonOrRo &&
                <Box mb={5}>
                    <h3><FormattedMessage defaultMessage="Countries progress" /></h3>
                    <LoadingOverlay
                        active={countryCtx.countriesLoading}
                        spinner
                    >
                        <CountryProgressChart countries={countryCtx.countries} />
                    </LoadingOverlay>
                </Box>
            }
        </React.Fragment>
    );
};

export default DashboardPage;