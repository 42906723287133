import React, {useEffect, useRef, useState} from 'react';
import {useContext} from "react";
import UserContext from "../../context/user-context";
import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {getCommentsURL} from "../../utils/api";
import CardBody from "../../theme-components/components/Card/CardBody";
import Card from "../../theme-components/components/Card/Card";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {successColor} from "../../assets/jss/material-dashboard-pro-react";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Button from "../../theme-components/components/CustomButtons/Button";
import {convertFromRaw, convertToRaw, EditorState, RichUtils, convertFromHTML, ContentState} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {FormattedMessage} from "react-intl";
import {convertToHTML} from "draft-convert";
import {pythonDateTimeToDate, pythonDateToHumanDate} from "../../utils/general-helpers";

const useStyles = makeStyles((theme) => ({
    successMessage: {
        // textAlign: "center",
        color: successColor[0]
    }
}));


const QuestionComment = (props) => {
    const [commentSaved, setCommentSaved] = useState(false);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty(),);
    const classes = useStyles();
    useEffect(() => {
        setCommentSaved(false);
    }, [editorState]);
    const ctx = useContext(UserContext);
    const handleKeyCommand = (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if(newState){
          setEditorState(newState);
          return 'handled';
      }
      return 'not-handled';
    };
    const commentQuery = useQuery(['comment', props.question.id, ctx.currentCountry.id],
        () => axios(getCommentsURL, {
            params: {
                question: props.question.id,
                country: ctx.currentCountry.id
            },
        }),
        {
            onSuccess: (data) => {
                if (data.data){
                    const comments = data.data;
                    if(comments.length === 0){
                        setEditorState(EditorState.createEmpty());
                        return null;
                    }
                    const commentBlocks = convertFromHTML(comments[0].text);
                    const contentState = ContentState.createFromBlockArray(commentBlocks.contentBlocks, commentBlocks.entityMap);
                    const newEditorstate = EditorState.createWithContent(contentState);
                    setEditorState(newEditorstate);
                }
            }
        }
    );
    const updateComment = useMutation(
        comment => axios.post(getCommentsURL, {
            question: props.question.id,
            country: ctx.currentCountry.code,
            text: comment
        }), {
            onMutate: (data) => {
                // const previousData = queryClient.getQueryData(['comment', props.question.id, ctx.currentCountry.id]);
                // const newData = {...previousData, data};
                // queryClient.setQueryData(['comment', props.question.id, ctx.currentCountry.id], newData);
            }
        }
    );
    const deleteComment = useMutation(
        (commentId) => axios.delete(getCommentsURL + commentId + "/")
    );
    const onSaveComment = (e, a) => {
        const editorStateContent = editorState.getCurrentContent();
        if (editorStateContent.hasText()){
            const html = convertToHTML(editorStateContent);
            updateComment.mutate(html);
        } else {
            const commentId = commentQuery.data ? commentQuery.data.data ? commentQuery.data.data.length > 0 ? commentQuery.data.data[0].id : null : null : null;
            if (commentId){
                deleteComment.mutate(commentId);
            }
        }
        setCommentSaved(true);
    };
    const handleEditorChange = (newState) => {
        setEditorState(!editorState.getSelection().getHasFocus() ? EditorState.moveFocusToEnd(newState) : newState);
    };
    const editorHasText = editorState.getCurrentContent().hasText();
    return (
        <Card>
            <CardBody>
            <h3>{editorHasText ?
                (<FormattedMessage defaultMessage="Edit Comment"/>) :
                (<FormattedMessage defaultMessage="Add Comment" />)}
            </h3>
                {commentSaved && (editorHasText ?
                    <Typography className={classes.successMessage} component="p" variant="subtitle1">
                        <CheckCircleOutlineIcon /> <FormattedMessage defaultMessage="Comment saved" />
                    </Typography>
                    :
                    <Typography className={classes.successMessage} component="p" variant="subtitle1">
                        <CheckCircleOutlineIcon /> <FormattedMessage defaultMessage="Comment saved" />
                    </Typography>)
                }
            {commentQuery.isError && <p>{commentQuery.error}</p>}
            {commentQuery.isLoading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    <Editor editorState={editorState}
                            onEditorStateChange={handleEditorChange}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            handleKeyCommand={handleKeyCommand}
                            placeholder={<FormattedMessage defaultMessage="Add a comment..."/>}
                            toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
                                }
                            }
                    />
                    <Button onClick={onSaveComment}><FormattedMessage defaultMessage="Save"/></Button>
                    {
                        commentQuery.data && commentQuery.data.data && commentQuery.data.data.length > 0 && (commentQuery.data.data[0].created_by || commentQuery.data.data[0].modified_by) && (
                            <>
                                <br />
                                <br />
                                {
                                    commentQuery.data.data[0].created_by &&
                                    <Typography variant="caption"><FormattedMessage defaultMessage="Created by:" /> <span>{`${commentQuery.data.data[0].created_by.first_name} ${commentQuery.data.data[0].created_by.last_name} (${pythonDateTimeToDate(commentQuery.data.data[0].created)})`}</span></Typography>
                                }
                                <br />
                                {
                                    commentQuery.data.data[0].modified_by && (!commentQuery.data.data[0].created_by || commentQuery.data.data[0].created_by.id !== commentQuery.data.data[0].modified_by.id) &&
                                    (
                                        <Typography variant="caption"><FormattedMessage defaultMessage="Last edited by:" /> <span>{`${commentQuery.data.data[0].modified_by.first_name} ${commentQuery.data.data[0].modified_by.last_name} (${pythonDateTimeToDate(commentQuery.data.data[0].modified)})`}</span></Typography>
                                    )
                                }
                            </>
                        )
                    }
                </div>
                )}
            </CardBody>
        </Card>
    );
};

export default QuestionComment;