import React, {useContext} from "react";
import UserContext from "../../context/user-context";
import {FormControlLabel} from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import {FormattedMessage} from "react-intl";
import Checkbox from '@material-ui/core/Checkbox';
import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay';
import {updateConfirmedStatusURL} from "../../utils/api";
import {makeStyles} from "@material-ui/core/styles";
import {rtColors} from "../../assets/jss/rt-theme";
import AppInfoContext from "../../context/appinfo-context";
import {pythonDateToHumanDate} from "../../utils/general-helpers";

const useStyles = makeStyles((theme) => ({
    confirmLabel: {
        color: rtColors.grey1
    },
    confirmInfo: {
        color: rtColors.grey2
    }
}));


const ConfirmationDialog = () => {
    const classes = useStyles();
    const ctx = useContext(UserContext);
    const appCtx = useContext(AppInfoContext);
    const queryClient = useQueryClient();
    const updateConfirmationStatus = useMutation(
        confirmed => axios.post(updateConfirmedStatusURL, {
            country: ctx.currentCountry.code,
            validated: confirmed ? 1 : 0
        }), {
            onSuccess: (data) => {
                queryClient.invalidateQueries('userinfo');
                ctx.onCurrentCountryConfirmed(!!data.data.success);
            }
        }
    );
    const handleConfirmationChange = (event) => {
        if(event){
            updateConfirmationStatus.mutate(event.target.checked);
        }
    };
    return (
        <LoadingOverlay
            active={updateConfirmationStatus.isLoading}
            spinner
        >
        <Alert severity={ctx.currentCountry.checked_upcoming_dl_valid ?
            "success" : appCtx.rtDaysLeft < 8 ?
                "error" : appCtx.rtDaysLeft < 30 ?
                    "warning" : "info"}>
            <AlertTitle>
                {
                    ctx.currentCountry.checked_upcoming_dl_valid ?
                        <FormattedMessage
                            defaultMessage="Confirmed"
                        />
                        :
                        <FormattedMessage
                            defaultMessage="Not confirmed"
                        />

                }

            </AlertTitle>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={Boolean(ctx.currentCountry.checked_upcoming_dl_valid)}
                        onChange={handleConfirmationChange}
                        name="checkUpcomingDl"
                    />
                }
                label={<p className={classes.confirmLabel}>
                    <FormattedMessage
                        defaultMessage="I confirm that my answers in the questionaire for {country} can be transfered to
                        the new read-only-version to the day of {date}."
                        values={{
                            b: chunks => <b>{chunks}</b>,
                            country: ctx.currentCountry.name,
                            date: pythonDateToHumanDate(appCtx.rtDeadline)
                        }}
                    /></p>
                    }
            /><br />
            <p className={classes.confirmInfo}>
            <FormattedMessage
                defaultMessage="{daysLeft} days left until all answers will become transfered to the new
                read-only-version."
                values={{
                    b: chunks => <b>{chunks}</b>,
                    daysLeft: appCtx.rtDaysLeft
                }}
            />
            </p>
        </Alert>
        </LoadingOverlay>
    );
};

export default ConfirmationDialog;