
import React, {useContext, useEffect, useState} from "react";
import {
    Checkbox,
    FormControl, FormControlLabel,
    MenuItem
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";
import {useQuery} from "react-query";
import axios from "axios";
import Box from "@material-ui/core/Box";
import Flag from "react-flagkit";
import FileDownload from 'js-file-download';
import CountryContext from "../../../context/country-context";
// @material-ui/icons
// core components
import Button from "~/components/CustomButtons/Button.js";

import styles from "~/assets/jss/material-dashboard-pro-react/modalStyle.js";
import MyDialog from "./MyDialog";
import {reportByCountryURL} from "../../../utils/api";
import {rtColors} from "../../../assets/jss/rt-theme";

const useStyles = makeStyles(styles);


const ReportByCountryModal = (props) => {
    const classes = useStyles();
    const [countries, setCountries] = useState([]);
    const countryCtx = useContext(CountryContext);
    const exportAllQuery = useQuery(
        "export-all-countries",
        () => axios(reportByCountryURL, {responseType: 'blob'})
            .then((res) => FileDownload(res.data, "RT-export-by-countries.xlsx")), {
            enabled: false
        });
    const exportByCountries = useQuery(
        ['export-by-country', countries],
        () => axios(reportByCountryURL, {
            responseType: 'blob',
            params: {
                countries: JSON.stringify(countries)
            }}).then((res) => FileDownload(res.data, "RT-export-by-countries.xlsx")),{
            enabled: false
        });
    const handleChange = (event) => {
        if (event.target.checked){
            setCountries([...countries, event.target.value]);
        } else {
            setCountries(countries.filter((country) => {
                return country !== event.target.value;
            }));
        }
    };
    const handleExportAll = () => {
        exportAllQuery.refetch();
    };
    const handleExportCountries = () => {
        exportByCountries.refetch();
    };
    return (
        <MyDialog
            id="report-by-country"
            open={props.open}
            onClose={props.onClose}
            isLoading={exportByCountries.isLoading || exportAllQuery.isLoading}
            title={<FormattedMessage
                defaultMessage="Export Report by Countries"
            />}
            description={<FormattedMessage
                defaultMessage="Select one or more countries to export."
            />}
            buttons={<>
                <Button variant="contained" onClick={handleExportAll}>Export all Countries</Button>
                <Button variant="contained" color="primary" onClick={handleExportCountries} disabled={countries.length === 0}>Export selected</Button>
            </>}
        >

            <Box>
                <FormControl className={classes.formControl} required>
                    {countryCtx.countries && countryCtx.countries.map((country) => (
                        <FormControlLabel key={country.code} control={
                            <Checkbox checked={countries.includes(country.code)} onChange={handleChange}
                                                             value={country.code} />
                        }
                                          label={
                        <MenuItem key={country.code} value={country.name} data-id={country.id}>
                            <Flag country={country.code}  style={{paddingRight: "5px"}}/> {country.name}
                        </MenuItem>} />
                    ))}
                </FormControl>
            </Box>
        </MyDialog>
    );
};
export default ReportByCountryModal;