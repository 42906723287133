import React, {useContext, useEffect, useState} from 'react';
import UserContext from "../../../context/user-context";
import {FormattedMessage, useIntl} from "react-intl";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useSnackbar} from "notistack";
import axios from "axios";
import {getChapterAdminURL, getPointOfViewsAdminURL, getQuestionWeights, getScores} from "../../../utils/api";
import LoadingOverlay from 'react-loading-overlay';
import Grid from "@material-ui/core/Grid";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Fab,
    FormControl,
    InputLabel,
    MenuItem,
    Select, TextField, Typography
} from "@material-ui/core";
import {intToRoman} from "../../../utils/general-helpers";

import {makeStyles} from "@material-ui/core/styles";
import {dangerColor, successColor} from "../../../assets/jss/material-dashboard-pro-react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Alert, AlertTitle} from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
    answeredIcon: {
        color: successColor[0]
    },
    notAnsweredIcon: {
        color: dangerColor[0]
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    scoreInput: {
      padding: '10px'
    },
    fab: {
        float: 'right',
        margin: '5px',
    },
    questionInactive: {
        backgroundColor: '#888'
    },
    err: {
        color: 'red',
        position: 'absolute',
        bottom: 0
    }
}));
const WeightsTable = () => {
    const ctx = useContext(UserContext);
    const intl = useIntl();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const [chapter, setChapter] = useState(null);
    const [selectedChapter, setSelectedChapter] = useState(null);
    const classes = useStyles();
    const chapterAdminQuery = useQuery('chapterAdmin', () => axios(getChapterAdminURL), {
        onSuccess: (data) => {
            if (data && data.data) {
                setSelectedChapter(data.data[0].id);
            }
        }
    });
    useEffect(() => {
        if (chapterAdminQuery.data && chapterAdminQuery.data.data) {
            setChapter(chapterAdminQuery.data.data.find(c => {
                return c.id === selectedChapter;
            }));
        }
    }, [selectedChapter, chapterAdminQuery]);
    const povsQuery = useQuery('povs', () => axios(getPointOfViewsAdminURL), {
        onSuccess: (data) => {}
    });
    const scoresQuery = useQuery('scores', () => axios(getScores));
    const questionWeightsQuery = useQuery('questionWeights', () => axios(getQuestionWeights));

    const updateQuestionWeightQuery = useMutation(questionWeight => axios.put(getQuestionWeights + questionWeight.id  + "/", questionWeight), {
        onMutate: async newQuestionWeight => {
            await queryClient.cancelQueries('questionWeights');
            const weightIndex = getWeightIndex(newQuestionWeight);
            const previousWeights = [...questionWeightsQuery.data.data];
            let newWeights = [...previousWeights];
            newWeights[weightIndex] = newQuestionWeight;
            queryClient.setQueryData('questionWeights', {data: newWeights});
            return {previousWeights: previousWeights};
        },
        onSuccess: (data) => {
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Question Weight update success!"}), {variant: 'success'});
        },
        onError: (err, newQuestionWeight, context) => {
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Error updating Questionweight: "}) + err, {variant: 'error'});
            queryClient.setQueryData('questionWeights', {data: context.previousWeights});
        },
        onSettled: () => {
            queryClient.invalidateQueries('questionWeights');
        }
    });
    const addQuestionWeightQuery = useMutation(questionWeight => axios.post(getQuestionWeights, {
        weight: questionWeight.weight,
        question: questionWeight.question,
        pointofview: questionWeight.pointofview
    }), {
        onMutate: async newQuestionWeight => {
            await queryClient.cancelQueries('questionWeights');
            const previousWeights = [...questionWeightsQuery.data.data];
            let newWeights = [...previousWeights, newQuestionWeight];
            queryClient.setQueryData('questionWeights', {data: newWeights});
            return {previousWeights};
        },
        onError: (err, newWeight, context) => {
            queryClient.setQueryData('questionWeights', context.previousWeights);
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Error adding Question Weight: "}) + err, {variant: 'error'});
        },
        onSuccess: (data) => {
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Question Weight added success!"}), {variant: 'success'});
        },
        onSettled: () => {
            queryClient.invalidateQueries('questionWeights');
        }
    });
    const updateScoreQuery = useMutation(score => axios.put(getScores + score.id + "/" , score), {
        onMutate: async newScore => {
            await queryClient.cancelQueries('scores');
            const scoreIndex = getScoreIndex(newScore);
            const previousScores = [...scoresQuery.data.data];
            let currentScores = [...previousScores];
            currentScores[scoreIndex] = newScore;
            queryClient.setQueryData('scores', {data: currentScores});
            // setScores(currentScores);
            return {previousScores: previousScores};
        },
        onSuccess: (data) => {
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Answer Score update success!"}), {variant: 'success'});
        },
        onError: (err, newScore, context) => {
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Error updating Answer Scores: "}) + err, {variant: 'error'});
            queryClient.setQueryData('scores', {data: context.previousScores});
        },
        onSettled: () => {
            queryClient.invalidateQueries('scores');
        }
    });
    const addScoreQuery = useMutation(score => axios.post(getScores, {
        ...score
    }), {
        onMutate: async newScore => {
            await queryClient.cancelQueries('scores');
            const previousScores = [...scoresQuery.data.data];
            let newScores = [...previousScores, newScore];
            queryClient.setQueryData('scores', {data: newScores});
            return {previousScores};
        },
        onError: (err, newScore, context) => {
            queryClient.setQueryData('scores', context.previousScores);
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Error adding Answer Score: "}) + err, {variant: 'error'});
        },
        onSuccess: (data) => {
            enqueueSnackbar(intl.formatMessage({defaultMessage: "Answer Score added success!"}), {variant: 'success'});
        },
        onSettled: () => {
            queryClient.invalidateQueries('scores');
        }
    });
    const getWeightIndex = (weight) => {
        return questionWeightsQuery.data.data.findIndex(w => {
            return w.question === weight.question && w.pov === weight.pov;
        });
    };
    const getScoreIndex = (score) => {
        return scoresQuery.data.data.findIndex(s => {
            return s.answer === score.answer && s.pov === score.pov;
        });
    };

    const handleSelectChapter = (event) => {
        setSelectedChapter(event.target.value);
    };
    const getScore = (povId, answerId) => {
        const score = scoresQuery.data.data.find(score => {
            return answerId === score.answer && povId === score.pointofview;
        });
        return score ? score : {score: 0, answer: answerId, pointofview: povId, id: null};
    };
    const getWeight = (povId, questionId) => {
        const weight = questionWeightsQuery.data.data.find(weight => {
            return questionId === weight.question && povId === weight.pointofview;
        });
        return weight ? weight : {weight: 0, question: questionId, pointofview: povId, id: null};
    };
    const handleEditWeight = (oldWeight, event) => {
        const newWeight = {...oldWeight, weight: event.target.value};
        if (newWeight.id) {
            updateQuestionWeightQuery.mutate(newWeight);
        }
        else {
            addQuestionWeightQuery.mutate(newWeight);
        }
    };
    const handleEditScore = (oldScore, event) => {
        const newScore = {...oldScore, score: event.target.value};
        if (newScore.id){
            updateScoreQuery.mutate(newScore);
        }
        else {
            addScoreQuery.mutate(newScore);
        }
    };
    return (
        <LoadingOverlay
            active={chapterAdminQuery.isLoading || !chapterAdminQuery.data || !chapterAdminQuery.data.data || !chapter}
            spinner
        >
            {chapterAdminQuery.data && chapterAdminQuery.data.data &&
                questionWeightsQuery.isSuccess > 0 && scoresQuery.isSuccess &&
                (
                    <>
                        <Alert severity="info">
                                <AlertTitle>
                                    <FormattedMessage defaultMessage="Info" />
                                </AlertTitle>
                                <Typography><FormattedMessage defaultMessage="For changing the views and their weights please ask the developer."/></Typography>
                            </Alert>
                        <Grid container>

                            <Grid item xs={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel><FormattedMessage defaultMessage="Chapter"/></InputLabel>
                                    <Select
                                        value={selectedChapter}
                                        onChange={handleSelectChapter}
                                    >
                                        {chapterAdminQuery.data.data.map(chapter => (
                                            <MenuItem key={chapter.id} value={chapter.id}>{intToRoman(chapter.order) + " " +
                                                (ctx.locale === 'en' ? chapter["name_en"] : chapter["name_de"])}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={6}>
                                    <Grid container>
                                    {povsQuery.isSuccess && povsQuery.data.data.map(pov => (
                                        <Grid item xs={3} key={pov.id}>
                                            <Typography>{pov.name}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>

                    </>
                )
            }
            <Divider/>
            {chapter && chapter.questions.length && questionWeightsQuery.isSuccess &&
                scoresQuery.isSuccess && chapter.questions.map(question => (
                <Accordion
                    TransitionProps={{ unmountOnExit: true }}
                    key={question.id}
                    className={!question.active ? classes.questionInactive : ''}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {!question.active && (
                            <Typography className={classes.err}>Inactive</Typography>
                        ) }
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography>{question.order}. {ctx.locale === 'en' ? question.text_en : question.text_de}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container>
                                {povsQuery.isSuccess && povsQuery.data.data.map(pov => (
                                    <Grid item xs={3} key={pov.id}>
                                        <TextField
                                            label="Weight"
                                            className={classes.scoreInput}
                                            type="number"
                                            value={getWeight(pov.id, question.id).weight}
                                            onChange={(e) => handleEditWeight(getWeight(pov.id, question.id), e)}
                                            variant="outlined"
                                            InputProps={{inputProps: {min: 0}}}
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                        >
                                        </TextField>
                                    </Grid>
                                ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                        {question.answers.map(answer => (
                            <div key={answer.id}>
                                <Grid container>

                                <Grid item xs={6}>
                                        <Typography>{answer.order} - {ctx.locale === 'en' ? answer.text_en : answer.text_de}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container>
                                        {povsQuery.isSuccess && povsQuery.data.data.map(pov => (
                                            <Grid item xs={3} key={pov.id}>
                                                <TextField
                                                    label="Score"
                                                    className={classes.scoreInput}
                                                    type="number"
                                                    value={getScore(pov.id, answer.id).score}
                                                    onChange={(e) => handleEditScore(getScore(pov.id, answer.id), e)}
                                                    variant="outlined"
                                                    InputProps={{inputProps: {min: 0}}}
                                                >
                                                </TextField>
                                            </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    </Grid>
                                </div>
                            ))}
                    </Grid>
                    </AccordionDetails>
                </Accordion>

            ))

            }
        </LoadingOverlay>
    );

};

export default WeightsTable;