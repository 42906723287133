import React from "react";
import spinningTable from '~/assets/img/loading_animation.gif';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";


const QuestionNotFound = (props) => {
    return (
        <div style={{textAlign:"center"}}>
            <img src={spinningTable}  />
            <p style={{textAlign:"center"}}><FormattedMessage defaultMessage="The Question is not available" /></p>
            <p style={{textAlign:"center"}}>
                <Link to="/"><FormattedMessage defaultMessage="Go back to the Dashboard" /></Link>
            </p>
        </div>
    );
};

export default QuestionNotFound;