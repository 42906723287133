import React, {useContext} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {dangerColor} from "../../assets/jss/material-dashboard-pro-react";
import expertiseKarte from '../../assets/img/expertise_karte.jpg';
import Footer from "../../theme-components/components/Footer/Footer";
import TopBar from "../base/TopBar";
import {Container} from "@material-ui/core";
import AppinfoContext from "../../context/appinfo-context";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        // backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundImage: `url(${expertiseKarte})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        height: 'calc(100vh - 82px)'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    errorMessage: {
        textAlign: "center",
        color: dangerColor[0]
    },
    content: {
        marginTop: "70px",
        padding: "30px 15px",
        minHeight: "calc(100vh - 123px)",
    },
    headerImgWrapper: {
      borderTop: "0.75em solid " + theme.palette.primary.light
    },
    headerImg: {
        maxWidth: "100%"
    },
    headerTextWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        backgroundColor: theme.palette.primary.dark,
        color: "white",
        position: "relative",
        overflow: "hidden",
        minHeight: "150px",
        "&:before": {
            content: '" "',
            position: "absolute",
            width: "6em",
            height: "6em",
            backgroundColor: theme.palette.primary.darker,
            bottom: "-2em",
            right: "-3em",
            zIndex: "1",
            transform: "rotate(45deg)"
        },
        "&:after": {
            content: '" "',
            position: "absolute",
            bottom: "0",
            right: "0",
            left: "0",
            height: "1em",
            background: theme.palette.primary.light
        }
    },
    headerText: {
        flex: "0 0 120px",
        fontSize: "26px",
    },
    landingButtons: {
        backgroundColor: theme.palette.primary.main
    }
}));

export default function AuthBase(props) {
    const classes = useStyles();
    const appCtx = useContext(AppinfoContext);
    return (
        <>
            <CssBaseline/>
            <TopBar simple/>
            <Grid container component="main" className={classes.content}>
                <Container>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.headerTextWrapper}>
                                <Typography className={classes.headerText}>
                                    {appCtx.labelShort}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.headerImgWrapper}>
                                <img src={expertiseKarte} className={classes.headerImg}/>
                            </div>
                        </Grid>
                    </Grid>
                    {props.children}
                </Container>
            </Grid>
            <Footer white fluid/>
        </>

    );
}