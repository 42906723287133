import React, {useContext, useState} from 'react';
import CountryContext from "../../../context/country-context";
import MaterialTable from "@material-table/core";
import UserContext from "../../../context/user-context";
import {useQuery} from "react-query";
import axios from "axios";
import {getCountryAdminURL} from "../../../utils/api";
import Flag from "react-flagkit";
import {CheckCircle} from "@material-ui/icons";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import {makeStyles} from "@material-ui/core/styles";
import {rtColors} from "../../../assets/jss/rt-theme";
import {
    dangerBoxShadow,
    dangerColor,
    successBoxShadow,
    successColor
} from "../../../assets/jss/material-dashboard-pro-react";
// import {ExportCsv, ExportPdf} from "@material-table/exporters";
import {FormattedMessage, useIntl} from "react-intl";
import AddIcon from "@material-ui/icons/Add";
import {Fab} from "@material-ui/core";
import CountryEditDialog from "./CountryEditDialog";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import BlockIcon from "@material-ui/icons/Block";
import DeleteIcon from "@material-ui/icons/Delete";
import PublicIcon from "@material-ui/icons/Public";
import VpnLockIcon from "@material-ui/icons/VpnLock";

const useStyles = makeStyles((theme) => ({
    answeredIcon: {
        color: successColor[0]
    },
    notAnsweredIcon: {
        color: dangerColor[0]
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    tableOptions: {
        position: 'relative',
        margin: theme.spacing(1)
    }
}));

const CountryTable = () => {
    const classes = useStyles();
    const intl = useIntl();
    const initialCountryDialog = {
        open: false,
        addOrEdit: 'add',
        id: null,
        nameDe: '',
        nameEn: '',
        public: false,
        active: true,
        code: '',
        codeLabel: '',
    };
    const ctx = useContext(UserContext);
    const [countryDialogState, setCountryDialogState] = useState(initialCountryDialog);
    const countryAdminQuery = useQuery('countryAdmin', () => axios(getCountryAdminURL));
    const columns = [
        // {title: "Flag", field: "code", width: "5%", render: rowData => (<Flag country={rowData["code"]} />)},
        {title: "Country", field: ctx.locale === 'en' ? "name_en" : "name_de", render: rowData => (<p><Flag country={rowData["code"]} /> {
                ctx.locale === 'en' ? rowData["name_en"] : rowData["name_de"]
            }</p>)},
        {title: "Approved Answers", field: "checked_upcoming_dl_valid", render: rowData => (rowData["checked_upcoming_dl_valid"] ?
                <CheckCircleOutlineIcon className={classes.answeredIcon} /> :
                <CancelIcon className={classes.notAnsweredIcon} />)},
        {title: "# Comments", field: "comments_count"},
        {title: "Public", field: "public", render: rowData => (rowData["public"] ?
                <CheckCircleOutlineIcon className={classes.answeredIcon} /> :
                <CancelIcon className={classes.notAnsweredIcon} />)},
        {title: "Active", field: "active", render: rowData => (rowData["active"] ?
                <CheckCircleOutlineIcon className={classes.answeredIcon} /> :
                <CancelIcon className={classes.notAnsweredIcon} />)},

    ];
    const handleRowClick = (event, rowData) => {
        setCountryDialogState({
            open: true,
            addOrEdit: 'edit',
            id: rowData['id'],
            nameDe: rowData['name_de'],
            nameEn: rowData['name_en'],
            public: rowData['public'],
            active: rowData['active'],
            code: rowData['code'],
            codeLabel: rowData['code_label']
        });
    };
    const handleAddCountryClick = () => {
        setCountryDialogState({
            ...initialCountryDialog,
            open: true
        });
    };
    const handleCountryClose = () => {
        setCountryDialogState(initialCountryDialog);
    };
    return (
        <div>
            {
                countryAdminQuery.data && countryAdminQuery.data.data &&
                (
                    <div>
                        <div style={{margin: "75px 0"}}>
                            <div className={classes.tableOptions}>
                                <Fab color="primary" aria-label="add country" onClick={handleAddCountryClick}
                                     variant="extended"
                                     className={classes.fab}>
                                    <FormattedMessage defaultMessage="Add Country"/>
                                    <AddIcon/>
                                </Fab>
                            </div>
                        </div>
                        <MaterialTable columns={columns} data={countryAdminQuery.data.data}
                                       title={intl.formatMessage({defaultMessage: "Countries"})}
                                       onRowClick={handleRowClick}
                                       // actions={[
                                       //     rowData => ({
                                       //         icon: () => <BlockIcon/>,
                                       //         tooltip: 'Set to inactive',
                                       //         hidden: !rowData.active
                                       //     }),
                                       //     rowData => ({
                                       //         icon: () => <DeleteIcon/>,
                                       //         tooltip: 'Delete',
                                       //         hidden: rowData.active
                                       //     }),
                                       //     rowData => ({
                                       //         icon: () => <PublicIcon/>,
                                       //         tooltip: 'Set public',
                                       //         hidden: rowData.public
                                       //     }),
                                       //     rowData => ({
                                       //         icon: () => <VpnLockIcon/>,
                                       //         tooltip: 'Unset public',
                                       //         hidden: !rowData.public
                                       //     }),
                                       //
                                       // ]}
                                       options={{
                                           // pageSize: 100,
                                           paging: false,
                                           tableLayout: "fixed",
                                           // exportMenu: [{
                                           //     label: 'Export PDF',
                                           //     //// You can do whatever you wish in this function. We provide the
                                           //     //// raw table columns and table data for you to modify, if needed.
                                           //     // exportFunc: (cols, datas) => console.log({ cols, datas })
                                           //     exportFunc: (cols, datas) => ExportPdf(cols, datas, 'RT_Exported_Countries')
                                           // }, {
                                           //     label: 'Export CSV',
                                           //     exportFunc: (cols, datas) => ExportCsv(cols, datas, 'RT_Exported_Countries')
                                           // }],
                                           actionsColumnIndex: -1
                                       }}
                        />

                        <CountryEditDialog dialogState={countryDialogState} onClose={handleCountryClose}/>
                    </div>
                )
            }</div>
    );
};

export default CountryTable;