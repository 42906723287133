import React from "react";
import Box from "@material-ui/core/Box";
import {LinearProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Flag from "react-flagkit";
import {makeStyles} from "@material-ui/core/styles";
import {rtColors} from "../../assets/jss/rt-theme";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
    barrootAll: {
        backgroundColor: rtColors.primary
    },
    rootAll: {
        height: "8px",
        backgroundColor: rtColors.primaryLight
    },
    barrootWeighted: {
        backgroundColor: rtColors.grey2
    },
    rootWeighted: {
        height: "8px",
        backgroundColor: rtColors.grey3
    }

    }));

function DoubleLinearProgressWithLabel(props) {
    const classes = useStyles();
    const intl = useIntl();
    return (
        <Box display="flex" alignItems="center" mt={1}>
            <Box minWidth={35} mr={1}>
                <Flag country={props.country.code}></Flag>
            </Box>
            <Box minWidth={120} mr={1}>
                <h5 variant="body2" color="textSecondary">{props.country.name}</h5>
            </Box>
            <Box width="100%" mr={1}>
                <LinearProgress
                    title={intl.formatMessage({defaultMessage:"% of all questions"})}
                    variant="determinate"
                    value={props.country.progress_all}
                    classes={{barColorPrimary: classes.barrootAll}}
                    className={classes.rootAll} />
                <Box mt={1}>
                    <LinearProgress
                        title={intl.formatMessage({defaultMessage: "% of weighted questions"})}
                        variant="determinate"
                        value={props.country.progress_weighted} mt={1}
                        classes={{barColorPrimary: classes.barrootWeighted}}
                        className={classes.rootWeighted}/>
                </Box>
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.country.progress_all,
                )}%`}</Typography>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.country.progress_weighted,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


const CountryProgressChart = (props) => {
    let countryProgressChartContent;
    if (props.countries && props.countries.length > 0){
        countryProgressChartContent = props.countries.map((country, index) => (
            <DoubleLinearProgressWithLabel country={country} key={index}/>
        ));
    }
    return (
        <div>
            {countryProgressChartContent ? countryProgressChartContent : <p>...</p>}
        </div>
    );
};

export default CountryProgressChart;