import {hexToRgb} from "./material-dashboard-pro-react";

export const rtColors = {
    primary: "#b01159",
    primaryLight: "#ed2c83",
    grey1: "#666666",
    grey2: "#999999",
    grey3: "#d9d9d9",
    grey4: "#575656",
    grey5: "#e6e6e6",
};
export const rtBoxShadow = {
    primary: "0 4px 10px 0 rgba(" +
        hexToRgb(rtColors.primary) +
        ",.44), 0 7px 10px -5px rgba(" +
        hexToRgb(rtColors.primary) +
        ",.84)",
    grey: "0 4px 10px 0 rgba(" +
        hexToRgb("#333") +
        ",.44), 0 7px 10px -5px rgba(" +
        hexToRgb("#333") +
        ",.84)",
};