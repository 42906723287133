import {useField} from "formik";
import FormControl from "@material-ui/core/FormControl";
import {FormLabel, RadioGroup} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {dangerColor} from "../../assets/jss/material-dashboard-pro-react";
const useStyles = makeStyles((theme) => ({
    error: {
        color: dangerColor[0]
    },
    radioControl: {
        marginTop: "10px"
    }
}));
const MyRadios = ({label, ...props}) => {
    const classes = useStyles();
    const [field, meta] = useField(props);
    return (
        <FormControl component="fieldset" fullWidth className={classes.radioControl}>
            <FormLabel component="legend" >{label}</FormLabel>
            <RadioGroup {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className={classes.error}>{meta.error}</div>
            ) : null}
        </FormControl>
    );
};
export default MyRadios;