import  React, {useState} from 'react';
import SearchIcon from "@material-ui/icons/Search";
import {InputBase} from "@material-ui/core";
import useStyles from "./styles";
import {useHistory} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";


const SearchInput = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [searchInput, setSearchInput] = useState('');
    const intl = useIntl();
    const handleSubmit = (event) => {
        event.preventDefault();
        history.push('/search?search=' + searchInput);
    };
    const handleInputChange = (event) => {
        setSearchInput(event.target.value);
    };
    return (
        <form onSubmit={handleSubmit}>
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                id="search-input"
                placeholder={intl.formatMessage({defaultMessage: "Search"}) + "..."}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={searchInput}
                onChange={handleInputChange}
                required={true}
            />
        </div>
        </form>
    );
};
export default SearchInput;