import React, { useState } from 'react';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: 'white',
        padding: '10px',
        boxShadow: '0px 0px 5px rgba(0,0,0,0.3)',
        zIndex: 1100,
    },
    button: {
        backgroundColor: '#b01159',
        color: 'white',
        border: 'none',
        padding: '5px 25px',
        borderRadius: '5px',
        marginLeft: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#8d0c46',
        }
    },
    declineButton: {
        backgroundColor: '#999',
        '&:hover': {
            backgroundColor: '#bbb',
        }
    }
}));
const CookieConsent = () => {
    const classes = useStyles();
    const [accepted, setAccepted] = useState(localStorage.getItem('analytics_consent') || false);

    if (accepted) {
        return null; // Don't display the component if cookies are already accepted.
    }

    const acceptCookies = () => {
        setAccepted(true);
        localStorage.setItem('analytics_consent', 'true');

        // Initialize your analytics scripts here, e.g., Google Analytics
        // Or you can use an event to notify other parts of your app.
        // For example:
        // document.dispatchEvent(new Event('analytics-accepted'));
    };

    return (
        <div className={classes.container}>
            <p>We use cookies to analyze website traffic and optimize your website experience. By accepting our use of cookies, you agree to this.</p>
            <button className={classes.button} onClick={acceptCookies}>Accept</button>
            <button className={`${classes.button} ${classes.declineButton}`} onClick={() => setAccepted(true)}>Decline</button>
        </div>
    );
};

export default CookieConsent;